
import { useEffect, useState } from 'react'
import useApi from './../../utilities/useApi';
import arrayUtilities from '../../utilities/arrayUtilities';
import stringUtilities from '../../utilities/stringUtilities'
import dateUtilities from '../../utilities/dateUtilities';
import OrganisationSelector from '../common/organisationSelector';
import { Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import FilterBar from '../common/filterBar';
import Loading from '../common/loading';
import DatePicker from 'react-datepicker';

export default function UserActivity(props) {


    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [organisationUnitId, setOrganisationalUnitId] = useState(arrayUtilities.isNullOrEmpty(props.organisationalUnits) ? "" : props.organisationalUnits[0].id)

    const [startDate, setStartDate] = useState(dateUtilities.firstDayOfMonth(new Date()));
    const [endDate, setEndDate] = useState(dateUtilities.lastDayOfMonth(new Date()));
    const [filter, setFilter] = useState("");
    const [dataItems, setDataItems] = useState(null);
    const [filteredDataItems, setFilteredDataItems] = useState(null);

    const getReport = function () {

        if (!stringUtilities.isNullOrEmpty(organisationUnitId) && startDate && startDate !== null && endDate && endDate !== null) {

            setIsLoading(true);
            api.userActivityReport(organisationUnitId, startDate, endDate, (data) => {
                setDataItems(data);
                setIsLoading(false);
            }, () => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        setFilteredDataItems(arrayUtilities.isNullOrEmpty(dataItems) ? [] : dataItems.filter((dataItem) => {
            return stringUtilities.isNullOrEmpty(filter) || dataItem.userName.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, dataItems]);

    useEffect(() => {

        if (startDate > endDate) {
            setEndDate(startDate);
        }

        getReport();
    }, [organisationUnitId, startDate, endDate]);

    return <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2 report user-activity-report'>
        <Row>
            <Col className='mb-5'>
                <h1 className='h1 mt-3'>Reports</h1>
                <div className='d-flex justify-content-between my-5'>
                    <h2 className="h2 m-0">User Activity</h2>

                    <div className='d-flex justify-content-end'>

                        <Form.Group className={`d-flex align-items-center flex-shrink-0 me-4`}>
                            <Form.Label className='text-body fs-sm me-2 mb-0 pe-1 text-nowrap'>
                                <i className='fi-calendar text-muted mt-n1'></i>
                            </Form.Label>
                            <Form.Control
                                as={DatePicker}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                dateFormat="dd/MM/yyyy"
                                className='rounded-start form-control-sm'
                            />
                            <Form.Control
                                as={DatePicker}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                dateFormat="dd/MM/yyyy"
                                minDate={startDate}
                                className='rounded-start-0 form-control-sm'
                            />
                        </Form.Group>

                        <FilterBar value={filter} onChange={setFilter} className="me-4" />

                        <OrganisationSelector value={organisationUnitId} organisations={props.organisationalUnits} onChange={setOrganisationalUnitId} />
                    </div>
                </div>

                {isLoading && <Loading />}
                {!isLoading &&

                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th className='text-end'>Number of Points</th>
                                <th className='text-end'>Last Point</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!arrayUtilities.isNullOrEmpty(filteredDataItems) && filteredDataItems.map((dataItem, index) => <tr index={index} key={`user-activity-report-${dataItem.userId}`}>
                                <td className="">{dataItem.userName}</td>
                                <td className='text-end'>{dataItem.pointCount.toLocaleString()}</td>
                                <td className="text-end">{stringUtilities.formatDate(dataItem.latestPointTimestamp)}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                }

            </Col>
        </Row>
    </Container>;
}