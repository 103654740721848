import { Form } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";

export default function LimitSelect(props) {

    const onChange = function(e) {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    return <Form.Group controlId='input-small' className='mb-3'>
        <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
            Limit
        </Form.Label>
        <Form.Select size='sm' value={props.value ?? ""} onChange={onChange}>
            <option value=""></option>
            {!arrayUtilities.isNullOrEmpty(props.limits) && <>
                {props.limits.map((limit, index) => <option index={index} key={`limit-select-${limit.id}`} value={limit.id}>{limit.name}</option>)}
            </>}
        </Form.Select>
    </Form.Group>
}