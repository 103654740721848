import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Loading from './common/loading';
import { useEffect, useState } from 'react'
import useApi from './../utilities/useApi';
import arrayUtilities from '../utilities/arrayUtilities';
import { Table } from 'react-bootstrap';
import stringUtilities from '../utilities/stringUtilities'
import { Link } from 'react-router-dom'
import OrganisationSelector from './common/organisationSelector'
import FilterBar from './common/filterBar'

export default function Projects(props) {

    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [organisationUnitId, setOrganisationalUnitId] = useState(arrayUtilities.isNullOrEmpty(props.organisationalUnits) ? "" : props.organisationalUnits[0].id)
    const [filter, setFilter] = useState("");
    const [projects, setProjects] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState(null);

    const getProjects = function () {

        if (!stringUtilities.isNullOrEmpty(organisationUnitId)) {

            setIsLoading(true);
            api.getProjects(organisationUnitId, (data) => {
                setProjects(data);
                setIsLoading(false);
            }, () => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        setFilteredProjects(arrayUtilities.isNullOrEmpty(projects) ? [] : projects.filter((project) => {
            return stringUtilities.isNullOrEmpty(filter) || project.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || project.id.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, projects]);

    useEffect(() => {
        getProjects();
    }, [organisationUnitId]);

    return <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
        <Row>
            <Col className='mb-5'>
                <div className='d-flex justify-content-between my-5'>
                    <h1 className="h2 m-0">Projects</h1>

                    <div className='d-flex justify-content-end'>
                        <FilterBar value={filter} onChange={setFilter} className="me-4" />


                        <OrganisationSelector value={organisationUnitId} organisations={props.organisationalUnits} onChange={setOrganisationalUnitId} />

                        {/* <Button href='#' size='sm' className='ms-2'>
                            <i className='fi-plus me-2'></i>
                            Add project
                        </Button> */}
                    </div>
                </div>

                {isLoading && <Loading />}
                {!isLoading &&

                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <td>&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {!arrayUtilities.isNullOrEmpty(filteredProjects) && filteredProjects.map((project, index) => <tr index={index} key={`project-${project.id}`}>
                                <td className="align-middle">{project.name}</td>
                                <td className="text-end">
                                    <Link to={`/${organisationUnitId}/projects/${project.id}/jobs`} className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                        <i className='fi-briefcase me-2'></i> Jobs
                                    </Link>
                                   

                                    <Button variant='light-primary shadow-sm' size='sm' className='me-2' disabled>
                                        <i className='fi-route me-2'></i> Workflows
                                    </Button>

                                    <Button variant='light-primary shadow-sm' size='sm' className='me-2' disabled>
                                        <i className='fi-code me-2'></i> KML Files
                                    </Button>

                                    <Button variant='light-primary shadow-sm' size='sm' className='me-2' disabled>
                                        <i className='fi-layers me-2'></i> Surfaces
                                    </Button>

                                    <Link to={`/${organisationUnitId}/projects/${project.id}/map`} className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                        <i className='fi-map-pin me-2'></i> Map
                                    </Link>

                                    <Button variant='secondary' size="sm" className='me-2' disabled>
                                        <i className='fi-folders me-2'></i> Copy
                                    </Button>

                                    <Button variant='secondary' size="sm" className='me-2' disabled>
                                        <i className='fi-edit me-2'></i> Edit
                                    </Button>

                                    <Button variant='outline-danger' size="sm" disabled>
                                        <i className='fi-trash me-2'></i> Delete
                                    </Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                }

            </Col>
        </Row>
    </Container>;
}