import { useEffect, useState } from 'react';
import { Polygon, Rectangle, useMap } from 'react-leaflet';
import arrayUtilities from "../../utilities/arrayUtilities";
import { Button } from 'react-bootstrap';
import EditablePolygon from './editablePolygon';
import DeleteButton from '../common/deleteButton';
import EditableRectangle from './editableRectangle';
import postal from 'postal';
import ConfigurationDialog from './drawingTools/_configurationDialog';
import WeightSelect from '../form/weightSelect';
import ColorPicker from '../form/colorPicker';
import OpacitySlider from '../form/opacitySlider';
import Textbox from '../form/textbox';
import hash from 'object-hash';

export default function Limit(props) {

    const map = useMap();

    const [mapOutputRatio, setMapOutputRatio] = useState(null);
    const [mapOutputWidth, setMapOutputWidth] = useState(800);
    const [mapOutputHeight, setMapOutputHeight] = useState(450);

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);

    const [limitOfLocateCoords, setLimitOfLocateCoords] = useState(props.limit?.limitOfLocateCoords ?? []);
    const [isEditingLimitOfLocate, setIsEditingLimitOfLocate] = useState(false);

    const [limitOfSketchCoords, setLimitOfSketchCoords] = useState(props.limit?.limitOfSketchCoords ?? []);
    const [isEditingLimitOfSketch, setIsEditingLimitOfSketch] = useState(false);

    const [name, setName] = useState(props.limit?.name ?? `Limit ${props.index + 1}`);

    const configuration = props.limit?.configuration ?? {};

    const [northBoundary, setNorthBoundary] = useState(configuration.northBoundary ?? "");
    const [southBoundary, setSouthBoundary] = useState(configuration.southBoundary ?? "");
    const [eastBoundary, setEastBoundary] = useState(configuration.eastBoundary ?? "");
    const [westBoundary, setWestBoundary] = useState(configuration.westBoundary ?? "");
    const [weight, setWeight] = useState(Number(configuration.tool?.weight ?? 4));
    const [color, setColor] = useState(configuration.tool?.color ?? "#000000");
    const [opacity, setOpacity] = useState(configuration.tool?.opacity ?? 1);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {
        if (props.onSave) {

            let limit = {
                id: props.limit?.id ?? null,
                name,
                limitOfLocateCoords,
                limitOfSketchCoords,
                configuration: {
                    northBoundary,
                    southBoundary,
                    eastBoundary,
                    westBoundary,
                    tool: {
                        weight,
                        color,
                        opacity
                    }
                }
            }

            props.onSave(limit);
        }
    }

    const deleteLimitOfLocate = function () {
        setLimitOfLocateCoords([]);
        setIsEditingLimitOfLocate(false);
    }

    const deleteLimitOfSketch = function () {
        setLimitOfSketchCoords([]);
        setIsEditingLimitOfSketch(false);
    }

    const onLimitOfSketchChange = function (northEast, southWest) {
        setLimitOfSketchCoords([northEast, southWest])
    }

    const rotateLimitOfSketch = function () {

        let p0 = map.latLngToContainerPoint(limitOfSketchCoords[0]);
        let p1 = map.latLngToContainerPoint(limitOfSketchCoords[1]);

        let minX = Math.min(p0.x, p1.x);
        let minY = Math.min(p0.y, p1.y);

        let maxX = Math.max(p0.x, p1.x);
        let maxY = Math.max(p0.y, p1.y);

        let centerPoint = {
            x: minX + ((maxX - minX) / 2.0),
            y: minY + ((maxY - minY) / 2.0)
        }

        let newP0 = {
            x: centerPoint.x + ((maxY - minY) / 2.0),
            y: centerPoint.y + ((maxX - minX) / 2.0)
        }

        let newP1 = {
            x: centerPoint.x - ((maxY - minY) / 2.0),
            y: centerPoint.y - ((maxX - minX) / 2.0)
        }
        
        setLimitOfSketchCoords([map.containerPointToLatLng(newP0), map.containerPointToLatLng(newP1)]);
    };

    useEffect(() => {
        setMapOutputRatio(mapOutputHeight / mapOutputWidth)
    }, [mapOutputWidth, mapOutputHeight])

    useEffect(() => {

        if (isEditingLimitOfSketch && arrayUtilities.isNullOrEmpty(limitOfSketchCoords)) {
            let centerPoint = map.latLngToContainerPoint(map.getCenter());
            let n = centerPoint.y - mapOutputHeight / 2;
            let s = centerPoint.y + mapOutputHeight / 2;
            let e = centerPoint.x + mapOutputWidth / 2;
            let w = centerPoint.x - mapOutputWidth / 2;

            setLimitOfSketchCoords([map.containerPointToLatLng({ x: e, y: n }), map.containerPointToLatLng({ x: w, y: s })]);
        }

    }, [isEditingLimitOfSketch]);

    useEffect(() => {

        let mapOutputWidth = props.project?.configuration?.mapOutputWidth;
        let mapOutputHeight = props.project?.configuration?.mapOutputHeight;

        if (!isNaN(mapOutputWidth) && mapOutputWidth !== 0 && !isNaN(mapOutputHeight) && mapOutputHeight !== 0) {
            setMapOutputWidth(mapOutputWidth);
            setMapOutputHeight(mapOutputHeight);
        }

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, []);

    let lolPathOptions = { color, weight, fill: (isDrawing || isEditing), opacity, fillColor: color, fillOpacity: (opacity * 0.5) };
    let losPathOptions = { color: "#00ff00", weight: 2, fill: (isDrawing || isEditing), opacity: 1, fillColor: "#00ff00", fillOpacity: 0.5 };

    return <>
        {(printingSketchLimitIndex === null || printingSketchLimitIndex === props.index) && <>
            {isEditingLimitOfLocate && <>
                <EditablePolygon coords={limitOfLocateCoords} pathOptions={lolPathOptions} onChange={setLimitOfLocateCoords} isDrawing={arrayUtilities.isNullOrEmpty(limitOfLocateCoords)}></EditablePolygon>
            </>}
            {!isEditingLimitOfLocate && !arrayUtilities.isNullOrEmpty(limitOfLocateCoords) &&
                <Polygon positions={[limitOfLocateCoords.map(x => [x.lat, x.lng])]} pathOptions={lolPathOptions}></Polygon>
            }

            {printingSketchLimitIndex === null && <>
                {isEditingLimitOfSketch && !arrayUtilities.isNullOrEmpty(limitOfSketchCoords) && limitOfSketchCoords.length == 2 &&
                    <EditableRectangle key={hash(limitOfSketchCoords)} pathOptions={losPathOptions} ratio={mapOutputRatio} northEast={limitOfSketchCoords[0]} southWest={limitOfSketchCoords[1]} onChange={onLimitOfSketchChange} allowResize />
                }
                {!isEditingLimitOfSketch && !arrayUtilities.isNullOrEmpty(limitOfSketchCoords) && limitOfSketchCoords.length == 2 &&
                    <Rectangle bounds={[[limitOfSketchCoords[0].lat, limitOfSketchCoords[0].lng], [limitOfSketchCoords[1].lat, limitOfSketchCoords[1].lng]]} pathOptions={losPathOptions} />
                }
            </>}

            <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Limit`} onCancel={onCancel} onSave={onSave}>

                <div className="d-flex justify-content-between align-items-center w-100 pb-3 mb-3 border-bottom">
                    <p className='m-0 h6 fs-sm'>Located Area</p>
                    <div>
                        {!isEditingLimitOfLocate && arrayUtilities.isNullOrEmpty(limitOfLocateCoords) &&
                            <Button size="xs" variant="success btn-icon" disabled={isEditingLimitOfSketch} onClick={() => setIsEditingLimitOfLocate(true)}><i className='fi-plus' /></Button>
                        }
                        {!isEditingLimitOfLocate && !arrayUtilities.isNullOrEmpty(limitOfLocateCoords) &&
                            <Button size="xs" variant="success btn-icon" disabled={isEditingLimitOfSketch} onClick={() => setIsEditingLimitOfLocate(true)}><i className='fi-pencil' /></Button>
                        }
                        {isEditingLimitOfLocate &&
                            <Button size="xs" variant="success btn-icon" onClick={() => setIsEditingLimitOfLocate(false)}><i className='fi-check' /></Button>
                        }

                        <DeleteButton size="xs" compact className="ms-1" disabled={arrayUtilities.isNullOrEmpty(limitOfLocateCoords) || isEditingLimitOfSketch} modalTitle="Delete Limit of Locate" modalContent={`Are you sure you want to permanently delete the Limit of Locate'?`} onConfirm={deleteLimitOfLocate} />
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center w-100 pb-3 mb-3 border-bottom">
                    <p className='m-0 h6 fs-sm'>Limit of Sketch</p>
                    <div>
                        {!isEditingLimitOfSketch && arrayUtilities.isNullOrEmpty(limitOfSketchCoords) &&
                            <Button size="xs" variant="success btn-icon" disabled={isEditingLimitOfLocate} onClick={() => setIsEditingLimitOfSketch(true)}><i className='fi-plus' /></Button>
                        }
                        {!isEditingLimitOfSketch && !arrayUtilities.isNullOrEmpty(limitOfSketchCoords) &&
                            <Button size="xs" variant="success btn-icon" disabled={isEditingLimitOfLocate} onClick={() => setIsEditingLimitOfSketch(true)}><i className='fi-pencil' /></Button>
                        }
                        {isEditingLimitOfSketch && <>
                            <Button size="xs" variant="secondary btn-icon" className="ms-1" onClick={rotateLimitOfSketch}><i className='fi-rotate-right' /></Button>
                            <Button size="xs" variant="success btn-icon" className="ms-1" onClick={() => setIsEditingLimitOfSketch(false)}><i className='fi-check' /></Button>
                        </>}

                        <DeleteButton size="xs" compact className="ms-1" disabled={arrayUtilities.isNullOrEmpty(limitOfSketchCoords) || isEditingLimitOfLocate} modalTitle="Delete Limit of Sketch" modalContent={`Are you sure you want to permanently delete the Limit of Sketch'?`} onConfirm={deleteLimitOfSketch} />
                    </div>
                </div>

                <Textbox label="Name" value={name} onChange={setName} />
                <WeightSelect value={weight} onChange={setWeight} />
                <ColorPicker value={color} onChange={setColor} />
                <OpacitySlider value={opacity} onChange={setOpacity} />

                <Textbox label="North Boundary" value={northBoundary} onChange={setNorthBoundary} />
                <Textbox label="South Boundary" value={southBoundary} onChange={setSouthBoundary} />
                <Textbox label="East Boundary" value={eastBoundary} onChange={setEastBoundary} />
                <Textbox label="West Boundary" value={westBoundary} onChange={setWestBoundary} />

            </ConfigurationDialog>
        </>}
    </>
}