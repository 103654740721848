import { Form } from "react-bootstrap";
import ReactSlider from "react-slider";
import stringUtilities from "./../../utilities/stringUtilities";

export default function OpacitySlider(props) {

    const onChange = function(e) {
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return <Form.Group controlId='input-small' className='mb-3'>
        <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
            {stringUtilities.isNullOrEmpty(props.label) ? "Opacity" : props.label}
        </Form.Label>
        <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={1} value={props.value} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.01} onChange={onChange} renderThumb={(props, state) => (
            <div {...props}>
                <div className='range-slider-tooltip'>{Math.round(state.valueNow * 100)}%</div>
            </div>
        )}
        />
    </Form.Group>
}