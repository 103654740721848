import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import { divIcon } from "leaflet";
import postal from "postal";
import ConfigurationDialog from "./_configurationDialog";
import ScaleSlider from "../../form/scaleSlider";
import MapPopup from "./_mapPopup";
import WorkflowSelect from "../../form/workflowSelect";
import LimitSelect from "../../form/limitSelect";
import geospatialUtilities from "../../../utilities/geospatialUtilities";
import stringUtilities from "../../../utilities/stringUtilities";

export default function NorthArrowDrawingTool(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [shouldPrint, setShouldPrint] = useState(true);

    const [coords, setCoords] = useState(null);
    const [scale, setScale] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);

    const map = useMap();

    const reset = function () {
        setCoords(props.sketchItem?.coords ?? []);
        setScale(props.sketchItem?.tool?.properties?.scale ?? 1);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords,
                tool: {
                    id: 4,
                    properties: {
                        scale,
                        workflowId,
                        limitId
                    }
                }
            }
            props.onSave(sketchItem, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    const onMarkerDragEnd = function (e, index) {
        setCoords([e.target._latlng]);
    }

    useMapEvents({
        click: (e) => {
            if (props.isDrawing) {
                setCoords([e.latlng]);
            }
        },
    })

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

        map.closePopup();
    }, [isEditing])

    useEffect(() => {
        
        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {

        let shouldPrint = true;

        if (!arrayUtilities.isNullOrEmpty(coords) && ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId))) {

            if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                shouldPrint = false;
            }

            if (shouldPrint && !stringUtilities.isNullOrEmpty(workflowId)) {
                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                    shouldPrint = false;
                }
            }

            if (shouldPrint && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {
                let limit = props.sketch.limits[printingSketchLimitIndex];

                if (limit !== null && !arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) && !geospatialUtilities.polygonContainsPoint(geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords), coords[0])) {
                    shouldPrint = false;
                }
            }
        }

        setShouldPrint(shouldPrint);

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let icon = divIcon({
        html: `<img src="/Content/Json/Icons/NorthArrow.svg">`,
        className: "marker-icon-north-arrow",
        iconAnchor: [30 * scale * 0.5, 30 * scale * 0.5],
        iconSize: [30 * scale, 30 * scale]
    });

    return <>

        {!arrayUtilities.isNullOrEmpty(coords) && isVisible && shouldPrint && <>

            {coords.map((coord, index) => <Marker
                index={index}
                key={`workflow-drawing-tool-point-${index}`}
                icon={icon}
                draggable={isDrawing || isEditing}
                position={[coord.lat, coord.lng]}
                bubblingMouseEvents={false}
                eventHandlers={{
                    dragend: (e) => onMarkerDragEnd(e, index)
                }}>
                {!isDrawing && !isEditing &&
                    <MapPopup title="North Arrow" onEdit={() => setIsEditing(true)} deleteTitle="Delete North Arrow" deleteContent="Are you sure you want to permanently delete this north arrow?" onDelete={onDelete} />
                }
            </Marker>)}
        </>}

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} North Arrow`} onCancel={onCancel} onSave={onSave}>
            <ScaleSlider value={scale} onChange={setScale} />
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
            
        </ConfigurationDialog>
    </>
}