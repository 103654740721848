import { Marker } from "react-leaflet";
import pointUtilities from "../../utilities/pointUtilities";
import { divIcon } from "leaflet";
import stringUtilities from "../../utilities/stringUtilities";
import DataPopup from "./dataPopup";
import WhenMapIsNotDrawingOrEditing from "./whenMapIsNotDrawingOrEditing";
import { useEffect, useState } from "react";
import postal from "postal";
import arrayUtilities from "../../utilities/arrayUtilities";
import geospatialUtilities from "../../utilities/geospatialUtilities";
import snapUtilities from "../../utilities/snapUtilities";
import ConfigurationDialog from "./drawingTools/_configurationDialog";
import RotationSlider from "../form/rotationSlider";


export default function DataPoint(props) {

    const [isNormallyVisible, setIsNormallyVisible] = useState(false);
    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [shouldPrint, setShouldPrint] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [rotate, setRotate] = useState(null);

    const isVisible = props.point && props.point !== null && ((stringUtilities.isNullOrEmpty(props.point.lineId) && props.workflowPoints?.includes(props.point.workflowId)) || (!stringUtilities.isNullOrEmpty(props.point.lineId) && props.workflowPointsLines?.includes(props.point.workflowId)));

    const reset = function() {

        setRotate(0);

        if (!arrayUtilities.isNullOrEmpty(props.sketch?.configuration?.data)) {
            let dataItem = props.sketch.configuration.data.find(x => x.id === props?.point?.id);
            if (dataItem && dataItem !== null) {
                setRotate(dataItem.rotate);
            }
        }
    }

    const onCancel = function() {
        setIsEditing(false);
        reset();
    }

    const onSave = function() {
        if (props.onSave) {
            props.onSave({
                id: props.point.id,
                rotate
            });
        }

        setIsEditing(false);
    }

    
    const isNormallyVisibleForWorkflowId = function (workflowId) {
        let typeIsNormallyVisible = pointUtilities.getType(props.point.workflowId, props.point.formData?.Type, props.workflows, false)?.isNormallyVisible ?? false;

        if (typeIsNormallyVisible) {
            let dimensionLines = props.sketch?.items.filter(x => x.tool?.id === 1);
            if (!arrayUtilities.isNullOrEmpty(dimensionLines)) {
                for (let i = 0; i < dimensionLines.length; i++) {

                    if (!arrayUtilities.isNullOrEmpty(dimensionLines[i].coords)) {

                        let workflowIds = [];
                        for (let j = 0; j < dimensionLines[i].coords.length; j++) {
                            let snapResult = snapUtilities.snapToNearest(dimensionLines[i].coords[j], props.snappingTolerance, props.points, props.lines, props.sketch, false, props.workflowLines, props.workflowPoints, props.workflowPointsLines);

                            if (snapResult && !stringUtilities.isNullOrEmpty(snapResult.workflowId)) {
                                workflowIds.push(snapResult.workflowId);
                            }
                        }

                        if (workflowIds.includes(workflowId) && workflowIds.includes(props.point.workflowId)) {
                            return true;
                        }
                    }                    
                }
            }
        }

        return false;
    }

    useEffect(() => {

        let isNormallyVisible = isNormallyVisibleForWorkflowId(printingWorkflowId);
        setIsNormallyVisible(isNormallyVisible);

        if (isVisible || isNormallyVisible) {
            let shouldPrint = true;

            if ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === props.point.workflowId);

                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== props.point.workflowId && (!workflow || workflow === null || !workflow.isComposite) && !isNormallyVisible) {
                    shouldPrint = false;
                }

                if (shouldPrint && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {
                    let limit = props.sketch.limits[printingSketchLimitIndex];

                    if (limit !== null) {
                        let polygon = workflow.isComposite ?
                            (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) ? geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords) : null) :
                            (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords) ? limit.limitOfLocateCoords : null);

                        if (polygon !== null && !geospatialUtilities.polygonContainsPoint(polygon, props.point)) {
                            shouldPrint = false;
                        }
                    }
                }
            }

            setShouldPrint(shouldPrint);
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    
    useEffect(() => {
        reset();
    }, [props.sketch])
    
    useEffect(() => {

        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, []);


    if ((isVisible || isNormallyVisible) && shouldPrint) {
        let iconUrl = pointUtilities.getIconUrlFromPoint(props.point, props.workflows);
        let color = pointUtilities.getColorFromPoint(props.point, props.workflows);

        let icon = stringUtilities.isNullOrEmpty(iconUrl) ? divIcon({
            html: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="${color}" /></svg>`,
            className: "marker-icon-point",
            iconAnchor: [8, 8],
            iconSize: [16, 16]
        }) : divIcon({
            html: `<img src="${iconUrl}" style="transform: rotate(${rotate ?? 0}deg);">`,
            className: "marker-icon-point",
            iconAnchor: [15 * props.symbolScale, 15 * props.symbolScale],
            iconSize: [30 * props.symbolScale, 30 * props.symbolScale]
        });


        return <>
            <Marker position={{ lat: props.point.lat, lng: props.point.lng }} icon={icon} pointId={props.point.id}>
                <WhenMapIsNotDrawingOrEditing>
                    <DataPopup organisationId={props.organisationId} user={props.user} canEdit={props.sketch && props.sketch !== null} onEdit={() => setIsEditing(true)} projectId={props.projectId} pointId={props.point.id} canDeletePoint canDeleteGeometry={props.point.lineId && props.point.lineId !== null} geometryType={props.point.geometryType} />
                </WhenMapIsNotDrawingOrEditing>
            </Marker>
            <ConfigurationDialog show={isEditing} title="Edit Point" onCancel={onCancel} onSave={onSave}>
                <RotationSlider value={rotate} onChange={setRotate} />
            </ConfigurationDialog>
        </>;
    }

    return null;
}