import enums from "./enums";
import geospatialUtilities from "./geospatialUtilities";
import arrayUtilities from "./arrayUtilities";
import stringUtilities from "./stringUtilities";

export default {

    snapToNearest(latlng, snappingTolerance, points, lines, sketch, workflows, disableSnapToLimit, workflowLines, workflowPoints) {
        let candidates = [];

        if (!arrayUtilities.isNullOrEmpty(lines)) {
            lines.forEach((lineId) => {
                if (!arrayUtilities.isNullOrEmpty(points)) {
                    let linePoints = points.filter(x => x.lineId === lineId);
                    if (!arrayUtilities.isNullOrEmpty(linePoints) && linePoints.length > 1 && (arrayUtilities.isNullOrEmpty(workflowLines) || workflowLines.includes(linePoints[0].workflowId))) {                        

                        let closestPoint = geospatialUtilities.getClosestPointOnLines(latlng, linePoints);
                        let meters = geospatialUtilities.haversineDistance(closestPoint, latlng);

                        if (meters <= snappingTolerance) {

                            candidates.push({
                                label: enums.GeometryType[linePoints[0].geometryType],
                                latlng: { lat: closestPoint.lat, lng: closestPoint.lng },
                                distance: meters,
                                workflowId: linePoints[0].workflowId,
                                type: linePoints[0].formData?.Type
                            })
                        }
                    }
                }
            })
        }
        
        if (!arrayUtilities.isNullOrEmpty(points)) {
            points.forEach((point) => {

                if (point.geometryType === 0 && (arrayUtilities.isNullOrEmpty(workflowPoints) || workflowPoints.includes(point.workflowId))) {
                    let distance = geospatialUtilities.haversineDistance({ lat: point.lat, lng: point.lng }, latlng);                    
             
                    if (distance <= snappingTolerance) {
                        candidates.push({
                            label: "Point",
                            latlng: { lat: point.lat, lng: point.lng },
                            distance,
                            workflowId: point.workflowId,
                            type: point.formData?.Type
                        })
                    }
                }
            })
        }

        if (!disableSnapToLimit && !arrayUtilities.isNullOrEmpty(sketch?.limits)) {
            sketch.limits.forEach((limit) => {
                if (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords)) {

                    let closestPoint = geospatialUtilities.getClosestPointOnLines(latlng, limit.limitOfLocateCoords);
                    let meters = geospatialUtilities.haversineDistance(closestPoint, latlng);

                    if (meters <= snappingTolerance) {
                        candidates.push({ label: "Located area", latlng: { lat: closestPoint.lat, lng: closestPoint.lng }, distance: meters, workflowId: null, type: null })
                    }
                }
            });
        }

        if (!arrayUtilities.isNullOrEmpty(sketch?.items)) {
            sketch.items.forEach((item) => {

                if (!arrayUtilities.isNullOrEmpty(item.coords)) {
                    
                    if (item.tool?.id === 16) {
                        
                        if (!arrayUtilities.isNullOrEmpty(workflows) && !stringUtilities.isNullOrEmpty(item.tool?.properties?.workflowId) && !stringUtilities.isNullOrEmpty(item.tool?.properties?.type)) {
                            let workflow = workflows.find(x => x.id === item.tool?.properties?.workflowId);
                            let workflowType;

                            if (workflow && workflow !== null) {
                                let configuration = JSON.parse(workflow.configuration);

                                if (!arrayUtilities.isNullOrEmpty(configuration.types)) {
                                    workflowType = configuration.types.find(x => x.name === item.tool?.properties?.type);
                                }

                                if ((!workflowType || workflowType === null) && !arrayUtilities.isNullOrEmpty(configuration.types2)) {
                                    workflowType = configuration.types2.find(x => x.name === item.tool?.properties?.type);
                                }
                            }

                            if (workflowType && workflowType !== null && workflowType.geometryType === "Line") {

                                if (!arrayUtilities.isNullOrEmpty(item.coords) && item.coords.length > 1) {

                                    let closestPoint = geospatialUtilities.getClosestPointOnLines(latlng, item.coords);                                    
                                    let meters = geospatialUtilities.haversineDistance(closestPoint, latlng);

                                    if (meters <= snappingTolerance) {
                                        candidates.push({
                                            label: "Workflow",
                                            latlng: { lat: closestPoint.lat, lng: closestPoint.lng },
                                            distance: meters,
                                            workflowId: item.tool?.properties?.workflowId,
                                            type: item.tool?.properties?.type
                                        })
                                    }
                                }

                            } else {
                                item.coords.forEach((coord) => {
                                    let distance = geospatialUtilities.haversineDistance({ lat: coord.lat, lng: coord.lng }, latlng);
                                    if (distance <= snappingTolerance) {
                                        candidates.push({
                                            label: "Workflow",
                                            latlng: { lat: coord.lat, lng: coord.lng },
                                            distance,
                                            workflowId: item.tool?.properties?.workflowId,
                                            type: item.tool?.properties?.type
                                        })
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }

        if (candidates.length === 0) {
            return { latlng };

        } else {
            candidates.sort((a, b) => a.distance - b.distance);
            return candidates[0];
        }
    }
}