import { Button, Form, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "./form/textbox";
import { useEffect, useState } from "react";
import stringUtilities from "../utilities/stringUtilities";
import useApi from './../utilities/useApi';
import Loading from "./common/loading";



export default function UserModal(props) {

    const { api } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [roleId, setRoleId] = useState(2);

    const [canSave, setCanSave] = useState(false);

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {

        setIsLoading(true);

        api.addUser(props.organisationId, firstName, lastName, emailAddress, roleId, (data) => {
            
            if (props.onSave) {
                props.onSave();
            }
        }, () => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(firstName) && !stringUtilities.isNullOrEmpty(lastName) && !stringUtilities.isNullOrEmpty(emailAddress))
    }, [firstName, lastName, emailAddress])

    return <Modal show onHide={onCancel} centered>
        <Modal.Header closeButton>
            <Modal.Title>Add user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)'}}>
                <Textbox label="First name" value={firstName} onChange={setFirstName} />
                <Textbox label="Last name" value={lastName} onChange={setLastName} />
                <Textbox label="Email address" value={emailAddress} onChange={setEmailAddress} />
                <Form.Group className='mb-3'>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                        Role
                    </Form.Label>
                    <Form.Check type='radio' id='roleId-2' name='radio' value={2} checked={roleId === 2} label='User' onChange={(e) => setRoleId(Number(e.target.value))} />
                    <Form.Check type='radio' id='roleId-1' name='radio' value={1} checked={roleId === 1} label='Organization Administrator' onChange={(e) => setRoleId(Number(e.target.value))} />
                </Form.Group>



            </SimpleBar>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <div>
                {isLoading && <Loading />}
            </div>
            
            <div>
                <Button size="sm" variant='secondary' onClick={onCancel} className="me-2">Cancel</Button>
                <Button size="sm" variant='primary' onClick={onSave} disabled={!canSave}>Save</Button>
            </div>
        </Modal.Footer>
    </Modal>
}