import { divIcon } from "leaflet";

const dragHandleHtml = `<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="10" stroke="#000" stroke-width="3" fill="#000" /></svg>`;

const dragHandle = divIcon({
    html: dragHandleHtml,
    className: "marker-icon-point move",
    draggable: true,
    iconAnchor: [5, 5],
    iconSize: [10, 10]
});

const dragHandleNesw = divIcon({
    html: dragHandleHtml,
    className: "marker-icon-point nesw",
    draggable: true,
    iconAnchor: [5, 5],
    iconSize: [10, 10]
});

const dragHandleNwse = divIcon({
    html: dragHandleHtml,
    className: "marker-icon-point nwse",
    draggable: true,
    iconAnchor: [5, 5],
    iconSize: [10, 10]
});

const dragHandleNs = divIcon({
    html: dragHandleHtml,
    className: "marker-icon-point ns",
    draggable: true,
    iconAnchor: [5, 5],
    iconSize: [10, 10]
});

const dragHandleEw = divIcon({
    html: dragHandleHtml,
    className: "marker-icon-point ew",
    draggable: true,
    iconAnchor: [5, 5],
    iconSize: [10, 10]
});

export default {
    dragHandle,
    dragHandleNesw,
    dragHandleNwse,
    dragHandleNs,
    dragHandleEw
}