
import { useEffect, useState } from 'react'
import useApi from './../utilities/useApi';
import arrayUtilities from '../utilities/arrayUtilities';
import stringUtilities from '../utilities/stringUtilities'
import OrganisationSelector from './common/organisationSelector';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import FilterBar from './common/filterBar';
import Loading from './common/loading';
import DeleteButton from './common/deleteButton';
import cloneDeep from 'lodash.clonedeep';
import ConfirmButton from './common/confirmButton';
import hash from 'object-hash';
import UserModal from './_userModal';

export default function Users(props) {
    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [organisationUnitId, setOrganisationalUnitId] = useState(arrayUtilities.isNullOrEmpty(props.organisationalUnits) ? "" : props.organisationalUnits[0].id)
    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [filter, setFilter] = useState("");
    const [users, setUsers] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [showUserModal, setShowUserModal] = useState(false);

    const getUsers = function () {

        if (!stringUtilities.isNullOrEmpty(organisationUnitId)) {

            setIsLoading(true);
            api.getUsers(organisationUnitId, includeDeleted, (data) => {
                setUsers(data);
                setIsLoading(false);
            }, () => {
                setIsLoading(false);
            });
        }
    };

    const setUserEnabled = function (userId, enabled) {
        api.patchUser(organisationUnitId, userId, !enabled, null, () => {
        }, () => {
        });
    }

    const deleteUser = function (userId) {
        api.deleteUser(organisationUnitId, userId, () => {
            if (includeDeleted) {
                let copy = cloneDeep(users);
                let user = copy.find(x => x.id === userId);
                user.deleted = true;

                setUsers(copy);
            } else {
                setUsers(users.filter(x => x.id !== userId));
            }
        }, () => {

        });
    }

    const undeleteUser = function (userId) {
        api.patchUser(organisationUnitId, userId, null, false, () => {
            let copy = cloneDeep(users);
            let user = copy.find(x => x.id === userId);
            user.deleted = false;

            setUsers(copy);
        }, () => {

        });
    }

    const addUser = function() {
        getUsers();
        setShowUserModal(false);
    }

    useEffect(() => {
        setFilteredUsers(arrayUtilities.isNullOrEmpty(users) ? [] : users.filter((user) => {
            return stringUtilities.isNullOrEmpty(filter) || user.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || user.emailAddress.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, users]);

    useEffect(() => {
        getUsers();
    }, [organisationUnitId, includeDeleted]);

    return <>
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2 report user-activity-report'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Users</h1>

                        <div className='d-flex justify-content-end'>

                            <Form.Group className='d-flex align-items-center flex-shrink-0 me-4'>
                                <Form.Label className='text-body fs-sm me-2 mb-0 pe-1 text-nowrap'>
                                    Include deleted
                                </Form.Label>
                                <InputGroup size="sm">

                                    <Form.Check type='switch' id={`include-deleted`} className="me-2 pt-2" checked={includeDeleted} onChange={(e) => setIncludeDeleted(e.target.checked)} />
                                </InputGroup>
                            </Form.Group>

                            <FilterBar value={filter} onChange={setFilter} className="me-4" />
                            <OrganisationSelector value={organisationUnitId} organisations={props.organisationalUnits} onChange={setOrganisationalUnitId} />


                            <Button size='sm' className='ms-2' onClick={() => setShowUserModal(true)}>
                                <i className='fi-plus me-2'></i>
                                Add user
                            </Button>
                        </div>
                    </div>

                    {isLoading && <Loading />}
                    {!isLoading &&

                        <Table responsive hove sm>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email Address</th>
                                    <th className='text-center'>Enabled</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!arrayUtilities.isNullOrEmpty(filteredUsers) && filteredUsers.map((user, index) => <tr index={index} key={hash(user)} className='align-middle'>
                                    <td>
                                        <span className={user.deleted ? `text-muted` : ""}>{user.name}</span>
                                    </td>
                                    <td>
                                        <span className={user.deleted ? `text-muted` : ""}>{user.emailAddress}</span>
                                    </td>
                                    <td className='text-center'>
                                        <Form.Check type='switch' id={`user-${user.id}-enabled`} className="me-2" value={user.id} defaultChecked={!user.disabled} disabled={user.deleted} onChange={(e) => setUserEnabled(user.id, e.target.checked)} />
                                    </td>
                                    <td className='text-end'>
                                        {user.deleted &&
                                            <ConfirmButton size="xs" variant="light" icon="fi-rotate-left" label="Un-delete" modalTitle="Un-delete user" modalContent={`Are you sure you want to un-delete the user ${user.emailAddress}?`} onConfirm={() => undeleteUser(user.id)} />
                                        }
                                        {!user.deleted &&
                                            <DeleteButton size="xs" modalTitle="Delete user" modalContent={`Are you sure you want to delete the user ${user.emailAddress}?`} onConfirm={() => deleteUser(user.id)} />
                                        }
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    }

                </Col>
            </Row>
        </Container>
        {showUserModal && <UserModal organisationId={organisationUnitId} onCancel={() => setShowUserModal(false)} onSave={addUser} />}
    </>;


}