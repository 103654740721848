import { useEffect, useRef, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import postal from 'postal'

export default function AddressSearchToolbox(props) {

    const [value, setValue] = useState("");
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false
    };

    useEffect(() => {

        setValue("");
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );

        autoCompleteRef.current.addListener("place_changed", async function () {
            let place = await autoCompleteRef.current.getPlace();
            if (place && place.geometry && place.geometry.location) {
                postal.publish({
                    channel: "map",
                    topic: "view.change",
                    data: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    }
                });

                setValue("");
            }

        });
    }, []);

    return <Accordion.Item eventKey='0'>
        <Accordion.Header><i className='fi-map-pin me-2' /> Address Search</Accordion.Header>
        <Accordion.Body>
            <Form.Control ref={inputRef} value={value} onChange={(e) => setValue(e.target.value)} />
        </Accordion.Body>
    </Accordion.Item>

}