import postal from "postal";
import { useEffect, useState } from "react";

export default function WhenMapIsNotDrawingOrEditing(props) {

    const [mapIsDrawingOrEditing, setMapIsDrawingOrEditing] = useState(false);

    useEffect(() => {

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "isDrawingOrEditing",
                callback: function (data) {
                    setMapIsDrawingOrEditing(data.value);
                }
            })
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, []);

    return <>
        {!mapIsDrawingOrEditing &&
            <>
                {props.children}
            </>
        }
    </>
}