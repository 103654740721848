import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Marker, Polyline, useMap, useMapEvents } from "react-leaflet";
import cloneDeep from 'lodash.clonedeep';

import postal from "postal";
import ConfigurationDialog from "./_configurationDialog";
import Textbox from "../../form/textbox";
import MapPopup from "./_mapPopup";
import WeightSelect from "../../form/weightSelect";
import ColorPicker from "../../form/colorPicker";
import OpacitySlider from "../../form/opacitySlider";
import WorkflowSelect from "../../form/workflowSelect";
import mapIcons from "../mapIcons";
import SnapToPoint from "../snapToPoint";
import SnappableMarker from "../snappableMarker";
import { divIcon } from "leaflet";
import stringUtilities from "../../../utilities/stringUtilities";
import geospatialUtilities from "../../../utilities/geospatialUtilities";
import { Button } from "react-bootstrap";
import FontSizeSelect from "../../form/fontSizeSelect";
import RotationSlider from "../../form/rotationSlider";
import WidthSlider from "../../form/widthSlider";
import LimitSelect from "../../form/limitSelect";

export default function NoteWithLeader(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);
    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [shouldPrint, setShouldPrint] = useState(true);

    const [coords, setCoords] = useState(null);
    const [label, setLabel] = useState(null);
    const [weight, setWeight] = useState(null);
    const [color, setColor] = useState(null);
    const [opacity, setOpacity] = useState(null);
    const [rotate, setRotate] = useState(null);
    const [width, setWidth] = useState(null);
    const [fontsize, setFontsize] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);
    const [labelCoord, setLabelCoord] = useState(null);
    
    const [angle, setAngle] = useState(0);

    const map = useMap();

    const snappingTolerance = Math.max(props.snappingTolerance, 0.5);

    const reset = function () {
        setCoords(props.sketchItem?.coords ?? []);
        setRotate(props.sketchItem?.tool?.properties?.rotate);
        setWidth(props.sketchItem?.tool?.properties?.width);
        setFontsize(props.sketchItem?.tool?.properties?.fontsize ?? "font-smaller");
        setLabel(props.sketchItem?.tool?.properties?.label ?? "");
        setWeight(props.sketchItem?.tool?.properties?.weight ?? 1);
        setColor(props.sketchItem?.tool?.properties?.color ?? "#000000");
        setOpacity(props.sketchItem?.tool?.properties?.opacity ?? 1);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);

        let markers = props.sketchItem?.markers ?? []
        setLabelCoord(arrayUtilities.isNullOrEmpty(markers) || !markers[0].lat || markers[0].lat === null || !markers[0].lng || markers[0].lng === null ? null : markers[0]);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords,
                tool: {
                    id: 11,
                    properties: {
                        label,
                        weight,
                        color,
                        opacity,
                        rotate,
                        width,
                        fontsize,
                        workflowId,
                        limitId
                    }
                },
                markers: labelCoord === null ? null : [
                    labelCoord
                ]
            }
            props.onSave(sketchItem, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }


    const onAddPoint = function (latlng, workflowId) {

        setDoSnapToPoint(null);

        let newcoords = arrayUtilities.newOrConcat(coords, latlng);
        setCoords(newcoords);

        if (newcoords.length >= 2) {
            setIsDrawing(false);
            setIsEditing(true);
        }
        
        if (newcoords.length === 1 && !stringUtilities.isNullOrEmpty(workflowId)) {
            setWorkflowId(workflowId);
        }
    }

    const onSnappableMarkerDragEnd = function (latlng, index, workflowId) {
                
        let coordsCopy = cloneDeep(coords);
        coordsCopy.splice(index, 1, latlng);
        setCoords(coordsCopy);

        if (index === 0 && !stringUtilities.isNullOrEmpty(workflowId)) {
            setWorkflowId(workflowId);
        }
    }

    const onLabelMarkerDragEnd = function (e) {
        setLabelCoord(e.target._latlng);
    }

    useMapEvents({
        click: (e) => {
            if (isDrawing) {
                setDoSnapToPoint(e.latlng);
            }
        },
    })

    useEffect(() => {
        if (!arrayUtilities.isNullOrEmpty(coords) && coords.length == 2) {

            let point0 = map.latLngToContainerPoint(coords[0]);
            let point1 = map.latLngToContainerPoint(coords[1]);

            let dy = point1.y - point0.y;
            let dx = point1.x - point0.x;
            let theta = Math.atan2(dy, dx);

            theta *= 180 / Math.PI;
            theta += 270;

            setAngle(theta);
        }
    }, [coords])


    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

        map.closePopup();
    }, [isEditing])
    
    useEffect(() => {

        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {

        let shouldPrint = true;

        if (!arrayUtilities.isNullOrEmpty(coords) && ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId))) {

            if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                shouldPrint = false;
            }

            if (shouldPrint && !stringUtilities.isNullOrEmpty(workflowId)) {
                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                    shouldPrint = false;
                }
            }

            if (shouldPrint && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {
                let limit = props.sketch.limits[printingSketchLimitIndex];

                if (limit !== null && !arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) && !geospatialUtilities.polygonContainsPoint(geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords), coords[0])) {
                    shouldPrint = false;
                }
            }
        }

        setShouldPrint(shouldPrint);

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let iconSize = 30 * props.arrowScale;

    let icon = divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 30 30" fill="none"  style="transform-origin: top center; transform: rotate(${angle}deg)">
            <path d="M 15 -1 L 25 15 L 5 15 L 5 15 L 15 -1 z" fill="${color}"/>
        </svg>`,
        className: "marker-icon-point",
        iconAnchor: [iconSize * 0.5, 0],
        iconSize: [iconSize, iconSize]
    });

    let labelIcon = divIcon({
        html: `<div class="text-overlay ${!stringUtilities.isNullOrEmpty(fontsize) ? fontsize : ""} ${(width && width > 0) ? "has-width" : ""}" style="${(width && width > 0) ? `width: ${width}px;` : ""} transform: rotate(${rotate ?? 0}deg); ${!stringUtilities.isNullOrEmpty(color) ? `color: ${color};` : ""}">${(stringUtilities.isNullOrEmpty(label) ? `Add label` : label)}</div>`,
        className: "marker-icon-text-overlay",
        iconAnchor: [-5, -5]
    });

    let popup = <MapPopup title="Note with Leader" onEdit={() => setIsEditing(true)} deleteTitle="Delete Note with Leader" deleteContent="Are you sure you want to permanently delete this note with leader?" onDelete={onDelete} />;

    let pathOptions = { color, weight, opacity, fill: false };
    let bufferPathOptions = { color, weight: 20, opacity: 0.00, fill: false };

    return <>

        {!arrayUtilities.isNullOrEmpty(coords) && isVisible && shouldPrint && <>

            {(isDrawing || isEditing) && <>
                {coords.map((coord, index) => <SnappableMarker
                    index={index}
                    key={`note-with-leader-point-${index}`}
                    position={[coord.lat, coord.lng]}
                    icon={mapIcons.dragHandle}
                    onDragend={(e, index, workflowId) => onSnappableMarkerDragEnd(e.latlng, index, workflowId)}
                    snappingTolerance={snappingTolerance}
                    sketch={props.sketch}
                    points={props.points}
                    lines={props.lines}
                    workflowLines={props.workflowLines}
                    workflowPoints={props.workflowPoints}
                    workflowPointsLines={props.workflowPointsLines} 
                />
                )}
            </>}

            {coords.length > 1 && <>

                <Marker
                        icon={icon}
                        position={[coords[0].lat, coords[0].lng]}
                        bubblingMouseEvents={false} />
                <Polyline positions={geospatialUtilities.coordsToLatLngArray(coords)} pathOptions={pathOptions} />
                <Polyline positions={geospatialUtilities.coordsToLatLngArray(coords)} pathOptions={bufferPathOptions}>
                    {popup}
                </Polyline>
            </>}

            <Marker
                position={labelCoord !== null ? [labelCoord.lat, labelCoord.lng] : [coords[0].lat, coords[0].lng]}
                icon={labelIcon}
                pane="drawing-tool-labels"
                draggable={isDrawing || isEditing}
                eventHandlers={{
                    dragend: onLabelMarkerDragEnd
                }}>
                {!isDrawing && !isEditing && <>
                    {popup}
                </>}
            </Marker>
        </>}

        {doSnapToPoint !== null &&
            <SnapToPoint 
                latlng={doSnapToPoint} 
                icon={mapIcons.dragHandle} 
                snappingTolerance={snappingTolerance} 
                sketch={props.sketch} 
                points={props.points} 
                lines={props.lines} 
                onSnappedPointSelected={onAddPoint} 
                onCancel={() => setDoSnapToPoint(null)}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}  />
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Note with Leader`} onCancel={onCancel} onSave={onSave}>
            <Textbox label="Label" value={label} onChange={setLabel} />
            <Button size="xs" variant="secondary" className="mb-3" onClick={() => setLabelCoord(null)} disabled={labelCoord === null}>Reset label position</Button>

            <WeightSelect value={weight} onChange={setWeight} />
            <FontSizeSelect value={fontsize} onChange={setFontsize} />
            <ColorPicker value={color} onChange={setColor} />
            <RotationSlider value={rotate} onChange={setRotate} />
            <WidthSlider value={width} onChange={setWidth} />
            <OpacitySlider value={opacity} onChange={setOpacity} />
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
        </ConfigurationDialog>
    </>
}