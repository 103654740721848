import { Accordion, Button } from "react-bootstrap";
import postal from 'postal';

export default function DrawingToolsToolbox(props) {

    const addNote = function() {
        postal.publish({
            channel: "sketch",
            topic: "textMarkers.add"
        });

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: true }
        })
    }

    const addItem = function (id) {

        postal.publish({
            channel: "sketch",
            topic: "items.add",
            data: { id }
        });

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: true }
        })
    }

    return <>
        {props.sketch && props.sketch !== null &&
            <Accordion.Item eventKey='4'>
                <Accordion.Header><i className="fi-route me-2" /> Drawing Tools</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex flex-column align-items-start">
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(1)}>Dimension Line</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(15)}>Solid Line</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(19)}>Dashed Line</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(23)}>Box</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(13)}>Circle</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(14)}>Polygon</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(4)}>North Arrow</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(11)}>Note with Leader</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addNote()}>Note</Button>
                        <Button size="sm" variant="secondary" className="mb-2" onClick={(e) => addItem(16)}>Workflow</Button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        }
    </>;
}