import LineDrawingTool from "./drawingTools/lineDrawingTool";
import BoxDrawingTool from "./drawingTools/boxDrawingTool";
import CircleDrawingTool from "./drawingTools/circleDrawingTool";
import PolygonDrawingTool from "./drawingTools/polygonDrawingTool";
import WorkflowDrawingTool from "./drawingTools/workflowDrawingTool";
import NorthArrowDrawingTool from "./drawingTools/northArrowDrawingTool";
import DimensionLineDrawingTool from "./drawingTools/dimensionLineDrawingTool";
import NoteWithLeader from "./drawingTools/noteWithLeader";
import StampDrawingTool from "./drawingTools/stampDrawingTool";

export default function SketchObject(props) {

    return <>
        {props.sketchItem?.tool?.id === 1 &&
            <DimensionLineDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 4 &&
            <NorthArrowDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 13 &&
            <CircleDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 14 &&
            <PolygonDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {(props.sketchItem?.tool?.id === 15 || props.sketchItem?.tool?.id === 19) &&
            <LineDrawingTool
                index={props.index}
                toolId={props.sketchItem?.tool?.id}
                isDashed={props.sketchItem?.tool?.id === 19}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 16 &&
            <WorkflowDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 23 &&
            <BoxDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 11 &&
            <NoteWithLeader
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale} />
        }

        {props.sketchItem?.tool?.id === 24 &&
            <StampDrawingTool
                index={props.index}
                sketchItem={props.sketchItem}
                workflows={props.workflows}
                onCancel={props.onSketchItemCancel}
                onSave={props.onSketchItemSave}
                onDelete={props.onSketchItemDelete}
                snappingTolerance={props.snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}
                arrowScale={props.arrowScale}
                symbolScale={props.symbolScale}
                stamps={props.stamps} />
        }
    </>
}