import React, {useState, useEffect} from 'react';
import stringUtilities from '../../utilities/stringUtilities';

var md5 = require('md5');

export default function Gravatar(props) {

    const [hash, setHash] = useState(null);

    const style = {
        width: stringUtilities.isNullOrEmpty(props.width) ? "auto" : `${props.width}px`,
        height: stringUtilities.isNullOrEmpty(props.height) ? "auto" : `${props.height}px`
    }

    useEffect(() => {
        if (props.user && props.user.emailAddress) {
            setHash(md5(props.user.emailAddress.trim().toLowerCase()));
        }
    }, []);

    return <>
        <img src={"https://www.gravatar.com/avatar/" + hash + "?s=100&d=mp"} style={style} className='rounded-circle' alt={props.user.name} />
    </>;
}