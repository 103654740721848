import { useEffect, useState } from "react";
import stringUtilities from "../../../utilities/stringUtilities";
import { Accordion, Button, Form, Modal } from "react-bootstrap";

import DeleteButton from "../../common/deleteButton";
import useApi from '../../../utilities/useApi';
import postal from "postal";
import Loading from "../../common/loading";
import SketchPicker from "../../form/sketchPicker";

export default function SketchesToolbox(props) {

    const { api } = useApi();

    const [sketchId, setSketchId] = useState(props.sketchId);

    const [newSketchModalIsVisible, setNewSketchModalIsVisible] = useState(false);
    const [renameModalIsVisible, setRenameModalIsVisible] = useState(false);
    const [copyModalIsVisible, setCopyModalIsVisible] = useState(false);

    const [name, setName] = useState("");
    const [canSave, setCanSave] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const onSketchSelected = function (sketchId) {
        setSketchId(sketchId);
        if (props.onSketchSelected) {
            props.onSketchSelected(sketchId);
        }
    }

    const onSketchDeleted = function () {
        if (props.onSketchDeleted) {
            props.onSketchDeleted();
        }
    }

    const onSaveNewSketch = function () {

        setIsLoading(true);
        api.addSketch(props.organisationId, props.projectId, name, (data) => {

            if (props.onRefreshSketches) {
                props.onRefreshSketches(data.id);
                setNewSketchModalIsVisible(false);
            }
        }, () => { onError("Error saving sketch. Please ensure the sketch name is unique"); });
    }

    const onRenameSketch = function () {

        setIsLoading(true);

        let sketch = props.sketch;
        sketch.name = name;

        api.saveSketch(props.organisationId, props.projectId, sketchId, sketch, (data) => {

            if (props.onRefreshSketches) {
                props.onRefreshSketches(data.id);
                setRenameModalIsVisible(false);
            }
        }, () => { onError("Error renaming sketch. Please ensure the sketch name is unique"); });
    }

    const onCopySketch = function () {

        setIsLoading(true);

        let sketch = props.sketch;
        sketch.name = name;

        api.addSketch(props.organisationId, props.projectId, name, (data) => {
            api.saveSketch(props.organisationId, props.projectId, data.id, sketch, () => {
                if (props.onRefreshSketches) {
                    props.onRefreshSketches(data.id);
                    setRenameModalIsVisible(false);
                }
            }, () => { onError("Error saving sketch. Please ensure the sketch name is unique"); })
        }, () => { onError("Error saving sketch. Please ensure the sketch name is unique"); });
    }

    const onError = function(errorMessage) {
        postal.publish({
            channel: "error",
            topic: "thrown",
            data: {
                error: {
                    message: errorMessage
                }
            }
        });

        setIsLoading(false);
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name));
    }, [name])

    useEffect(() => {
        if (renameModalIsVisible || copyModalIsVisible) {
            setName(props.sketch.name);
        } else {
            setName("");
        }
    }, [newSketchModalIsVisible, renameModalIsVisible, copyModalIsVisible])

    return <>
        <Accordion.Item eventKey='2'>
            <Accordion.Header><i className="fi-pencil me-2" /> Sketches</Accordion.Header>
            <Accordion.Body>
                <div className='d-flex justify-content-start mt-2 mb-3'>
                    <Button size='sm' variant="primary" onClick={() => setNewSketchModalIsVisible(true)}><i className='fi-plus me-2'></i> New</Button>
                </div>

                <SketchPicker sketches={props.sketches} onChange={onSketchSelected} value={sketchId} />

                <div className='d-flex justify-content-between  flex-wrap '>
                    <div className="d-flex justify-content-start mb-2">
                        <Button size='sm' className='me-2' variant="secondary" disabled={!props.sketch || props.sketch === null} onClick={() => setRenameModalIsVisible(true)}><i className='fi-edit me-2'></i> Rename</Button>
                        <Button size='sm' variant="secondary" disabled={!props.sketch || props.sketch === null}  onClick={() => setCopyModalIsVisible(true)}><i className='fi-list me-2'></i> Save as...</Button>
                    </div>
                    <DeleteButton disabled={!props.sketch || props.sketch === null} modalTitle="Delete Sketch" modalContent={`Are you sure you want to permanently delete the Sketch '${props.sketch?.name}'?`} onConfirm={onSketchDeleted} />
                </div>
            </Accordion.Body>
        </Accordion.Item>

        <Modal show={newSketchModalIsVisible} onHide={() => setNewSketchModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>New Sketch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='mb-3'>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                        Name
                    </Form.Label>
                    <Form.Control size='sm' value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center">
                <div>{isLoading && <Loading />}</div>
                <div className="d-flex justify-content-end">
                    <Button size="sm" variant='secondary' onClick={() => setNewSketchModalIsVisible(false)} className="me-2">Cancel</Button>
                    <Button size="sm" variant="primary" disabled={!canSave} onClick={onSaveNewSketch}>Save</Button>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={renameModalIsVisible} onHide={() => setRenameModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>Rename Sketch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='mb-3'>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                        New name
                    </Form.Label>
                    <Form.Control size='sm' value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center">
                <div>{isLoading && <Loading />}</div>
                <div className="d-flex justify-content-end">
                    <Button size="sm" variant='secondary' onClick={() => setRenameModalIsVisible(false)} className="me-2">Cancel</Button>
                    <Button size="sm" variant="primary" disabled={!canSave} onClick={onRenameSketch}>Save</Button>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={copyModalIsVisible} onHide={() => setCopyModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>Save as...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='mb-3'>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                        Name
                    </Form.Label>
                    <Form.Control size='sm' value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center">
                <div>{isLoading && <Loading />}</div>
                <div className="d-flex justify-content-end">
                    <Button size="sm" variant='secondary' onClick={() => setCopyModalIsVisible(false)} className="me-2">Cancel</Button>
                    <Button size="sm" variant="primary" disabled={!canSave} onClick={onCopySketch}>Save</Button>
                </div>
            </Modal.Footer>
        </Modal>
    </>;
}