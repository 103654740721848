import { Button, Form, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { useEffect, useState } from "react";
import stringUtilities from "../utilities/stringUtilities";
import useApi from './../utilities/useApi';
import Loading from "./common/loading";
import arrayUtilities from "../utilities/arrayUtilities";

export default function MergeJobModal(props) {

    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(false);

    const [job, setJob] = useState(props.job);
    const [targetJobId, setTargetJobId] = useState("");

    const [canMerge, setCanMerge] = useState(false);

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {

        setIsLoading(true);

        api.mergeJob(props.organisationId, props.projectId, job.id, targetJobId, () => {
            if (props.onMerge) {
                props.onMerge();
            }
        }, () => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setCanMerge(!stringUtilities.isNullOrEmpty(targetJobId))
    }, [targetJobId])

    return <Modal show onHide={onCancel} centered>
        <Modal.Header closeButton>
            <Modal.Title>Merge job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)' }}>
                <p>To merge this Job into another Job, select the merge target below. This job will then be deleted.</p>
                <Form.Group controlId='input-small' className='mb-3'>
                    <Form.Select size='sm' value={targetJobId} onChange={(e) => setTargetJobId(e.target.value)}>
                        <option value=""></option>
                        {!arrayUtilities.isNullOrEmpty(props.jobs) && <>
                            {props.jobs.filter(x => x.id !== job.id).map((job, index) => <option value={job.id}>{job.name}</option>)}
                        </>}
                    </Form.Select>
                </Form.Group>

            </SimpleBar>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <div>
                {isLoading && <Loading />}
            </div>

            <div>
                <Button size="sm" variant='secondary' onClick={onCancel} className="me-2">Cancel</Button>
                <Button size="sm" variant='primary' onClick={onSave} disabled={!canMerge}>Merge</Button>
            </div>
        </Modal.Footer>
    </Modal>
}