import { useEffect, useState } from "react";
import { Circle, Marker, Rectangle, useMap, useMapEvents } from "react-leaflet";
import mapIcons from "./mapIcons";
import geospatialUtilities from "../../utilities/geospatialUtilities";
var hash = require('object-hash');

export default function EditableCircle(props) {

    const map = useMap();

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);

    const [centerPoint, setCenterPoint] = useState(props.centerPoint);
    const [radius, setRadius] = useState(props.radius ?? 1);

    const [centerPointTemp, setCenterPointTemp] = useState(null);
    const [draggingCircleStart, setDraggingCircleStart] = useState(null);
    const [isDraggingCircle, setIsDraggingCircle] = useState(false);

    const [isDraggingMarker, setIsDraggingMarker] = useState(false);

    const [north, setNorth] = useState(null);
    const [east, setEast] = useState(null);
    const [south, setSouth] = useState(null);
    const [west, setWest] = useState(null);

    const onCircleDragStart = function (e) {

        map.dragging.disable();

        setCenterPointTemp(centerPoint);
        setIsDraggingCircle(true);
        setDraggingCircleStart(e.latlng);
    }

    const onMarkerDragStart = function (e) {

        map.dragging.disable();
        setIsDraggingMarker(true);
    }

    useMapEvents({

        mousedown: (e) => {

            if (isDrawing) {
                map.dragging.disable();
                setCenterPoint(e.latlng);
            }
        },

        mousemove: (e) => {
            if ((isDrawing || isDraggingMarker) && centerPoint !== null) {
                setRadius(geospatialUtilities.haversineDistance(centerPoint, e.latlng));
            }

            if (isDraggingCircle && draggingCircleStart && draggingCircleStart !== null && centerPointTemp && centerPointTemp !== null) {

                let latDiff = e.latlng.lat - draggingCircleStart.lat;
                let lngDiff = e.latlng.lng - draggingCircleStart.lng;

                setCenterPoint({ lat: centerPointTemp.lat + latDiff, lng: centerPointTemp.lng + lngDiff });
            }
        },
        mouseup: (e) => {

            setIsDrawing(false);
            setIsDraggingMarker(false);

            map.dragging.enable();
            setCenterPointTemp(null);
            setIsDraggingCircle(false);
            setDraggingCircleStart(null);
        }
    })

    useEffect(() => {

        if (centerPoint !== null && radius !== null) {
            setNorth(geospatialUtilities.shiftPoint(centerPoint, radius, 0));
            setEast(geospatialUtilities.shiftPoint(centerPoint, 0, radius));
            setSouth(geospatialUtilities.shiftPoint(centerPoint, -radius, 0));
            setWest(geospatialUtilities.shiftPoint(centerPoint, 0, -radius));

            if (props.onChange) {
                props.onChange(centerPoint, radius);
            }
        } else {
            setNorth(null);
            setEast(null);
            setSouth(null);
            setWest(null);
        }

        

    }, [centerPoint, radius])


    return <>
        {centerPoint !== null && radius !== null &&
            <>
                <Circle key={hash({ centerPoint, hash })} center={centerPoint} radius={radius} pathOptions={props.pathOptions} eventHandlers={{
                    mousedown: onCircleDragStart
                }} />

                {north !== null &&
                    <Marker position={north} icon={mapIcons.dragHandleNs} eventHandlers={{
                        mousedown: onMarkerDragStart
                    }} />
                }

                {east !== null &&
                    <Marker position={east} icon={mapIcons.dragHandleEw} eventHandlers={{
                        mousedown: onMarkerDragStart
                    }} />
                }

                {south !== null &&
                    <Marker position={south} icon={mapIcons.dragHandleNs} eventHandlers={{
                        mousedown: onMarkerDragStart
                    }} />
                }

                {west !== null &&
                    <Marker position={west} icon={mapIcons.dragHandleEw} eventHandlers={{
                        mousedown: onMarkerDragStart
                    }} />
                }
            </>
        }
    </>;
}