import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";
import SimpleBar from "simplebar-react";
import stringUtilities from "../../utilities/stringUtilities";
import FilterBar from "./filterBar";


export default function OrganisationSelector(props) {

    const [filter, setFilter] = useState("");
    const [filteredOrganisations, setFilteredOrganisations] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const organisation = arrayUtilities.isNullOrEmpty(props.organisations) ? null : props.organisations.find(x => x.id === props.value);

    const onChange = function (id) {
        if (props.onChange) {
            props.onChange(id);
        }

        setModalIsVisible(false);
    }

    useEffect(() => {
        setFilter("");
    }, [modalIsVisible])

    useEffect(() => {
        setFilteredOrganisations(arrayUtilities.isNullOrEmpty(props.organisations) ? [] : props.organisations.filter((o) => {
            return stringUtilities.isNullOrEmpty(filter) ||
                o.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || 
                o.id.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                props.organisations.filter(x => x.leftEdge > o.leftEdge && x.rightEdge < o.rightEdge && x.depth > o.depth && x.name.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1).length > 0 ||
                props.organisations.filter(x => x.leftEdge > o.leftEdge && x.rightEdge < o.rightEdge && x.depth > o.depth && x.id.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1).length > 0;
        }));
    }, [filter, props.organisations]);

    return <>
        <Form.Group className='d-flex align-items-center flex-shrink-0 me-3'>
            <Form.Label className='text-body fs-sm me-2 mb-0 pe-1 text-nowrap'>
                <i className='fi-building text-muted mt-n1'></i>
            </Form.Label>
            <InputGroup size="sm">
                <div className="form-control form-control-static form-control-sm">{organisation?.name}</div>

                <InputGroup.Text className='p-0'>
                    <Button variant="" className='btn-link d-block text-body px-3 py-2' onClick={() => setModalIsVisible(true)}>
                        <i className='fi-switch-horizontal mt-n1'></i>
                    </Button>
                </InputGroup.Text>
            </InputGroup>
        </Form.Group>

        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Select organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FilterBar value={filter} onChange={setFilter} className="mb-4" />

                <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)', minHeight: 'calc(100vh - 380px)' }}>
                    {!arrayUtilities.isNullOrEmpty(filteredOrganisations) &&
                        <ul className="organisation-switcher">
                            {filteredOrganisations.map((org, index) => <li index={index} key={`organisation-selector-${org.id}`} className={`d-flex align-items-center organisation-level-${org.depth - filteredOrganisations[0].depth}`}>

                                {index > 0 && org.depth > filteredOrganisations[index - 1].depth &&
                                    <i className='fi-corner-down-right mt-n1'></i>
                                }
                                <Button className="btn-link" size="sm" variant="" onClick={() => onChange(org.id)} active={props.value === org.id}>{org.name}</Button>

                            </li>
                            )}
                        </ul>
                    }
                </SimpleBar>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setModalIsVisible(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>
}