import { useState, useEffect } from "react";
import arrayUtilities from "../../utilities/arrayUtilities";
import Limit from "./limit";
import postal from 'postal';
import cloneDeep from 'lodash.clonedeep';
import LineDrawingTool from "./drawingTools/lineDrawingTool";
import BoxDrawingTool from "./drawingTools/boxDrawingTool";
import CircleDrawingTool from "./drawingTools/circleDrawingTool";
import PolygonDrawingTool from "./drawingTools/polygonDrawingTool";
import WorkflowDrawingTool from "./drawingTools/workflowDrawingTool";
import NorthArrowDrawingTool from "./drawingTools/northArrowDrawingTool";
import DimensionLineDrawingTool from "./drawingTools/dimensionLineDrawingTool";
import SketchObject from "./sketchObject";
import TextMarker from "./drawingTools/textMarker";
import NoteWithLeader from "./drawingTools/noteWithLeader";
import StampDrawingTool from "./drawingTools/stampDrawingTool";

var hash = require('object-hash');

export default function Sketch(props) {

    const [enableSaving, setEnableSaving] = useState(false);
    const [sketch, setSketch] = useState(props.sketch);
    const [textMarkers, setTextMarkers] = useState(props.sketch.textMarkers ?? []);
    const [items, setItems] = useState(props.sketch.items ?? []);

    const [isAddingLimit, setisAddingLimit] = useState(false);
    const [editingLimit, setEditingLimit] = useState(null);
    const [limits, setLimits] = useState(props.sketch.limits);

    const [addingSketchItem, setAddingSketchItem] = useState(null);
    const [addingStampId, setAddingStampId] = useState(null);

    const [isAddingTextMarker, setIsAddingTextMarker] = useState(false);

    const onLimitCancel = function () {
        setisAddingLimit(false);
        setEditingLimit(null);

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: false }
        })
    }

    const onLimitSave = function (limit) {
        if (isAddingLimit) {
            if (arrayUtilities.isNullOrEmpty(limits)) {
                setLimits([limit]);
            } else {
                setLimits(limits.concat([limit]));
            }
        }

        if (editingLimit !== null) {
            let copy = cloneDeep(limits);
            copy.splice(editingLimit, 1, limit);
            setLimits(copy);
        }

        setisAddingLimit(false);
        setEditingLimit(null);

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: false }
        })
    }

    const editLimit = function (index) {
        setEditingLimit(index);
    }

    const deleteLimit = function (index) {
        if (!arrayUtilities.isNullOrEmpty(limits) && limits.length > index) {
            let copy = cloneDeep(limits);
            copy.splice(index, 1);
            setLimits(copy);
        }

        onLimitCancel();
    }

    const onTextMarkerCancel = function () {

        setIsAddingTextMarker(null);

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: false }
        })
    }

    const deleteTextMarker = function (index) {
        if (!arrayUtilities.isNullOrEmpty(textMarkers) && textMarkers.length > index) {
            let copy = cloneDeep(textMarkers);
            copy.splice(index, 1);
            setTextMarkers(copy);
        }

        onTextMarkerCancel();
    }

    const onTextMarkerSave = function (textMarker, index) {
        if (isAddingTextMarker) {
            setTextMarkers(arrayUtilities.newOrConcat(textMarkers, textMarker));
        } else {
            let copy = cloneDeep(textMarkers);
            copy.splice(index, 1, textMarker);
            setTextMarkers(copy);
            
            setIsAddingTextMarker(false);
        }
    }

    const onSketchItemSave = function (sketchItem, index) {
        if (addingSketchItem === null) {
            let copy = cloneDeep(items);
            copy.splice(index, 1, sketchItem);
            setItems(copy);
            
            onSketchItemCancel();

        } else {
            setItems(items.concat([sketchItem]));
        }
    }

    const onSketchItemDelete = function (index) {
        let copy = cloneDeep(items);
        copy.splice(index, 1);
        setItems(copy);

        onSketchItemCancel();
    }

    const onSketchItemCancel = function () {

        setAddingSketchItem(null)

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: false }
        })
    }

    const onSave = function(refresh) {
        if (enableSaving && props.onSave) {
            let sketch = {
                name: props.sketch.name,
                textMarkers: textMarkers,
                overriddenLines: [],
                configuration: "",
                limits: limits,
                items: items
            };

            props.onSave(sketch, refresh);

            setSketch(sketch);
        }
    }

    useEffect(() => {
        onSave(false);
    }, [textMarkers, items])

    useEffect(() => {
        onSave(true);
    }, [limits])

    useEffect(() => {
        setSketch(props.sketch);
    }, [props.sketch])

    useEffect(() => {

        const subscriptions = [
            postal.subscribe({
                channel: "sketch",
                topic: "limit.add",
                callback: function () {
                    setisAddingLimit(true);
                    setAddingSketchItem(null);
                    setIsAddingTextMarker(false);
                }
            }),
            postal.subscribe({
                channel: "sketch",
                topic: "limit.delete",
                callback: function (data) {
                    deleteLimit(data.index);
                }
            }),
            postal.subscribe({
                channel: "sketch",
                topic: "limit.edit",
                callback: function (data) {
                    editLimit(data.index);
                }
            }),
            postal.subscribe({
                channel: "sketch",
                topic: "items.add",
                callback: function (data) {
                    setAddingSketchItem(data.id);
                    setAddingStampId(data.stampId);
                    setisAddingLimit(false);
                    setIsAddingTextMarker(false);
                }
            }),
            postal.subscribe({
                channel: "sketch",
                topic: "textMarkers.add",
                callback: function (data) {
                    setIsAddingTextMarker(true);
                    setAddingSketchItem(null);
                    setisAddingLimit(false);
                }
            }),
        ]

        let timeout = setTimeout(() => {
            setEnableSaving(true);
        }, 5000);

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });

            clearTimeout(timeout);
        }

    }, [])

    return <>
        {!arrayUtilities.isNullOrEmpty(textMarkers) && <>
            {textMarkers.map((textMarker, index) => <TextMarker textMarker={textMarker} index={index} key={hash(textMarker)} workflows={props.workflows} sketch={sketch} snappingTolerance={props.snappingTolerance} points={props.points} lines={props.lines} onSave={onTextMarkerSave} onCancel={onTextMarkerCancel} onDelete={deleteTextMarker} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />)}
        </>}
        {isAddingTextMarker && <TextMarker isDrawing isEditing workflows={props.workflows} sketch={sketch} snappingTolerance={props.snappingTolerance} points={props.points} lines={props.lines} onSave={onTextMarkerSave} onCancel={onTextMarkerCancel} onDelete={deleteTextMarker} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}

        {!arrayUtilities.isNullOrEmpty(limits) && <>
            {limits.map((limit, index) => {
                let isEditing = editingLimit !== null && editingLimit === index;
                return <Limit key={hash({ limit, isEditing })} limit={limit} isEditing={isEditing} onSave={onLimitSave} index={index} onCancel={onLimitCancel} project={props.project} />
            })}

        </>}
        {isAddingLimit && <Limit isDrawing isEditing onCancel={onLimitCancel} onSave={onLimitSave} index={limits?.length ?? 0} project={props.project} />}

        {!arrayUtilities.isNullOrEmpty(items) && <>
            {items.map((item, index) =>
                <SketchObject
                    sketchItem={item}
                    index={index}
                    key={hash(item)}
                    workflows={props.workflows}
                    onSketchItemCancel={onSketchItemCancel}
                    onSketchItemSave={onSketchItemSave}
                    onSketchItemDelete={onSketchItemDelete}
                    snappingTolerance={props.snappingTolerance}
                    sketch={sketch}
                    points={props.points}
                    lines={props.lines}
                    workflowLines={props.workflowLines}
                    workflowPoints={props.workflowPoints}
                    workflowPointsLines={props.workflowPointsLines}
                    arrowScale={props.arrowScale}
                    symbolScale={props.symbolScale}
                    stamps={props.stamps} />
            )}
        </>}

        {addingSketchItem === 1 && <DimensionLineDrawingTool isDrawing isEditing sketchItem={null} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} snappingTolerance={props.snappingTolerance} sketch={sketch} points={props.points} lines={props.lines} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 4 && <NorthArrowDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 13 && <CircleDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 14 && <PolygonDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {(addingSketchItem === 15 || addingSketchItem == 19) && <LineDrawingTool toolId={addingSketchItem} isDashed={addingSketchItem === 19} isDrawing sketchItem={null} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} snappingTolerance={props.snappingTolerance} sketch={sketch} points={props.points} lines={props.lines} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 16 && <WorkflowDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} points={props.points} lines={props.lines} snappingTolerance={props.snappingTolerance} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 23 && <BoxDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 11 && <NoteWithLeader isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} snappingTolerance={props.snappingTolerance} points={props.points} lines={props.lines} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
        {addingSketchItem === 24 && <StampDrawingTool isDrawing isEditing sketchItem={null} sketch={sketch} workflows={props.workflows} onCancel={onSketchItemCancel} onSave={onSketchItemSave} arrowScale={props.arrowScale} symbolScale={props.symbolScale} stamps={props.stamps} stampId={addingStampId} activeLimit={props.activeLimit} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />}
    </>
}