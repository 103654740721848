import React, { useEffect, useState } from "react";
import cloneDeep from 'lodash.clonedeep';
import { Marker, Polygon, Popup, useMap, useMapEvents } from "react-leaflet";
import geospatialUtilities from "../../utilities/geospatialUtilities";
import mapIcons from "./mapIcons";
import { Button } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";

export default function EditablePolygon(props) {

    const [draggingIndex, setDraggingIndex] = useState(null);
    const [coords, setCoords] = useState(props.coords);
    const [vertices, setVertices] = useState([]);
    const [polygonCoords, setPolygonCoords] = useState(props.coords);
    const [isDrawing, setIsDrawing] = useState(props.isDrawing)

    const [coordsTemp, setCoordsTemp] = useState(null);
    const [draggingPolygonStart, setDraggingPolygonStart] = useState(null);
    const [isDraggingPolygon, setIsDraggingPolygon] = useState(false);

    const map = useMap();

    const onPolygonDragStart = function (e) {

        map.dragging.disable();

        setCoordsTemp(coords);
        setIsDraggingPolygon(true);
        setDraggingPolygonStart(e.latlng);
    }

    const deleteVertex = function (index) {

        map.closePopup();

        let copy = cloneDeep(coords);
        copy.splice(index / 2, 1);
        setCoords(copy);
        setPolygonCoords(copy);
    }

    const onMarkerDragStart = function (e, index) {
        setDraggingIndex(index);
    }

    const onMarkerDrag = function (e, index) {

        let copy = cloneDeep(vertices);
        copy.splice(index, 1, e.latlng);
        setVertices(copy);

        let newPolygonCoords = [];
        copy.forEach((coord, i) => {
            if (i % 2 === 0 || i === index) {
                newPolygonCoords.push(coord);
            }
        });

        setPolygonCoords(newPolygonCoords);
    }

    const onMarkerDragEnd = function (e, index) {

        let newcoords = []
        vertices.forEach((coord, i) => {

            if (i % 2 === 0 || i === index) {
                newcoords.push(coord);
            }
        });

        setCoords(newcoords);
        setDraggingIndex(null);
    }

    useMapEvents({

        click: (e) => {

            if (isDrawing) {
                let newcoords = arrayUtilities.newOrConcat(coords, e.latlng);
                setCoords(newcoords);
                setPolygonCoords(newcoords);
            }
        },

        mousemove: (e) => {

            if (isDraggingPolygon && draggingPolygonStart && draggingPolygonStart !== null && !arrayUtilities.isNullOrEmpty(coordsTemp)) {

                let latDiff = e.latlng.lat - draggingPolygonStart.lat;
                let lngDiff = e.latlng.lng - draggingPolygonStart.lng;

                let newCoords = [];
                coordsTemp.forEach((coord, index) => {
                    newCoords.push({ lat: coord.lat + latDiff, lng: coord.lng + lngDiff });
                })

                setCoords(newCoords);
                setPolygonCoords(newCoords);
            }
        },
        mouseup: (e) => {

            map.dragging.enable();
            setCoordsTemp(null);
            setIsDraggingPolygon(false);
            setDraggingPolygonStart(null);
        }
    })

    useEffect(() => {

        let coordsWithMidpoints = [];

        if (!arrayUtilities.isNullOrEmpty(coords)) {
            coords.forEach((coord, index) => {
                coordsWithMidpoints.push(coord);

                let p1 = coords[index];
                let p2 = coords[index + 1 < coords.length ? index + 1 : 0];
                let midPoint = geospatialUtilities.midPoint(p1, p2);

                coordsWithMidpoints.push(midPoint);
            })
        }

        setVertices(coordsWithMidpoints);

        if (props.onChange) {
            props.onChange(coords);
        }
    }, [coords])

    return <>
        {!arrayUtilities.isNullOrEmpty(polygonCoords) && <>
            <Polygon positions={[polygonCoords.map(x => [x.lat, x.lng])]} pathOptions={props.pathOptions} eventHandlers={{
                mousedown: onPolygonDragStart
            }} />
        </>}

        {vertices.map((coord, index) => {

            if ((index % 2 === 0 || index === draggingIndex || draggingIndex === null)) {
                return <Marker
                    draggable
                    icon={mapIcons.dragHandle}
                    opacity={index % 2 === 0 || draggingIndex === index ? 1 : 0.35}
                    position={[coord.lat, coord.lng]}
                    eventHandlers={{
                        dragstart: (e) => onMarkerDragStart(e, index),
                        drag: (e) => onMarkerDrag(e, index),
                        dragend: (e) => onMarkerDragEnd(e, index)
                    }}>
                    {index % 2 == 0 && <Popup>
                        <div className='card border-0'>
                            <div className='card-body position-relative d-flex justify-content-center'>
                                <Button size="sm" variant='danger' onClick={() => deleteVertex(index)}>Delete Point</Button>
                            </div>
                        </div>
                    </Popup>}
                </Marker>
            }
            return null;
        })}
    </>
}