import { Marker } from "react-leaflet"
import mapIcons from "./mapIcons"
import { useEffect, useState } from "react";
import SnapToPoint from "./snapToPoint";

var hash = require('object-hash');

export default function SnappableMarker(props) {

    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const onDragStart = function (e, index) {
        if (props.onDragStart) {
            props.onDragStart(e, index);
        }
    }

    const onDrag = function (e, index) {
        if (props.onDrag) {
            props.onDrag(e, index);
        }
    }

    const onDragend = function (e, index) {
        setDoSnapToPoint(e.target._latlng);
    }

    const onSnappingCancelled = function (e, index) {

        if (props.onDragend) {
            props.onDragend({ latlng: doSnapToPoint }, props.index, null);
        }
        setDoSnapToPoint(null);
    }

    const onSnappedPointSelected = function (latlng, workflowId) {
        
        if (props.onDragend) {
            props.onDragend({ latlng }, props.index, workflowId);
        }
    }

    return <>
        <Marker
            draggable
            icon={props.icon ?? mapIcons.dragHandle}
            opacity={props.opacity}
            position={props.position}
            bubblingMouseEvents={false}
            pane="drawing-tool-labels"
            eventHandlers={{
                dragstart: (e) => onDragStart(e, props.index),
                drag: (e) => onDrag(e, props.index),
                dragend: (e) => onDragend(e, props.index)
            }}>
            {props.children}
        </Marker>

        {doSnapToPoint !== null && 
            <SnapToPoint 
                latlng={doSnapToPoint} 
                key={hash( { doSnapToPoint} )} 
                snappingTolerance={props.snappingTolerance} 
                sketch={props.sketch} 
                points={props.points} 
                lines={props.lines} 
                workflows={props.workflows} 
                disableSnapToLimit={props.disableSnapToLimit} 
                onSnappedPointSelected={onSnappedPointSelected} 
                onCancel={onSnappingCancelled}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines}  />
        }
    </>
}