import { Button, Offcanvas } from "react-bootstrap";
import SimpleBar from "simplebar-react";

export default function ConfigurationDialog(props) {

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {
        if (props.onSave) {
            props.onSave();
        }
    }

    return <Offcanvas show={props.show} placement='end' scroll={false} backdrop={false}>
        <Offcanvas.Header className='border-bottom'>
            <Offcanvas.Title>{props.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className="d-flex flex-align-start flex-column h-100 w-100 ">
                <div className="w-100 h-100 mb-auto overflow-y-auto">
                    <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 280px)' }}>
                        {props.children}
                    </SimpleBar>
                </div>

                <div className="d-flex justify-content-between order-2 w-100">
                    <Button size="sm" variant='secondary' onClick={onCancel}>Close</Button>
                    <Button size="sm" variant="primary" onClick={onSave}>Save</Button>
                </div>
            </div>
        </Offcanvas.Body>
    </Offcanvas>
}