import { Accordion, Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import arrayUtilities from "../../../utilities/arrayUtilities";
import postal from "postal";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";


export default function StampsToolbox(props) {

    const [tagIds, setTagIds] = useState([]);
    const [filteredStamps, setFilteredStamps] = useState(props.stamps);

    const toggleTag = function (tagId) {
        setTagIds(arrayUtilities.toggle(tagId, tagIds, !tagIds.includes(tagId)));
    };

    const addItem = function (stampId) {

        postal.publish({
            channel: "sketch",
            topic: "items.add",
            data: {
                id: 24,
                stampId
            }
        });

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: true }
        })
    }

    useEffect(() => {

        if (!arrayUtilities.isNullOrEmpty(props.stamps)) {

            if (arrayUtilities.isNullOrEmpty(tagIds)) {
                setFilteredStamps(props.stamps);
            } else {
                setFilteredStamps(props.stamps.filter(x => !arrayUtilities.isNullOrEmpty(x.tags) && x.tags.filter(t => tagIds.includes(t.id)).length > 0));
            }
        }
    }, [tagIds])

    return <>
        {props.sketch && props.sketch !== null &&
            <Accordion.Item eventKey='5'>
                <Accordion.Header><i className="fi-image me-2" /> Stamps</Accordion.Header>
                <Accordion.Body>
                    <div className='border-bottom pt-3 pb-3 mb-3'>
                        <h3>Filter by tag:</h3>
                        <SimpleBar autoHide={false} className='simplebar-no-autohide' style={{ maxHeight: '11rem' }}>
                            {!arrayUtilities.isNullOrEmpty(props.tags) && <>
                                {props.tags.map((tag, index) => <Form.Check label={tag.name} key={`stamp-tag-filter-${index}`} id={`stamp-tag-filter-${index}`} value={tag.id} checked={tagIds.includes(tag.id)} onChange={(e) => toggleTag(e.target.value)} />)}
                            </>}
                        </SimpleBar>
                    </div>
                    {!arrayUtilities.isNullOrEmpty(filteredStamps) && <>
                        <Row className="mt-3">
                            {filteredStamps.map((stamp, index) =>
                                <Col md={3} index={index} key={`stamp-${stamp.id}`} className="stamps-list-item mb-3">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{stamp.name}</Tooltip>}>
                                        <Button variant="btn-link btn-icon" className="w-100 mb-2" onClick={() => addItem(stamp.id)}>
                                            <img src={stamp.url} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            )}
                        </Row>
                    </>
                    }
                </Accordion.Body>
            </Accordion.Item>
        }
    </>;
}