import arrayUtilities from "./arrayUtilities";
import colorUtilities from "./colorUtilities";
import stringUtilities from "./stringUtilities";

export default {

    getType(workflowId, typeName, workflows, hasHueOrColor) {

        if (stringUtilities.isNullOrEmpty(typeName) || arrayUtilities.isNullOrEmpty(workflows)) {
            return "";
        }

        let type = null;

        let workflow = workflows.find(x => x.id === workflowId);

        if (workflow && !stringUtilities.isNullOrEmpty(workflow.configuration)) {

            try {
                let found = false;

                var configuration = JSON.parse(workflow.configuration);
                for (let t = 0; t < configuration.types.length; t++) {
                    if (configuration.types[t].name === typeName && (!hasHueOrColor || (!stringUtilities.isNullOrEmpty(configuration.types[t].hue) || !stringUtilities.isNullOrEmpty(configuration.types[t].color)))) {
                        type = JSON.parse(JSON.stringify(configuration.types[t]));
                        type.name = workflow.Text + " - " + type.name;
                        found = true;
                    }
                }

                if (!found && configuration.types2 && configuration.types2 !== null && configuration.types2.length > 0) {
                    for (let t = 0; t < configuration.types2.length; t++) {
                        if (configuration.types2[t].name === typeName && (!hasHueOrColor || (!stringUtilities.isNullOrEmpty(configuration.types2[t].hue) || !stringUtilities.isNullOrEmpty(configuration.types2[t].color)))) {
                            type = configuration.types2[t];
                            type = JSON.parse(JSON.stringify(configuration.types[t]));
                            type.name = workflow.Text + " - " + type.name;
                        }
                    }
                }
            }
            catch (e) {

            }
        }

        return type;
    },

    getIsDashedFromPoint(point, workflows) {
        let typeName = point.formData?.Type;
        
        let type = this.getType(point.workflowId, typeName, workflows, false);

        return type !== null && type.isDashed;
    },

    getColorFromPoint(point, workflows) {

        return this.getColorFromWorkflowAndType(point.workflowId, point.formData?.Type, workflows);
    },

    getColorFromWorkflowAndType(workflowId, typeName, workflows) {

        if (!stringUtilities.isNullOrEmpty(workflowId) && !stringUtilities.isNullOrEmpty(typeName) && !arrayUtilities.isNullOrEmpty(workflows)) {
            let type = this.getType(workflowId, typeName, workflows, true);

            if (type !== null && !stringUtilities.isNullOrEmpty(type.color)) {
                return stringUtilities.ensureStringHasPrefix(type.color, "#");
            } else if (type !== null && type.hue && type.hue !== undefined) {
                return colorUtilities.hueToHex(type.hue);
            } else {
                if (!stringUtilities.isNullOrEmpty(workflowId)) {
                    let workflow = workflows.find(x => x.id === workflowId);
                    if (workflow && workflow !== null) {
                        if (!stringUtilities.isNullOrEmpty(workflow.color)) {
                            return stringUtilities.ensureStringHasPrefix(workflow.color, "#");
                        } else if (workflow.hue && workflow.hue !== undefined) {
                            return colorUtilities.hueToHex(workflow.hue);
                        }
                    }
                }
            }
        }

        return "#f00"; //TODO: define the default colour
    },

    getIconUrlFromPoint(point, workflows) {
        return this.getIconUrlFromWorkflowAndType(point.workflowId, point.formData?.Type, workflows);
    },

    getIconUrlFromWorkflowAndType(workflowId, typeName, workflows) {

        try {
            if (!stringUtilities.isNullOrEmpty(workflowId) && !arrayUtilities.isNullOrEmpty(workflows)) {

                let iconUrl = "";

                let workflow = workflows.find(x => x.id === workflowId);

                if (workflow && !stringUtilities.isNullOrEmpty(workflow.configuration)) {
                    var configuration = JSON.parse(workflow.configuration);

                    if (workflowId.toLowerCase() === workflow.id.toLowerCase()) {
                        for (let t = 0; t < configuration.types.length; t++) {

                            if (configuration.types[t].name === typeName && !stringUtilities.isNullOrEmpty(configuration.types[t].iconUrl)) {
                                iconUrl = configuration.types[t].iconUrl;
                            }
                        }

                        if (!stringUtilities.isNullOrEmpty(iconUrl) && configuration.types2 && configuration.types2 !== null && configuration.types2.length > 0) {
                            for (let t = 0; t < configuration.types2.length; t++) {
                                if (configuration.types2[t].name === typeName && !stringUtilities.isNullOrEmpty(configuration.types2[t].iconUrl)) {
                                    iconUrl = configuration.types2[t].iconUrl;
                                }
                            }
                        }
                    }
                }

                if (!stringUtilities.isNullOrEmpty(iconUrl)) {
                    return `/Content/Json/Icons/${iconUrl}`;
                }
            }
        }
        catch (e) {

        }

        return "";
    }
}