import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import { divIcon } from "leaflet";
import cloneDeep from 'lodash.clonedeep';
import geospatialUtilities from "../../../utilities/geospatialUtilities";
import stringUtilities from "../../../utilities/stringUtilities";
import postal from "postal";
import SnappableMarker from "../snappableMarker";
import SnapToPoint from "../snapToPoint";
import ConfigurationDialog from "./_configurationDialog";
import WeightSelect from "../../form/weightSelect";
import ColorPicker from "../../form/colorPicker";
import OpacitySlider from "../../form/opacitySlider";
import Textbox from "../../form/textbox";

import snapUtilities from "../../../utilities/snapUtilities";
import MapPopup from "./_mapPopup";
import { Button } from "react-bootstrap";
import pointUtilities from "../../../utilities/pointUtilities";
import LimitSelect from "../../form/limitSelect";
import FontSizeSelect from "../../form/fontSizeSelect";
import RotationSlider from "../../form/rotationSlider";

export default function DimensionLineDrawingTool(props) {


    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const [isVisible, setIsVisible] = useState(false);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [shouldPrint, setShouldPrint] = useState(true);

    const [coords, setCoords] = useState(null);
    const [workflowIds, setWorkflowIds] = useState(null);
    const [angle, setAngle] = useState(0);
    const [distance, setDistance] = useState(0);

    const [weight, setWeight] = useState(null);
    const [color, setColor] = useState(null);
    const [opacity, setOpacity] = useState(null);
    const [label, setLabel] = useState(null);
    const [labelCoord, setLabelCoord] = useState(null);
    const [limitId, setLimitId] = useState(null);
    const [rotate, setRotate] = useState(null);
    const [fontsize, setFontsize] = useState(null);

    const map = useMap();

    const snappingTolerance = Math.max(props.snappingTolerance, 0.5);

    const reset = function () {

        if (arrayUtilities.isNullOrEmpty(props.sketchItem?.tool?.workflowIds)) {
            resetSnappedWorkflowIds();
        } else {
            setCoords(props.sketchItem?.coords);
            setWorkflowIds(props.sketchItem?.tool?.workflowIds);
        }
        
        
        setRotate(props.sketchItem?.tool?.properties?.rotate ?? 0);
        setFontsize(props.sketchItem?.tool?.properties?.fontsize ?? "font-smaller");
        setWeight(props.sketchItem?.tool?.properties?.weight ?? 1);
        setColor(props.sketchItem?.tool?.properties?.color ?? "#000000");
        setOpacity(props.sketchItem?.tool?.properties?.opacity ?? 1);
        setLabel(props.sketchItem?.tool?.properties?.label ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);

        let markers = props.sketchItem?.markers ?? []
        setLabelCoord(arrayUtilities.isNullOrEmpty(markers) || !markers[0].lat || markers[0].lat === null || !markers[0].lng || markers[0].lng === null ? null : markers[0]);
    }

    const resetSnappedWorkflowIds = function() {
        let coords = props.sketchItem?.coords ?? [];
        let snappedCoords = [];
        let workflowIds = [];

        if (!arrayUtilities.isNullOrEmpty(coords)) {
            coords.forEach((coord) => {
                let result = snapUtilities.snapToNearest(coord, 0.1, props.points, props.lines, props.sketch, props.workflows, true, props.workflowLines, props.workflowPoints, props.workflowPointsLines);

                snappedCoords.push(result.latlng);
                workflowIds.push(result.workflowId);
            })
        }

        setWorkflowIds(workflowIds);
        setCoords(snappedCoords);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords,
                tool: {
                    id: 1,
                    properties: {
                        weight,
                        rotate,
                        fontsize,
                        color,
                        opacity,
                        label,
                        limitId
                    },
                    workflowIds
                },
                markers: labelCoord === null ? null : [
                    labelCoord
                ]
            }
            props.onSave(sketchItem, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    const onAddPoint = function (latlng, workflowId) {

        setDoSnapToPoint(null);

        let newcoords = arrayUtilities.newOrConcat(coords, latlng);
        setCoords(newcoords);

        setWorkflowIds(arrayUtilities.newOrConcat(workflowIds, workflowId ));

        if (newcoords.length >= 2) {
            setIsDrawing(false);
            setIsEditing(true);
        }
    }

    const onSnappableMarkerDragEnd = function (latlng, index, workflowId) {

        let coordsCopy = cloneDeep(coords);
        coordsCopy.splice(index, 1, latlng);
        setCoords(coordsCopy);

        let workflowIdsCopy = cloneDeep(workflowIds);
        workflowIdsCopy.splice(index, 1, workflowId );
        setWorkflowIds(workflowIdsCopy);

    }

    const onLabelMarkerDragEnd = function (e) {
        setLabelCoord(e.target._latlng);
    }

    const recalculateAngle = function () {
        if (!arrayUtilities.isNullOrEmpty(coords) && coords.length == 2) {


            let point0 = map.latLngToContainerPoint(coords[0]);
            let point1 = map.latLngToContainerPoint(coords[1]);

            let dy = point1.y - point0.y;
            let dx = point1.x - point0.x;
            let theta = Math.atan2(dy, dx);

            theta *= 180 / Math.PI;
            theta += 90;

            setAngle(theta);
            setDistance(geospatialUtilities.haversineDistance(coords[0], coords[1]));
        }
    }

    useMapEvents({
        click: (e) => {
            if (isDrawing) {
                setDoSnapToPoint(e.latlng);
            }
        },
        zoomend: () => {
            recalculateAngle();
        }
    })

    useEffect(() => {
        if (arrayUtilities.isNullOrEmpty(workflowIds)) {
            resetSnappedWorkflowIds();
        }
    }, [props.points, props.lines]);

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

        map.closePopup();
    }, [isEditing])

    useEffect(() => {
        recalculateAngle();
    }, [coords])

    useEffect(() => {

        let pointsLinesIsDefault = (arrayUtilities.isNullOrEmpty(props.workflowPoints) || props.workflowPoints.length == props.workflows.length) && (arrayUtilities.isNullOrEmpty(props.workflowLines) || props.workflowLines.length == props.workflows.length) && (arrayUtilities.isNullOrEmpty(props.workflowPointsLines) || props.workflowPointsLines.length == props.workflows.length);
        let allWorkflowsAreVisible = arrayUtilities.isNullOrEmpty(workflowIds) || pointsLinesIsDefault || (workflowIds.length == workflowIds.filter(x => props.workflowPoints.includes(x) || props.workflowLines.includes(x) || props.workflowPointsLines.includes(x)).length);

        setIsVisible(isDrawing || isEditing || allWorkflowsAreVisible);
    }, [isDrawing, isEditing, workflowIds, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {

        let shouldPrints = [];

        if (printingSketchLimitIndex !== null || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

            let limitIsVisible = stringUtilities.isNullOrEmpty(limitId) || arrayUtilities.isNullOrEmpty(props.sketch?.limits) || (props.sketch.limits.findIndex(x => x.id === limitId) === printingSketchLimitIndex);

            if (!limitIsVisible) {
                setShouldPrint(false);
            } else {

                if (!arrayUtilities.isNullOrEmpty(coords)) {

                    coords.forEach((coord, index) => {
                        let shouldPrint = true;
                        let workflowId = !arrayUtilities.isNullOrEmpty(workflowIds) && workflowIds.length > index ? workflowIds[index] : null;

                        if ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                            if (!stringUtilities.isNullOrEmpty(workflowId)) {
                                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                                let typeIsNormallyVisible = ((workflowIds.filter(x => x === printingWorkflowId).length > 0 || printingSketchLimitIndex === "ALL") && pointUtilities.getType(workflowId, workflowId.type, props.workflows, false)?.isNormallyVisible) ?? false;

                                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite) && !typeIsNormallyVisible) {
                                    shouldPrint = false;
                                }

                                if (shouldPrint && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {

                                    let limit = props.sketch.limits[printingSketchLimitIndex];

                                    if (limit !== null) {
                                        let polygon = workflow && workflow !== null && workflow.isComposite ?
                                            (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) ? geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords) : null) :
                                            (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords) ? limit.limitOfLocateCoords : null);

                                        if (polygon !== null && !geospatialUtilities.polygonContainsPoint(polygon, coord)) {
                                            shouldPrint = false;
                                        }
                                    }
                                }
                            }
                        }

                        shouldPrints.push(shouldPrint);
                    })
                }

                setShouldPrint(arrayUtilities.isNullOrEmpty(shouldPrints) || shouldPrints.filter(x => x).length === shouldPrints.length);
            }
        } else {
            setShouldPrint(true);
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let iconSize = 21 * props.arrowScale;

    let icon0 = divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 30 30" fill="none"  style="transform-origin: top center; transform: rotate(${angle}deg)">
            <line x1="15" y1="15" x2="15" y2="30" stroke="${color}" stroke-width="2"/>
            <path d="M 15,2 30,15 0,15 0,15 15,2 z" fill="${color}"/>
        </svg>`,
        className: "marker-icon-point",
        iconAnchor: [iconSize * 0.5, 0],
        iconSize: [iconSize, iconSize]
    });


    let icon1 = divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 30 30" fill="none"  style="transform-origin: top center; transform: rotate(${angle - 180}deg)">
            <line x1="15" y1="15" x2="15" y2="30" stroke="${color}" stroke-width="2"/>
            <path d="M 15,2 30,15 0,15 0,15 15,2 z" fill="${color}"/>
        </svg>`,
        className: "marker-icon-point",
        iconAnchor: [iconSize * 0.5, 0],
        iconSize: [iconSize, iconSize]
    });

    let labelIcon = divIcon({
        html: `<div class="text-overlay ${!stringUtilities.isNullOrEmpty(fontsize) ? fontsize : ""}" style="transform: rotate(${rotate ?? 0}deg); ${!stringUtilities.isNullOrEmpty(color) ? `color: ${color};` : ""}">${(stringUtilities.isNullOrEmpty(label) ? `${stringUtilities.formatDecimal(distance)}m` : label)}</div>`,
        className: "marker-icon-text-overlay",
        iconAnchor: [-5, -5]
    });

    let popup = <MapPopup title="Dimension Line" onEdit={() => setIsEditing(true)} deleteTitle="Delete Dimension Line" deleteContent="Are you sure you want to permanently delete this dimension line?" onDelete={onDelete} />;

    return <>

        {!arrayUtilities.isNullOrEmpty(coords) && isVisible && shouldPrint && <>

            {coords.map((coord, index) =>
                (!isDrawing && !isEditing) ?
                    <Marker
                        index={index}
                        key={`workflow-drawing-tool-point-${index}`}
                        pane="dimension-line-icons"
                        icon={index === 0 ? icon0 : icon1}
                        position={[coord.lat, coord.lng]}
                        bubblingMouseEvents={false}>
                        {!isDrawing && !isEditing && <>
                            {popup}
                        </>}
                    </Marker>
                    :
                    <SnappableMarker
                        index={index}
                        key={`workflow-drawing-tool-point-${index}`}
                        pane="dimension-line-icons"
                        position={[coord.lat, coord.lng]}
                        icon={index === 0 ? icon0 : icon1}
                        onDragend={(e, index, workflowId) => onSnappableMarkerDragEnd(e.latlng, index, workflowId)}
                        snappingTolerance={snappingTolerance}
                        sketch={props.sketch}
                        points={props.points}
                        lines={props.lines}
                        workflows={props.workflows}
                        disableSnapToLimit
                        workflowLines={props.workflowLines}
                        workflowPoints={props.workflowPoints}
                        workflowPointsLines={props.workflowPointsLines}
                    />
            )}

            <Marker
                position={labelCoord && labelCoord !== null ? [labelCoord.lat, labelCoord.lng] : [coords[0].lat, coords[0].lng]}
                pane="drawing-tool-labels"
                icon={labelIcon}
                draggable={isDrawing || isEditing}
                eventHandlers={{
                    dragend: onLabelMarkerDragEnd
                }}>
                {popup}
            </Marker>
        </>}

        {doSnapToPoint !== null &&
            <SnapToPoint
                latlng={doSnapToPoint}
                icon={arrayUtilities.isNullOrEmpty(coords) ? icon0 : icon1}
                snappingTolerance={snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                workflows={props.workflows}
                disableSnapToLimit
                onSnappedPointSelected={onAddPoint}
                onCancel={() => setDoSnapToPoint(null)}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines} />
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Dimension Line`} onCancel={onCancel} onSave={onSave}>

            {!arrayUtilities.isNullOrEmpty(workflowIds) &&
                <div className="mb-3">
                    <label className="text-body fs-sm me-2 pe-1 text-nowrap form-label">Connected to</label>
                    <ul>
                        {workflowIds.map((workflowId, index) => {
                            let workflow = props.workflows.find(x => x.id === workflowId);
                            if (workflow && workflow !== null) {
                                return <li key={`dimenion-line-connected-to-${workflowId}`} index={index} className="text-body fs-sm ">{workflow.name}</li>
                            }
                        })}
                    </ul>

                </div>
            }

            <WeightSelect value={weight} onChange={setWeight} />            
            <FontSizeSelect value={fontsize} onChange={setFontsize} />
            <ColorPicker value={color} onChange={setColor} />
            <RotationSlider value={rotate} onChange={setRotate} />
            <OpacitySlider value={opacity} onChange={setOpacity} />
            <Textbox label="Label" value={label} onChange={setLabel} />
            <Button size="xs" variant="secondary" className="mb-3" onClick={() => setLabelCoord(null)} disabled={labelCoord === null}>Reset label position</Button>
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />

        </ConfigurationDialog>
    </>
}