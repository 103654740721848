import { useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import postal from 'postal'
import arrayUtilities from '../../utilities/arrayUtilities';
import geospatialUtilities from '../../utilities/geospatialUtilities';

export default function RemoteControl(props) {
    const map = useMap();
    map.attributionControl.setPrefix("");

    const onBoundsChange = function () {
        if (props.onBoundsChange) {
            props.onBoundsChange(map.getBounds(), map.getCenter());
        }
    }

    const onZoomChange = function () {
        postal.publish({
            channel: "map",
            topic: "zoom.changed",
            data: {
                zoom: map.getZoom()
            }
        });

        if (props.onZoomChange) {
            props.onZoomChange(map.getZoom())
        };
    }

    useMapEvents({
        moveend: onBoundsChange,
        zoomend: onZoomChange
    })

    useEffect(() => {

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "project.loaded",
                callback: function (data) {

                    if (data.project.latestPoint && data.project.latestPoint !== null && data.project.latestPoint.lat !== 0 && data.project.latestPoint.lng !== 0) {
                        
                        map.setView({
                            lat: data.project.latestPoint.lat,
                            lng: data.project.latestPoint.lng
                        }, 18);
                        
                        onZoomChange();

                    } else if (data.project.latitude !== 0 && data.project.longitude !== 0) {
                        map.setView({
                            lat: data.project.latitude,
                            lng: data.project.longitude
                        }, 16);

                        onZoomChange();
                    }
                }
            }),

            postal.subscribe({
                channel: "map",
                topic: "sketch.loaded",
                callback: function (data) {

                    if (data && data.sketch && data.sketch !== null) {

                        let latLngs = [];

                        if (!arrayUtilities.isNullOrEmpty(data.sketch.limits)) {
                            data.sketch.limits.forEach((limit) => {
                                if (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords)) {
                                    limit.limitOfLocateCoords.forEach((limitOfLocateCoord) => {
                                        latLngs.push(geospatialUtilities.coordToLatLngArray(limitOfLocateCoord));
                                    })
                                }

                                if (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords)) {
                                    limit.limitOfSketchCoords.forEach((limitOfSketchCoord) => {
                                        latLngs.push(geospatialUtilities.coordToLatLngArray(limitOfSketchCoord));
                                    })
                                }
                            })
                        }

                        if (!arrayUtilities.isNullOrEmpty(data.sketch.items)) {
                            data.sketch.items.forEach((item) => {
                                if (!arrayUtilities.isNullOrEmpty(item.coords)) {
                                    item.coords.forEach((coord) => {
                                        latLngs.push(geospatialUtilities.coordToLatLngArray(coord));
                                    })
                                }
                            })
                        }

                        if (!arrayUtilities.isNullOrEmpty(data.sketch.textMarkers)) {
                            data.sketch.textMarkers.forEach((textMarker) => {
                                if (!arrayUtilities.isNullOrEmpty(textMarker.coords)) {
                                    textMarker.coords.forEach((coord) => {
                                        latLngs.push(geospatialUtilities.coordToLatLngArray(coord));
                                    })
                                }
                            })
                        }

                        if (!arrayUtilities.isNullOrEmpty(latLngs)) {
                            map.fitBounds(latLngs);
                        }

                        let zoom = data.sketch?.configuration?.zoom ?? 0;
                        
                        if (zoom > 0) {
                            setTimeout(() => {
                                map.setZoom(zoom);
                            }, 500);
                        }
                    }
                }
            }),

            postal.subscribe({
                channel: "map",
                topic: "zoom.change",
                callback: function (data) {
                    map.setZoom(data.zoom);
                }
            }),

            postal.subscribe({
                channel: "map",
                topic: "view.change",
                callback: function (data) {
                    map.setView({
                        lat: data.lat,
                        lng: data.lng
                    }, 17);

                }
            }),

            postal.subscribe({
                channel: "map",
                topic: "zoom.enabled",
                callback: function (data) {
                    map.zoomControl.enable();
                    map.scrollWheelZoom.enable();
                    map.doubleClickZoom.enable();
                    map.touchZoom.enable();
                    map.boxZoom.enable();
                }
            }),


            postal.subscribe({
                channel: "map",
                topic: "zoom.disabled",
                callback: function (data) {
                    map.zoomControl.disable();
                    map.scrollWheelZoom.disable();
                    map.doubleClickZoom.disable();
                    map.touchZoom.disable();
                    map.boxZoom.disable();
                }
            }),
        ]

        onBoundsChange();

        return () => subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });

    }, [])

    return null;
}