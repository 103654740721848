import { Button, Form, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "../form/textbox";
import { useEffect, useState } from "react";
import stringUtilities from "../../utilities/stringUtilities";
import Tags from "../form/tags";


export default function StampModal(props) {

    const [name, setName] = useState(props.stamp?.name ?? "");
    const [svg, setSvg] = useState(null);
    const [tagIds, setTagIds] = useState(props.stamp?.tags.map((t) => t.id) ?? []);

    const [canSave, setCanSave] = useState(false);

    const onSvgChange = function (e) {

        if (e?.target?.files !== null && e?.target?.files.length > 0) {
            setSvg(e.target.files[0]);
        }
    }

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {
        if (props.onSave) {
            props.onSave(props.stamp?.id ?? null, name, svg, tagIds);
        }
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name) && (svg !== null || props.stamp))
    }, [name, svg])

    useEffect(() => {
        if (svg !== null && stringUtilities.isNullOrEmpty(name)) {
            setName(svg.name.replace(".svg", ""));
        }
    }, [svg])

    return <Modal show onHide={onCancel} centered size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{props.stamp ? "Edit" : "Add"} stamp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)', minHeight: 'calc(100vh - 380px)' }}>
                <Textbox label="Name" value={name} onChange={setName} />

                <Form.Group className='mb-3'>
                    <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                        SVG
                    </Form.Label>
                    <Form.Control size='sm' type="file" onChange={onSvgChange} accept=".svg" />
                </Form.Group>

                <Tags organisationId={props.organisationId} value={tagIds} onChange={setTagIds} />
            </SimpleBar>
        </Modal.Body>
        <Modal.Footer>
            <Button size="sm" variant='secondary' onClick={onCancel}>Cancel</Button>
            <Button size="sm" variant='primary' onClick={onSave} disabled={!canSave}>Save</Button>
        </Modal.Footer>
    </Modal>
}