import { Button, Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { SketchPicker } from "react-color";

export default function ColorPicker(props) {

    const onChange = function (color) {
        if (props.onChange) {
            props.onChange(color);
        }
    }

    return <Form.Group className='mb-3' size="sm">
        <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
            Color
        </Form.Label>
        <InputGroup className="mb-3" size="sm">
            <OverlayTrigger placement='left' rootClose trigger='click' overlay={
                <Popover close>
                    <Popover.Body>
                        <SketchPicker color={props.value} onChange={(e) => onChange(e.hex)} />
                    </Popover.Body>
                </Popover>
            }>
                <Button style={{ backgroundColor: props.value }}>&nbsp;</Button>
            </OverlayTrigger>
            <Form.Control size='sm' value={props.value} onChange={(e) => onChange(e.target.value)} />
        </InputGroup>
    </Form.Group>;
}