import { Form, InputGroup } from "react-bootstrap";

export default function FilterBar(props) {

    const onChange = function(value) {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    return <Form.Group className={`d-flex align-items-center flex-shrink-0 ${props.className}`}>
        <Form.Label className='text-body fs-sm me-2 mb-0 pe-1 text-nowrap'>
            <i className='fi-filter text-muted mt-n1'></i>
        </Form.Label>
        <InputGroup size="sm">
            <Form.Control size='sm' placeholder='Filter' value={props.value} onChange={(e) => onChange(e.target.value)}></Form.Control>
            <InputGroup.Text className='p-0'>
                <a href='#' className='d-block text-body px-3 py-2' onClick={() => onChange("")}>
                    <i className='fi-x mt-n1'></i>
                </a>
            </InputGroup.Text>
        </InputGroup>
    </Form.Group>
}