import { Form } from "react-bootstrap";

export default function FontSizeSelect(props) {

    const onChange = function (e) {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    return <Form.Group controlId='input-small' className='mb-3'>
        <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
            Font Size
        </Form.Label>
        <Form.Select size='sm' value={props.value} onChange={onChange}>
            <option value="font-smallest">Smallest</option>
            <option value="font-smaller">Smaller</option>
            <option value="font-normal">Normal</option>
            <option value="font-bigger">Bigger</option>
            <option value="font-biggest">Biggest</option>
        </Form.Select>
    </Form.Group>
}