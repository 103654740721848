import { Popup } from "react-leaflet";
import WhenMapIsNotDrawingOrEditing from "../whenMapIsNotDrawingOrEditing";
import DeleteButton from "../../common/deleteButton";
import { Button } from "react-bootstrap";

export default function MapPopup(props) {

    const onEdit = function() {
        if (props.onEdit) {
            props.onEdit();
        }
    }

    const onDelete = function() {
        if (props.onDelete) {
            props.onDelete();
        }
    }


    return <WhenMapIsNotDrawingOrEditing>
        <Popup>
            <div className='card border-0'>
                <div className='card-body position-relative'>
                    <h5 className="card-title mb-4">{props.title}</h5>
                    <div className="d-flex justify-content-between">
                        <Button size="sm" variant='secondary' onClick={onEdit}>Edit</Button>
                        <DeleteButton modalTitle={props.deleteTitle} modalContent={props.deleteContent} onConfirm={onDelete} />
                    </div>
                </div>
            </div>
        </Popup>
    </WhenMapIsNotDrawingOrEditing>;
}