import { Form } from "react-bootstrap";
import ReactSlider from "react-slider";

export default function ScaleSlider(props) {

    const onChange = function(e) {
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return <Form.Group controlId='input-small' className='mb-3'>
        <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
            Scale
        </Form.Label>
        <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0.1} max={props.max ?? 5} value={props.value} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.1} onChange={onChange} renderThumb={(props, state) => (
            <div {...props}>
                <div className='range-slider-tooltip'>{state.valueNow}</div>
            </div>
        )}
        />
    </Form.Group>
}