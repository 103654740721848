import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";
import SimpleBar from "simplebar-react";
import stringUtilities from "../../utilities/stringUtilities";
import FilterBar from "./../common/filterBar";

export default function SketchPicker(props) {

    const [filter, setFilter] = useState("");
    const [filteredSketches, setFilteredSketches] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const sketch = arrayUtilities.isNullOrEmpty(props.sketches) ? null : props.sketches.find(x => x.id === props.value);

    const onChange = function (id) {
        if (props.onChange) {
            props.onChange(id);
        }

        setModalIsVisible(false);
    }

    useEffect(() => {
        setFilter("");
    }, [modalIsVisible])

    useEffect(() => {
        setFilteredSketches(arrayUtilities.isNullOrEmpty(props.sketches) ? [] : props.sketches.filter((s) => {
            return stringUtilities.isNullOrEmpty(filter) ||
                s.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        }));
    }, [filter, props.organisations]);

    return <>
        <Form.Group className='d-flex align-items-center flex-shrink-0 mb-3'>
            <InputGroup size="sm">
                <div className="form-control form-control-static form-control-sm border-end-0 cursor-pointer" onClick={() => setModalIsVisible(true)}>{sketch?.name ?? "Open sketch..."}</div> 

                <InputGroup.Text className='p-0'>
                    <Button variant="" className='btn-link d-block text-body px-3 py-2' onClick={() => setModalIsVisible(true)}>
                        <i className='fi-chevron-down mt-n1'></i>
                    </Button>
                </InputGroup.Text>
                <InputGroup.Text className='p-0'>
                    <Button variant="" className='btn-link d-block text-body px-3 py-2' onClick={() => onChange(null)}>
                        <i className='fi-x mt-n1'></i>
                    </Button>
                </InputGroup.Text>
            </InputGroup>
        </Form.Group>

        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Select sketch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FilterBar value={filter} onChange={setFilter} className="mb-4" />

                <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)', minHeight: 'calc(100vh - 380px)' }}>
                    {!arrayUtilities.isNullOrEmpty(filteredSketches) &&
                        <ul className="sketch-picker">
                            {filteredSketches.map((sketch, index) => <li index={index} key={`sketch-picker-${sketch.id}`} className="d-flex align-items-center">
                                <Button className="btn-link" size="sm" variant="" onClick={() => onChange(sketch.id)} active={props.value === sketch.id}>{sketch.name}</Button>
                            </li>)}
                        </ul>
                    }
                </SimpleBar>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setModalIsVisible(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>
}