import { Popup, useMap, useMapEvents } from "react-leaflet";
import Loading from './../common/loading';
import { useEffect, useState, useRef } from "react";
import useApi from './../../utilities/useApi';
import { Button, Form, Modal, Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import stringUtilities from "../../utilities/stringUtilities";
import enums from "../../utilities/enums";
import postal from 'postal';
import arrayUtilities from "../../utilities/arrayUtilities";
var hash = require('object-hash');

export default function DataPopup(props) {

    const { api } = useApi();
    const map = useMap();

    const [isLoading, setIsLoading] = useState(true);
    const [point, setPoint] = useState(null);
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const [deleteMode, setDeleteMode] = useState(props.canDeletePoint ? 0 : 1)

    const onEdit = function () {

        map.closePopup();
        if (props.onEdit) {
            props.onEdit();
        }
    };

    const onDeleteConfirm = function () {

        setDeleteModalIsVisible(false);
        setIsLoading(true);

        api.deletePoint(props.organisationId, props.projectId, props.pointId, deleteMode, () => {

            map.closePopup();
            postal.publish({
                channel: "map",
                topic: "point.deleted"
            });
        }, (e) => {
            setIsLoading(false);
            postal.publish({
                channel: "error",
                topic: "thrown",
                data: {
                    error: {
                        message: "Unable to delete"
                    }
                }
            });

        })
    };

    useMapEvents({
        popupopen: (e) => {
            if (point === null && e.popup._source.options.pointId === props.pointId) {
                api.getPoint(props.organisationId, props.projectId, props.pointId, (data) => {
                    setPoint(data);
                    setIsLoading(false);
                }, () => { });
            }
        }
    });

    return <>
        <Popup className="data-popup">
            <div className='card border-0'>
                <div className='card-footer d-flex flex-column justify-content-center mx-2 px-0 text-nowrap'>
                    {isLoading &&
                        <Loading />
                    }
                    {!isLoading && point && point !== null && <>
                        <SimpleBar autoHide={false} className='simplebar-no-autohide' style={{ maxHeight: '15rem' }}>
                            <Table size="sm" striped>
                                <tbody>
                                    <tr>
                                        <td><strong className="p-0">Workflow</strong></td>
                                        <td>{point.workflow}</td>
                                    </tr>
                                    <tr>
                                        <td><strong className="p-0">Job</strong></td>
                                        <td>{point.job}</td>
                                    </tr>
                                    <tr>
                                        <td><strong className="p-0">Type</strong></td>
                                        <td>{point.formData?.Type}</td>
                                    </tr>
                                    <tr>
                                        <td><strong className="p-0">User</strong></td>
                                        <td>{point.user?.name}</td>
                                    </tr>
                                    {point.formData && point.formData !== null && <>
                                        <tr>
                                            <td colSpan={2}><h3 className="h6 px-0 m-0">Form Data</h3></td>
                                        </tr>
                                        {Object.keys(point.formData).map((key, index) => <tr index={index} key={`point-popup-formdata-${index}`}>
                                            <td><strong className="p-0">{stringUtilities.camelCaseToWords(key)}</strong></td>
                                            <td>{point.formData[key]}</td>
                                        </tr>)}
                                    </>}
                                    {point.sensorData && point.sensorData !== null && <>
                                        <tr>
                                            <td colSpan={2}><h3 className="h6 px-0 m-0">Sensor Data</h3></td>
                                        </tr>
                                        {Object.keys(point.sensorData).map((key, index) => <tr index={index} key={`point-popup-sensordata-${index}`}>
                                            <td><strong className="p-0">{stringUtilities.camelCaseToWords(key)}</strong></td>
                                            <td>{point.sensorData[key]}</td>
                                        </tr>)}
                                    </>}

                                    {point.gpsData && point.gpsData !== null && <>
                                        <tr>
                                            <td colSpan={2}><h3 className="h6 px-0 m-0">GPS Data</h3></td>
                                        </tr>
                                        {Object.keys(point.gpsData).map((key, index) => <tr index={index} key={`point-popup-gpsdata-${index}`}>
                                            <td><strong className="p-0">{stringUtilities.camelCaseToWords(key)}</strong></td>
                                            <td>{stringUtilities.formatDate(point.gpsData[key])}</td>
                                        </tr>)}
                                    </>}

                                    {!arrayUtilities.isNullOrEmpty(point.photos) && <>
                                        <tr>
                                            <td colSpan={2}><h3 className="h6 px-0 m-0">Photos</h3></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="data-popup-photos">
                                                    {point.photos.map((photo, index) => <div className="data-popup-photo" index={index} key={hash(photo)}>
                                                        <a href={photo} target="_blank"><img src={photo} /></a>
                                                    </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    </>}
                                </tbody>
                            </Table>
                        </SimpleBar>
                        <div className='d-flex justify-content-between mt-4 mb-1'>
                            <div>
                                {props.canEdit &&
                                    <Button size='sm' variant="secondary" onClick={onEdit}><i className='fi-edit me-2'></i> Edit</Button>
                                }
                            </div>                            
                            {(props.user.roleId === 0 || props.user.roleId === 1 || props.user.id === point?.user?.id) &&
                                <Button size='sm' variant="danger" onClick={() => setDeleteModalIsVisible(true)}><i className='fi-trash me-2'></i> Delete</Button>
                            }
                        </div>
                    </>}
                </div>
            </div>
        </Popup>
        <Modal show={deleteModalIsVisible} onHide={() => setDeleteModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>{`Delete ${props.canDeletePoint ? "Point" : ""}${props.canDeletePoint && props.canDeleteGeometry ? "/" : ""}${props.canDeleteGeometry ? (props.geometryType === 0 ? "Line" : enums.GeometryType[props.geometryType]) : ""}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3"></p>
                {props.canDeletePoint &&
                    <Form.Check type='radio' id='delete-radio-point' name='delete-radio' label='Delete this Point only' value={0} checked={deleteMode === 0} onChange={(e) => setDeleteMode(Number(e.target.value))} />
                }
                {props.canDeleteGeometry &&
                    <>
                        <Form.Check type='radio' id='delete-radio-geometry' name='delete-radio' label={`Delete this ${props.geometryType === 0 ? "Line" : enums.GeometryType[props.geometryType]} only`} value={1} checked={deleteMode === 1} onChange={(e) => setDeleteMode(Number(e.target.value))} />
                        <Form.Check type='radio' id='delete-radio-geometry-and-point' name='delete-radio' label={`Delete this ${props.geometryType === 0 ? "Line" : enums.GeometryType[props.geometryType]} and associated Points`} value={2} checked={deleteMode === 2} onChange={(e) => setDeleteMode(Number(e.target.value))} />
                    </>
                }
                {props.canDeletePoint && props.canDeleteGeometry &&
                    <Form.Check type='radio' id='delete-radio-split-geometry' name='delete-radio' label={`Delete this Point and split the ${props.geometryType === 0 ? "Line" : enums.GeometryType[props.geometryType]}`} value={3} checked={deleteMode === 3} onChange={(e) => setDeleteMode(Number(e.target.value))} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setDeleteModalIsVisible(false)}>Cancel</Button>
                <Button size="sm" variant="danger" onClick={onDeleteConfirm}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}