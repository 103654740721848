import { Accordion, Form } from "react-bootstrap";
import ReactSlider from "react-slider";
import BasemapPicker from "../../form/basemapPicker";

export default function SettingsToolbox(props) {
   
    const onBasemapChange = function (value) {
        if (props.onBasemapChange) {
            props.onBasemapChange(value);
        }
    }

    const onPrintBasemapChange = function (value) {
        if (props.onPrintBasemapChange) {
            props.onPrintBasemapChange(value);
        }
    }

    const onSnappingToleranceChange = function (e) {
        if (props.onSnappingToleranceChange) {
            props.onSnappingToleranceChange(e);
        }
    }

    const onArrowScaleChange = function(e) {
        if (props.onArrowScaleChange) {
            props.onArrowScaleChange(e);
        }
    }

    const onAfterArrowScaleChange = function(e) {
        if (props.onAfterArrowScaleChange) {
            props.onAfterArrowScaleChange(e);
        }
    }

    const onSymbolScaleChange = function (e) {
        if (props.onSymbolScaleChange) {
            props.onSymbolScaleChange(e);
        }
    }

    return <>
        <Accordion.Item eventKey='7'>
            <Accordion.Header><i className="fi-settings me-2" /> Settings</Accordion.Header>
            <Accordion.Body>
                <Form.Group controlId='input-small' className='mb-5'>
                    <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
                        Snapping Tolerance
                    </Form.Label>
                    <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={10} defaultValue={props.snappingTolerance} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.5} onChange={onSnappingToleranceChange} renderThumb={(props, state) => (
                        <div {...props}>
                            <div className='range-slider-tooltip'>{state.valueNow}</div>
                        </div>
                    )}
                    />
                </Form.Group>
                <Form.Group controlId='input-small' className='mb-5'>
                    <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
                        Arrow scale
                    </Form.Label>
                    <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0.1} max={3} value={props.arrowScale} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.1} onChange={onArrowScaleChange} onAfterChange={onAfterArrowScaleChange} renderThumb={(props, state) => (
                        <div {...props}>
                            <div className='range-slider-tooltip'>{state.valueNow}</div>
                        </div>
                    )}
                    />
                </Form.Group>
                <Form.Group controlId='input-small' className='mb-5'>
                    <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
                        Symbol scale
                    </Form.Label>
                    <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0.1} max={3} defaultValue={props.symbolScale} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.1} onChange={onSymbolScaleChange} renderThumb={(props, state) => (
                        <div {...props}>
                            <div className='range-slider-tooltip'>{state.valueNow}</div>
                        </div>
                    )}
                    />
                </Form.Group>
                
                <BasemapPicker label="Basemap" value={props.basemap} onChange={onBasemapChange} center={props.center} />
                <BasemapPicker label="Basemap (print)" value={props.printBasemap} onChange={onPrintBasemapChange} center={props.center} />
                
            </Accordion.Body>
        </Accordion.Item>

        
    </>
}