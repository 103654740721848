import { Button, Col, Container, Row, Table } from "react-bootstrap";
import stringUtilities from "../../utilities/stringUtilities";
import Loading from "../common/loading";
import OrganisationSelector from "../common/organisationSelector";
import DeleteButton from "../common/deleteButton";
import { useEffect, useState } from "react";
import useApi from "../../utilities/useApi";
import { useParams } from "react-router-dom";
import StampModal from "./_stampModal";
import arrayUtilities from "../../utilities/arrayUtilities";

export default function Stamps(props) {
    const { api } = useApi();
    const params = useParams();

    const [organisationId, setOrganisationId] = useState(stringUtilities.isNullOrEmpty(params.organisationId) ? props.organisationId : params.organisationId);

    const [isLoading, setIsLoading] = useState(true);
    const [stamps, setStamps] = useState(null);
    const [editStamp, setEditStamp] = useState(null)

    const [showStampModal, setShowStampModal] = useState(false);

    const addStamp = function () {
        setShowStampModal(true);
    }

    const cancelEdit = function () {
        setShowStampModal(false);
        setEditStamp(null);
    }

    const saveStamp = function (id, name, svg, tagIds) {

        setIsLoading(true);
        if (stringUtilities.isNullOrEmpty(id)) {
            api.addStamp(organisationId, name, svg, tagIds, (data) => {
                cancelEdit();
                getStamps();
            }, () => { });
        } else {
            api.editStamp(organisationId, id, name, svg, tagIds, (data) => {
                cancelEdit();
                getStamps();
            }, () => { });
        }
    }

    const getStamps = function () {

        if (!stringUtilities.isNullOrEmpty(organisationId)) {

            setIsLoading(true);
            api.getStamps(organisationId, (data) => {
                setStamps(data);
                setIsLoading(false);
            }, () => {
                setIsLoading(false);
            });
        }
    };

    const deleteStamp = function(id) {

        api.deleteStamp(organisationId, id, (data) => {
            setStamps(stamps.filter( x => x.id !== id));
        }, () => {

        });
    }

    useEffect(() => {
        getStamps();
    }, [organisationId]);

    return <>
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Stamps</h1>

                        <div className='d-flex justify-content-end'>
                            <OrganisationSelector value={organisationId} organisations={props.organisationalUnits} onChange={setOrganisationId} />

                            <Button size='sm' className='ms-2' onClick={addStamp}>
                                <i className='fi-plus me-2'></i>
                                Add stamp
                            </Button>
                        </div>
                    </div>

                    {isLoading && <Loading />}
                    {!isLoading &&
                        <Table response hover sm className="table-borderless stamps-list">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">SVG</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Tags</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            {!arrayUtilities.isNullOrEmpty(stamps) &&
                                <tbody>
                                    {stamps.map((stamp, index) =>
                                        <tr index={index} key={`stamp-${stamp.id}`} className="stamps-list-item">
                                            <td className="text-center align-middle">
                                                <img src={stamp.url} />
                                            </td>
                                            <td className="align-middle">{stamp.name}</td>
                                            <td className="align-middle">
                                                {!arrayUtilities.isNullOrEmpty(stamp.tags) && <div className="tags-list m-0">
                                                    {stamp.tags.map((tag) => <Button size="sm" key={`tag-${tag.id}`} index={index} className={`tag active my-0 `}>{tag.name}</Button>)}
                                                </div>}
                                            </td>
                                            <td className="text-end align-middle">
                                                <Button variant='secondary' size="xs" className='me-2' onClick={() => setEditStamp(stamp)}>
                                                    <i className='fi-edit me-2'></i> Edit
                                                </Button>

                                                <DeleteButton size="xs" modalTitle={`Delete Stamp`} modalContent={`Are you sure you want to permanently delete the stamp "${stamp.name}"?`} onConfirm={() => deleteStamp(stamp.id)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            }
                        </Table>
                    }
                </Col>
            </Row>
        </Container>

        {(showStampModal || editStamp !== null) &&
            <StampModal organisationId={organisationId} onCancel={cancelEdit} onSave={saveStamp} stamp={editStamp} />
        }
    </>;
}