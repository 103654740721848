import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmButton(props) {

    const [modalIsVisible, setModalIsVisible] = useState(false);

    const onConfirm = function () {
        setModalIsVisible(false);

        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    return <>
        <Button size={props.size ?? "sm"} variant={props.variant} className={props.className + (props.compact ? " btn-icon" : "")} disabled={props.disabled} onClick={() => setModalIsVisible(true)}>
            {props.compact &&
                <i className={props.icon}></i>
            }
            {!props.compact && <>
                <i className={`${props.icon} me-2`}></i> {props.label}
            </>}
        </Button>

        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3">{props.modalContent}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setModalIsVisible(false)}>Cancel</Button>
                <Button size="sm" variant="primary" onClick={onConfirm}>Ok</Button>
            </Modal.Footer>
        </Modal>
    </>
}