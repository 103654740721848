import { Accordion, Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import FilterBar from "../../common/filterBar";
import DatePicker from 'react-datepicker';
import { useEffect, useState } from "react";
import stringUtilities from "../../../utilities/stringUtilities";
var hash = require('object-hash');

export default function FiltersToolbox(props) {

    const [workflowsModalIsVisible, setWorkflowsModalIsVisible] = useState(false);
    
    const [jobsFilter, setJobsFilter] = useState("");
    const [filteredJobs, setFilteredJobs] = useState([]);

    const onJobsSelected = function (e) {
        if (props.onJobsSelected) {
            props.onJobsSelected(arrayUtilities.toggle(e.target.value, props.selectedJobs, e.target.checked))
        }
    }

    const onUsersSelected = function (e) {
        if (props.onUsersSelected) {
            props.onUsersSelected(arrayUtilities.toggle(e.target.value, props.selectedUsers, e.target.checked));
        }
    }

    const onDateFromSelected = function (date) {
        if (props.onDateFromSelected) {
            props.onDateFromSelected(date);
        }
    }

    const onDateToSelected = function (date) {
        if (props.onDateToSelected) {
            props.onDateToSelected(date);
        }
    }

    const clearDates = function () {
        onDateFromSelected(null);
        onDateToSelected(null);
    }

    const toggleWorkflow = function (index) {
        let checkbox = document.getElementById(`workflow-all-${index}`);
        if (checkbox) {
            checkbox.click();
        }
    }

    const toggleAllWorkflow = function (e) {
        toggleWorkflowPoints(e);
        toggleWorkflowLines(e);
        toggleWorkflowPointsLines(e);
    }

    const toggleWorkflowPoints = (e) => toggleWorkflowItem(e, props.workflowPoints, props.onWorkflowPointsSelected);
    const toggleWorkflowLines = (e) => toggleWorkflowItem(e, props.workflowLines, props.onWorkflowLinesSelected)
    const toggleWorkflowPointsLines = (e) => toggleWorkflowItem(e, props.workflowPointsLines, props.onWorkflowPointsLinesSelected);

    const toggleWorkflowItem = function (e, array, callback) {
        let selectedWorkflows = arrayUtilities.toggle(e.target.value, array, e.target.checked);

        if (callback) {
            callback(selectedWorkflows);
        }
    }

    const toggleAllWorkflows = function (e) {
        toggleAllPoints(e);
        toggleAllLines(e);
        toggleAllPointsLines(e);
    }

    const toggleAllPoints = (e) => { toggleWorkflows(e, props.onWorkflowPointsSelected) }
    const toggleAllLines = (e) => { toggleWorkflows(e, props.onWorkflowLinesSelected) }
    const toggleAllPointsLines = (e) => { toggleWorkflows(e, props.onWorkflowPointsLinesSelected) }

    const toggleWorkflows = function (e, callback) {
        let selectedWorkflows = e.target.checked ? props.workflows?.map((x) => x.id) : [];

        if (callback) {
            callback(selectedWorkflows);
        }
    }

    useEffect(() => {
        setFilteredJobs(arrayUtilities.isNullOrEmpty(props.jobs) ? [] : props.jobs.filter((job) => {
            return stringUtilities.isNullOrEmpty(jobsFilter) || job.name.toLowerCase().indexOf(jobsFilter.toLowerCase()) > -1;
        }));
    }, [jobsFilter, props.jobs]);

    return <>
        <Accordion.Item eventKey='1'>
            <Accordion.Header><i className="fi-filter me-2" /> Filters</Accordion.Header>
            <Accordion.Body>
                <div className='border-bottom pb-3 mb-2'>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h3 className="m-0">Jobs</h3>
                        <FilterBar value={jobsFilter} onChange={setJobsFilter} />
                    </div>
                    <SimpleBar autoHide={false} className='simplebar-no-autohide' style={{ maxHeight: '11rem' }}>
                        {!arrayUtilities.isNullOrEmpty(filteredJobs) && <>
                            {filteredJobs.map((job, index) => (
                                <Form.Check key={index} id={`job-${index}`} value={job.id} defaultChecked={props.selectedJobs?.includes(job.id)} onChange={onJobsSelected} label={<><span className='fs-sm'>{job.name}</span></>} />
                            ))}
                        </>
                        }
                    </SimpleBar>
                </div>

                <div className='border-bottom pt-3 pb-3 mb-2'>
                    <h3>Workflows</h3>
                    <Button size="sm" variant='secondary' onClick={() => setWorkflowsModalIsVisible(true)}>
                        <i className='fi-layers me-2'></i> Select workflow layers
                    </Button>
                </div>

                {!arrayUtilities.isNullOrEmpty(props.users) &&
                    <div className='border-bottom pt-3 pb-3 mb-2'>
                        <h3>Users</h3>
                        <SimpleBar autoHide={false} className='simplebar-no-autohide' style={{ maxHeight: '11rem' }}>
                            {props.users.map((user, index) => (
                                <Form.Check key={index} id={`user-${index}`} value={user.id} defaultChecked={props.selectedUsers.includes(user.id)} onChange={onUsersSelected} label={<><span className='fs-sm'>{user.name}</span></>} />
                            ))}

                        </SimpleBar>

                    </div>
                }

                <div className='pt-3 pb-3'>
                    <h3>Dates</h3>
                    <InputGroup className='flex-nowrap' size='sm'>
                        <InputGroup.Text>
                            <i className='fi-calendar'></i>
                        </InputGroup.Text>
                        <Form.Control as={DatePicker} size='sm' selected={props.dateFrom} onChange={(date) => onDateFromSelected(date)} dateFormat="dd/MM/yyyy" selectsStart startDate={props.dateFrom} endDate={props.dateTo} maxDate={props.dateTo} placeholderText='From' className='rounded-0' />
                        <Form.Control as={DatePicker} size='sm' selected={props.dateTo} onChange={(date) => onDateToSelected(date)} dateFormat="dd/MM/yyyy" selectsEnd startDate={props.dateFrom} endDate={props.dateTo} minDate={props.dateFrom} placeholderText='To' className='rounded-start-0' />
                        <Button variant="outline-secondary" onClick={clearDates}>
                            <i className='fi-x'></i>
                        </Button>
                    </InputGroup>
                </div>
            </Accordion.Body>
        </Accordion.Item>

        <Modal show={workflowsModalIsVisible} onHide={() => setWorkflowsModalIsVisible(false)} centered size="lg">
            <Modal.Header>
                <Modal.Title>Workflow Layers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Workflow</th>
                            <th><Form.Check id={`workflow-all`} checked={props.workflowPoints?.length === props.workflows?.length && props.workflowLines?.length === props.workflows?.length && props.workflowPointsLines?.length === props.workflows?.length} onChange={toggleAllWorkflows} className="py-0 m-0" label={<><span>All</span></>} /></th>
                            <th><Form.Check id={`workflow-points-all`} checked={props.workflowPoints?.length === props.workflows?.length} onChange={toggleAllPoints} className="py-0 m-0" label={<><span>Points</span></>} /></th>
                            <th><Form.Check id={`workflow-lines-all`} checked={props.workflowLines?.length === props.workflows?.length} onChange={toggleAllLines} className="py-0 m-0" label={<><span>Lines</span></>} /></th>
                            <th><Form.Check id={`workflow-points-lines-all`} checked={props.workflowPointsLines?.length === props.workflows?.length} onChange={toggleAllPointsLines} className="py-0 m-0" label={<><span>Points on lines</span></>} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!arrayUtilities.isNullOrEmpty(props.workflows) && <>
                            {props.workflows.map((workflow, index) => <tr index={index} key={`workflow-layer-${workflow.id}`}>
                                <td>
                                    <Button variant="link" className="p-0" size="sm" onClick={() => toggleWorkflow(index)}>{workflow.name}</Button>
                                </td>
                                <td><Form.Check key={hash({ id: workflow.id, workflowPoints: props.workflowPoints, workflowLines: props.workflowLines, workflowPointsLines: props.workflowPointsLines })} id={`workflow-all-${index}`} value={workflow.id} checked={props.workflowPoints?.includes(workflow.id) && props.workflowLines?.includes(workflow.id) && props.workflowPointsLines?.includes(workflow.id)} onChange={toggleAllWorkflow} /></td>
                                <td><Form.Check key={hash({ id: workflow.id, workflowPoints: props.workflowPoints })} id={`workflow-points-${index}`} value={workflow.id} checked={props.workflowPoints?.includes(workflow.id)} onChange={toggleWorkflowPoints} /></td>
                                <td><Form.Check key={hash({ id: workflow.id, workflowLine: props.workflowLines })} id={`workflow-lines-${index}`} value={workflow.id} checked={props.workflowLines?.includes(workflow.id)} onChange={toggleWorkflowLines} /></td>
                                <td><Form.Check key={hash({ id: workflow.id, workflowPointsLines: props.workflowPointsLines })} id={`workflow-points-lines-${index}`} value={workflow.id} checked={props.workflowPointsLines?.includes(workflow.id)} onChange={toggleWorkflowPointsLines} /></td>
                            </tr>)}
                        </>}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={() => setWorkflowsModalIsVisible(false)}>Finish</Button>
            </Modal.Footer>
        </Modal>
    </>
}