import { Button, Form, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "./form/textbox";
import { useEffect, useState } from "react";
import stringUtilities from "../utilities/stringUtilities";
import useApi from './../utilities/useApi';
import Loading from "./common/loading";

export default function JobModal(props) {

    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(false);

    const [job, setJob] = useState(props.job);
    const [name, setName] = useState(props.job?.name);
    
    const [canSave, setCanSave] = useState(false);
    
    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onSave = function () {

        setIsLoading(true);

        if (job && job !== null) {
            api.editJob(props.organisationId, props.projectId, job.id, name, (data) => {
                if (props.onSave) {
                    props.onSave();
                }
            }, () => {
                setIsLoading(false);
            })
        } else {
            api.addJob(props.organisationId, props.projectId, name, (data) => {
                if (props.onSave) {
                    props.onSave();
                }
            }, () => {
                setIsLoading(false);
            })
        }
    }

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name))
    }, [name])
    
    return <Modal show onHide={onCancel} centered>
    <Modal.Header closeButton>
        <Modal.Title>{(job ? "Edit" : "Add")} job</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)'}}>
            <Textbox label="Name" value={name} onChange={setName} />
        </SimpleBar>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between">
        <div>
            {isLoading && <Loading />}
        </div>
        
        <div>
            <Button size="sm" variant='secondary' onClick={onCancel} className="me-2">Cancel</Button>
            <Button size="sm" variant='primary' onClick={onSave} disabled={!canSave}>Save</Button>
        </div>
    </Modal.Footer>
</Modal>
}