import { TileLayer } from "react-leaflet";

export default function Basemap(props) {

    return <>
        {props.basemap === 0 && (props.zoom < 20 || !props.isDownloadingMapPackage) &&
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={props.maxZoom} />
        }
        {props.basemap === 1 &&
            <TileLayer attribution='Powered by <a href="https://www.esri.com/" target="_blank">Esri</a>. Sources: Esri, DigitalGlobe, GeoEye, i-cubed, USDA FSA, USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community' url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" maxZoom={props.maxZoom} />
        }
    </>
}