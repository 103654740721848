import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import stringUtilities from "../../utilities/stringUtilities";
import Loading from "../common/loading";
import OrganisationSelector from "../common/organisationSelector";
import { useEffect, useState } from "react";
import useApi from "../../utilities/useApi";
import { useParams } from "react-router-dom";
import ReactSlider from "react-slider";

export default function DeviceManagement(props) {

    const { api } = useApi();
    const params = useParams();

    const [organisationId, setOrganisationId] = useState(stringUtilities.isNullOrEmpty(params.organisationId) ? props.organisationId : params.organisationId);

    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState(null);

    const [saveAndMoreMode, setSaveAndMoreMode] = useState(0);
    const [autoSync, setAutoSync] = useState(false);
    const [autoSyncFrequency, setAutoSyncFrequency] = useState(0);
    const [bluetoothWindow, setBluetoothWindow] = useState(false);
    const [audibleToneOnPointCollection, setAudibleToneOnPointCollection] = useState(false);
    const [locationSource, setLocationSource] = useState(0);
    const [locationTranslation, setLocationTranslation] = useState(0);
    const [accuracyLimitEnabled, setAccuracyLimitEnabled] = useState(false);
    const [accuracyLimitCentimetres, setAccuracyLimitCentimetres] = useState(0);

    const getSettings = function () {
        if (!stringUtilities.isNullOrEmpty(organisationId)) {

            setIsLoading(true);
            api.getDeviceManagementSettings(organisationId, (data) => {
                setSettings(data);
                setIsLoading(false);
            }, () => {
                setSettings(null);
                setIsLoading(false);
            });
        }
    }

    const save = function () {
        if (!stringUtilities.isNullOrEmpty(organisationId)) {

            setIsLoading(true);
            api.saveDeviceManagementSettings(organisationId, saveAndMoreMode, autoSync, autoSyncFrequency, bluetoothWindow, audibleToneOnPointCollection, locationSource, locationTranslation, accuracyLimitEnabled, accuracyLimitCentimetres, (data) => {
                setSettings(data);
                setIsLoading(false);
            }, () => {
                setSettings(null);
                setIsLoading(false);
            });
        }
    }

    const reset = function () {
        if (settings) {
            setSaveAndMoreMode(settings.saveAndMoreMode);
            setAutoSync(settings.autoSync);
            setAutoSyncFrequency(settings.autoSyncFrequency);
            setBluetoothWindow(settings.bluetoothWindow);
            setAudibleToneOnPointCollection(settings.audibleToneOnPointCollection);
            setLocationSource(settings.locationSource);
            setLocationTranslation(settings.locationTranslation);
            setAccuracyLimitEnabled(settings.accuracyLimitEnabled);
            setAccuracyLimitCentimetres(settings.accuracyLimitCentimetres);
        } else {
            setSaveAndMoreMode(0);
            setAutoSync(false);
            setAutoSyncFrequency(0);
            setBluetoothWindow(false);
            setAudibleToneOnPointCollection(false);
            setLocationSource(0);
            setLocationTranslation(0);
            setAccuracyLimitEnabled(false);
            setAccuracyLimitCentimetres(0);
        }
    }

    useEffect(() => {
        reset();
    }, [settings]);

    useEffect(() => {
        getSettings();
    }, [organisationId]);

    return <>
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Device Management Settings</h1>

                        <div className='d-flex justify-content-end'>
                            <OrganisationSelector value={organisationId} organisations={props.organisationalUnits} onChange={setOrganisationId} />
                        </div>
                    </div>

                    {isLoading && <Loading />}
                    {!isLoading && settings !== null &&
                        <>
                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-save-and-more-mode">
                                    Save And More Mode
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Select size='sm' value={saveAndMoreMode} onChange={(e) => setSaveAndMoreMode(Number(e.target.value))}>
                                        <option value="0">Manual</option>
                                        <option value="1">Automatic</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-auto-sync">
                                    Auto Sync
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Check type='switch' id="device-management-auto-sync" checked={autoSync} onChange={(e) => setAutoSync(e.target.checked)} />
                                </div>
                            </Form.Group>

                            {autoSync &&
                                <Form.Group className='mb-3 row align-items-center'>
                                    <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-auto-sync">
                                        Auto Sync Frequency (minutes)
                                    </Form.Label>
                                    <div className="col-md-9 d-flex align-items-center">
                                        <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={1} max={20} value={autoSyncFrequency} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={1} onChange={(e) => setAutoSyncFrequency(e)} renderThumb={(props, state) => (
                                            <div {...props}>
                                                <div className='range-slider-tooltip'>{state.valueNow}</div>
                                            </div>
                                        )}
                                        />
                                    </div>
                                </Form.Group>
                            }

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm  col-form-label col-md-3' htmlFor="device-management-bluetooth-window">
                                    Show Bluetooth data during data collection
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Check type='switch' id="device-management-bluetooth-window" checked={bluetoothWindow} onChange={(e) => setBluetoothWindow(e.target.checked)} />
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm  col-form-label col-md-3' htmlFor="device-management-audible-tone-on-point-collection">
                                    Audible Tone on Point Collection
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Check type='switch' id="device-management-audible-tone-on-point-collection" checked={audibleToneOnPointCollection} onChange={(e) => setAudibleToneOnPointCollection(e.target.checked)} />
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-location-source">
                                    Location Source
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Select size='sm' value={locationSource} onChange={(e) => setLocationSource(Number(e.target.value))}>
                                        <option value="0">Location Services</option>
                                        <option value="1">Catalyst</option>
                                        <option value="2">Trimble GNSS</option>
                                        <option value="3">NMEA</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-location-translation">
                                    Location Translation
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Select size='sm' value={locationTranslation} onChange={(e) => setLocationTranslation(Number(e.target.value))}>
                                        <option value="0">None</option>
                                        <option value="1">NAD832011 ITRF2014 -&gt; WebMercator</option>
                                        <option value="2">NAD832011 ITRF2008 -&gt; WebMercator</option>
                                        <option value="3">NAD83 CSRS -&gt; WebMercator</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3 row align-items-center'>
                                <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-accuracy-limit-enabled">
                                    Accuracy Limit Enabled
                                </Form.Label>
                                <div className="col-md-9 d-flex align-items-center">
                                    <Form.Check type='switch' id="device-management-accuracy-limit-enabled" checked={accuracyLimitEnabled} onChange={(e) => setAccuracyLimitEnabled(e.target.checked)} />
                                </div>
                            </Form.Group>

                            {accuracyLimitEnabled &&
                                <Form.Group className='mb-3 row align-items-center'>
                                    <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="device-management-accuracy-limit">
                                        Accuracy Limit (cm)
                                    </Form.Label>
                                    <div className="col-md-9 d-flex align-items-center">
                                        <Form.Control size='sm' value={accuracyLimitCentimetres} type="number" set="1" onChange={(e) => setAccuracyLimitCentimetres(Math.max(0, Math.round(Number(e.target.value))))} />
                                    </div>
                                </Form.Group>
                            }

                            <Form.Group className='mb-3 row justify-content-end'>
                                <div className="col-md-9">
                                    <Button size="sm" variant='secondary' onClick={reset} className="me-2">Reset</Button>
                                    <Button size="sm" variant='primary' onClick={save}>Save</Button>
                                </div>
                            </Form.Group>
                        </>
                    }

                </Col>
            </Row>
        </Container>

    </>;
}