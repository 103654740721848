import { Accordion, Button, Table, Form } from "react-bootstrap";
import arrayUtilities from "../../../utilities/arrayUtilities";
import postal from 'postal';
import DeleteButton from "../../common/deleteButton";
import stringUtilities from "../../../utilities/stringUtilities";

export default function LimitsToolbox(props) {

    const addLimit = function () {
        postal.publish({
            channel: "sketch",
            topic: "limit.add"
        });

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: true }
        })
    }

    const editLimit = function (index) {
        postal.publish({
            channel: "sketch",
            topic: "limit.edit",
            data: { index }
        });

        postal.publish({
            channel: "map",
            topic: "isDrawingOrEditing",
            data: { value: true }
        })
    }

    const deleteLimit = function (index) {
        postal.publish({
            channel: "sketch",
            topic: "limit.delete",
            data: { index }
        });
    }

    const selectActiveLimit = function(e) {
        if (props.onActiveLimitSelected) {            
            props.onActiveLimitSelected(e.target.checked ? e.target.value : "");
        }
    }

    return <>
        {props.sketch && props.sketch !== null &&
            <Accordion.Item eventKey='3'>
                <Accordion.Header><i className="fi-layers me-2" /> Limits</Accordion.Header>
                <Accordion.Body className="px-2">
                    {!arrayUtilities.isNullOrEmpty(props.sketch.limits) && <>
                        <Table size="sm" className="table-borderless m-0">
                            <tbody>
                                {props.sketch.limits.map((limit, index) => {

                                    let limitName = stringUtilities.isNullOrEmpty(limit.name) ? `Limit ${index + 1}` : limit.name;

                                    return <tr index={index} key={`limitstoolbox-item-${index}`}>
                                        <td className="align-middle">{limitName}</td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <Form.Check type='switch' id='switch-1' className="me-2" value={limit.id} checked={props.activeLimit === limit.id} onChange={selectActiveLimit} />

                                                <Button variant='secondary btn-icon' size="sm" className='me-2' onClick={() => editLimit(index)}>
                                                    <i className='fi-edit'></i>
                                                </Button>

                                                <DeleteButton compact modalTitle="Delete Limit" modalContent={`Are you sure you want to permanently delete the Limit '${limitName}'?`} onConfirm={() => deleteLimit(index)} />
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </>}
                    {arrayUtilities.isNullOrEmpty(props.sketch.limitOfLocates) && <>
                        <div className='d-flex justify-content-start mb-3' onClick={addLimit}>
                            <Button size='sm' variant="primary"><i className='fi-plus me-2'></i> New</Button>
                        </div>
                    </>}
                </Accordion.Body>
            </Accordion.Item>
        }</>;
}