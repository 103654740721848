import { useEffect, useState } from "react";
import * as htmlToImage from 'html-to-image';
import { useMap } from "react-leaflet";
import arrayUtilities from "../../../utilities/arrayUtilities";
import postal from "postal";
import JSZip from "jszip";
import { Button, ListGroup, Offcanvas } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Loading from "../../common/loading";
import stringUtilities from "../../../utilities/stringUtilities";
import geospatialUtilities from "../../../utilities/geospatialUtilities";

import cloneDeep from 'lodash.clonedeep';
import moment from "moment";

export default function DownloadMapPackage(props) {

    const timeToWaitForScreenshot = 1000; // Wait for screen to refresh before grabbing image.

    const [mapOutputWidth, setMapOutputWidth] = useState(800);
    const [mapOutputHeight, setMapOutputHeight] = useState(450);

    const map = useMap();

    const [hasStarted, setHasStarted] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [outputs, setOutputs] = useState([]);

    const doMapPackage = async function () {

        if (!hasStarted) {
            setHasStarted(true);

            let centerPoint = map.getCenter();
            let initialZoom = map.getZoom();
            let mapContainer = document.querySelector(".map-container");

            let mapOutputRatio = mapOutputHeight / mapOutputWidth;
            let mapOutputIsLandscape = mapOutputRatio > 1;

            let clone = cloneDeep(outputs);

            for (let outputIndex = 0; outputIndex < clone.length; outputIndex++) {

                let limit = !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.length > outputIndex ? props.sketch.limits[outputIndex] : null;

                if (limit !== null && !arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords)) {

                    let p0 = map.latLngToContainerPoint(limit.limitOfSketchCoords[0]);
                    let p1 = map.latLngToContainerPoint(limit.limitOfSketchCoords[1]);

                    let xDiff = Math.max(p0.x, p1.x) - Math.min(p0.x, p1.x);
                    let yDiff = Math.max(p0.y, p1.y) - Math.min(p0.y, p1.y);

                    let losRatio = yDiff / xDiff;
                    let losRadioIsLandscape = losRatio > 1;

                    if (losRadioIsLandscape === mapOutputIsLandscape) {
                        await configureMapLayout(mapContainer, centerPoint, mapOutputWidth, mapOutputHeight);
                    } else {
                        await configureMapLayout(mapContainer, centerPoint, mapOutputHeight, mapOutputWidth);
                    }

                    let shifted = geospatialUtilities.coordsToLatLngArray([geospatialUtilities.shiftPoint(limit.limitOfSketchCoords[0], -1, -1), geospatialUtilities.shiftPoint(limit.limitOfSketchCoords[1], 1, 1)])

                    map.fitBounds(shifted, {
                        animate: false,
                        padding: [0, 0]
                    });
                }

                if (!arrayUtilities.isNullOrEmpty(clone[outputIndex].images)) {

                    for (let imageIndex = 0; imageIndex < clone[outputIndex].images.length; imageIndex++) {

                        let image = clone[outputIndex].images[imageIndex];

                        postal.publish({
                            channel: "map",
                            topic: "printing",
                            data: {
                                sketchLimitIndex: outputIndex,
                                workflowId: image.workflowId
                            }
                        });

                        image.url = await takeScreenshot();

                        let element = document.getElementById(`output-${outputIndex}-${imageIndex}`);
                        if (element && element !== null) {
                            element.classList.add("complete");
                        }
                    }
                }
            }


            setOutputs(clone);
            await complete(mapContainer, centerPoint, initialZoom);
        }
    }

    const configureMapLayout = function (mapContainer, centerPoint, width, height) {
        mapContainer.classList.add("print");
        mapContainer.style.width = `${width}px`;
        mapContainer.style.height = `${height}px`;

        return new Promise(resolve => setTimeout(() => {
            map.invalidateSize();
            map.setView(centerPoint);
            return resolve();
        }, 1000));
    }

    const takeScreenshot = async function () {
        try {
            return new Promise(resolve => setTimeout(async () => {
                await (htmlToImage.toJpeg(document.querySelector(".map-container"), { quality: 1 }).then(function (dataUrl) {
                    return resolve(dataUrl);
                }));
                return resolve();

            }, timeToWaitForScreenshot));
        }
        catch (e) {
            console.log(e);
        }
    }

    const generateAndDownloadZip = async function () {

        const zip = new JSZip();

        for (let outputIndex = 0; outputIndex < outputs.length; outputIndex++) {
            for (let imageIndex = 0; imageIndex < outputs[outputIndex].images.length; imageIndex++) {

                let image = outputs[outputIndex].images[imageIndex];

                const response = await fetch(image.url);
                const blob = await response.blob();

                zip.file(image.name, blob);
            }
        }

        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });

        let datetime = moment();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `map-package-${datetime.format("YYYYMMDD-HHmmss")}.zip`;
        link.click();
    }

    const complete = function (mapContainer, centerPoint, initialZoom) {
        mapContainer.classList.remove("print");
        mapContainer.style.width = "";
        mapContainer.style.height = "";

        postal.publish({
            channel: "map",
            topic: "printing",
            data: {
                sketchLimitIndex: null,
                workflowId: null
            }
        });

        return new Promise(resolve => setTimeout(() => {
            map.invalidateSize();
            map.setView(centerPoint, initialZoom);
            setIsComplete(true);

            return resolve();
        }, 500));
    }

    const close = function () {

        if (props.onComplete) {
            props.onComplete();
        }
    }

    const getLimitName = function (limitIndex) {
        if (!arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch?.limits.length > limitIndex && !stringUtilities.isNullOrEmpty(props.sketch?.limits[limitIndex]?.name)) {
            return props.sketch.limits[limitIndex]?.name;
        }

        return `Limit ${limitIndex + 1}`;
    }

    const getImageName = function (limitIndex, workflowName) {

        let filename = `${stringUtilities.toFriendlyString(`${getLimitName(limitIndex)} ${workflowName}`)}.jpg`
        return filename;
    }

    const downloadImage = function (image) {

        if (image) {
            const link = document.createElement("a");
            link.href = image.url;
            link.download = image.name;
            link.click();
        }
    }

    useEffect(() => {
        if (!arrayUtilities.isNullOrEmpty(outputs)) {
            doMapPackage();
        }
    }, [outputs]);

    useEffect(() => {

        let timeout = setTimeout(() => {

            let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
            
            let dataWorkflowIds = !arrayUtilities.isNullOrEmpty(props.points) ? arrayUtilities.distinct(props.points.map(x => x.workflowId)) : [];
            let sketchWorkflowIds = !arrayUtilities.isNullOrEmpty(props.sketch?.items) ? arrayUtilities.distinct(props.sketch.items.map(x => x.tool?.properties?.workflowId)) : [];
            let textMarkerWorkflowIds = !arrayUtilities.isNullOrEmpty(props.sketch?.textMarkers) ? arrayUtilities.distinct(props.sketch.textMarkers.map(x => x.workflowId)) : [];

            let combinedWorkflowIds = arrayUtilities.distinct(dataWorkflowIds.concat(sketchWorkflowIds).concat(textMarkerWorkflowIds)).filter(x => !stringUtilities.isNullOrEmpty(x));

            setOutputs(
                props.sketch?.limits.map(
                    (limit, limitIndex) => {
                        return {
                            index: limitIndex,
                            name: getLimitName(limitIndex),
                            images: [{
                                workflowId: "ALL",
                                workflowName: "All",
                                name: getImageName(limitIndex, "all"),
                                url: ""
                            }].concat(props.workflows
                                .filter(workflow => combinedWorkflowIds.includes(workflow.id) && (pointsLinesIsDefault || props.workflowPoints.includes(workflow.id) || props.workflowLines.includes(workflow.id) || props.workflowPointsLines.includes(workflow.id)))
                                .map((workflow, workflowIndex) => {
                                    return {
                                        workflowId: workflow.id,
                                        workflowName: workflow.name,
                                        name: getImageName(limitIndex, workflow.name),
                                        url: ""
                                    }
                                }))
                        }
                    }
                )
            );

        }, 500);

        let mapOutputWidth = props.project?.configuration?.mapOutputWidth;
        let mapOutputHeight = props.project?.configuration?.mapOutputHeight;

        if (!isNaN(mapOutputWidth) && mapOutputWidth !== 0 && !isNaN(mapOutputHeight) && mapOutputHeight !== 0) {
            setMapOutputWidth(mapOutputWidth);
            setMapOutputHeight(mapOutputHeight);
        }

        return () => {
            clearTimeout(timeout);
        }

    }, []);


    return <>
        <Offcanvas show placement='end' scroll={false} backdrop={false}>
            <Offcanvas.Header className='border-bottom'>
                <Offcanvas.Title>Download Map Package</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="d-flex flex-align-start flex-column h-100 w-100 ">
                    <div className="w-100 h-100 mb-auto overflow-y-auto">
                        <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 280px)' }}>
                            {!arrayUtilities.isNullOrEmpty(outputs) && <>
                                {outputs.map((output, outputIndex) => <div index={outputIndex} key={`output-${outputIndex}`}>
                                    <p className="h6">{output.name}</p>
                                    <ListGroup as='ul' variant='flush' className="mb-3">
                                        {!arrayUtilities.isNullOrEmpty(output.images) && <div className="d-flex flex-column download-map-package-list">
                                            {output.images.map((image, imageIndex) => <ListGroup.Item as='li' className='d-flex align-items-center' index={imageIndex} key={`output-${outputIndex}-${imageIndex}`} id={`output-${outputIndex}-${imageIndex}`}>
                                                <Loading />
                                                {isComplete ?
                                                    <Button variant="btn-icon" onClick={() => downloadImage(image)} disabled={!isComplete}>
                                                        <i className="fi fi-download text-success" />
                                                    </Button> :
                                                    <i className="fi fi-check text-success" />
                                                }

                                                <p>{image.workflowName}</p>
                                            </ListGroup.Item>)}
                                        </div>
                                        }

                                    </ListGroup>
                                </div>
                                )}
                            </>}

                        </SimpleBar>
                    </div>
                    <div className="d-flex justify-content-between order-2 w-100">
                        <Button size="sm" variant="secondary" disabled={!isComplete} onClick={generateAndDownloadZip}><i className="fi-folders me-2"></i> Download ZIP</Button>
                        <Button onClick={close} variant="primary" size="sm" disabled={!isComplete}>Finish</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}