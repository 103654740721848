import { Form } from "react-bootstrap";
import stringUtilities from "../../utilities/stringUtilities";

export default function Textbox(props) {

    const onChange = function (e) {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    return <Form.Group className='mb-3'>
        {!stringUtilities.isNullOrEmpty(props.label) &&
            <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                {props.label}
            </Form.Label>
        }
        <Form.Control size='sm' onChange={onChange} value={props.value} />
    </Form.Group>
}