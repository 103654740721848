export default {
    distinct(array) {
        return [...new Set(array)];
    },

    newOrConcat(array, element) {
        return this.isNullOrEmpty(array) ? [element] : array.concat([element]);
    },

    toggle(element, array, include) {
        
        if (this.isNullOrEmpty(array)) {
            array = [];
        }
        
        if (include) {
            return array.concat([ element ])
        }
        
        return array.filter((x) => x !== element);
    },

    isNullOrEmpty(array) {
        return !array || array === null || !Array.isArray(array) || array.length === 0;
    },

    interleave (array1, array2) {
        let result = [];
        for (let i = 0; i < array1.length; i++) {

            result.push(array1[i]);

            if (array2.length > i) {
                result.push(array2[i]);
            }
        }
        
        return result;
    }
}