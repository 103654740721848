import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";
import snapUtilities from "../../utilities/snapUtilities";

export default function SnapToPoint(props) {

    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [candidates, setCandidates] = useState(null);

    const doSnap = function () {

        let result = snapUtilities.snapToNearest(props.latlng, props.snappingTolerance, props.points, props.lines, props.sketch, props.workflows, props.disableSnapToLimit, props.workflowLines, props.workflowPoints, props.workflowPointsLines);

        if (props.onSnappedPointSelected) {
            props.onSnappedPointSelected(result?.latlng, result?.workflowId);
        }
    }

    const onSnappedPointSelected = function (index) {
        if (props.onSnappedPointSelected) {
            props.onSnappedPointSelected(candidates[0].latlng, candidates[0].workflowId);
        }
    }

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    useEffect(() => {
        doSnap();
    }, [])

    return <>
        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>Snap To...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!arrayUtilities.isNullOrEmpty(candidates) && <>
                    <Table size="sm">
                        <tbody>
                            {candidates.map((candidate, index) => <tr index={index} key={`snap-to=candidate-${index}`}>
                                <td>{candidate.label}</td>
                                <td>{candidate.distance.toFixed(2)}m</td>
                                <td className="text-end">
                                    <Button size="xs" onClick={() => onSnappedPointSelected(index)}>Snap to this</Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={onCancel}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>
}