import { Form } from "react-bootstrap";

export default function WeightSelect(props) {
    const onChange = function (e) {
        if (props.onChange) {
            props.onChange(Number(e.target.value));
        }
    }

    return <Form.Group className='mb-3'>
        <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
            Weight
        </Form.Label>
        <Form.Select size='sm' value={props.value} onChange={onChange}>
            <option value="1">Pencil</option>
            <option value="2">Thin</option>
            <option value="3">Middle</option>
            <option value="4">Thick</option>
        </Form.Select>
    </Form.Group>
}