import { useState } from "react"
import enums from "../../utilities/enums";
import { Button, Form, FormGroup, InputGroup, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { MapContainer, TileLayer } from "react-leaflet";

export default function BasemapPicker(props) {

    const [modalIsVisible, setModalIsVisible] = useState(false);

    const onChange = function(e) {
        if (props.onChange) {
            props.onChange(Number(e.target.value));
        }
    }    

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    return <>
        <FormGroup controlId="basemap" className="mb-5">
            <Form.Label className='text-body fs-sm me-0 pe-1 text-nowrap'>
                {props.label}
            </Form.Label>
            <InputGroup size="sm">
                <div className="form-control form-control-static form-control-sm">{enums.Basemaps[props.value]}</div>

                <InputGroup.Text className='p-0'>
                    <a href='#' className='d-block text-body px-3 py-2' onClick={() => setModalIsVisible(true)}>
                        <i className='fi-switch-horizontal mt-n1'></i>
                    </a>
                </InputGroup.Text>
            </InputGroup>
        </FormGroup>

        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Select basemap</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 380px)', minHeight: 'calc(100vh - 380px)' }}>
                    <div class="card-hover card-body px-4 mb-2 card">
                        <div class="form-check">
                            <input type="radio" id="basemap-2" class="form-check-input" checked={props.value === 2} value={2} onChange={onChange} />
                            <label htmlFor="basemap-2" class="d-sm-flex w-100 align-items-start justify-content-between form-check-label">
                                <span class="d-block pe-5">
                                    <span class="d-block h6 mb-2">{enums.Basemaps[2]}</span>
                                </span>
                                <span className="d-block h4 mb-0 mini-map-container flex-shrink-0 d-flex flex-column justify-content-center shadow">
                                    <i className='fi-x fs-1 text-danger text-center'></i>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="card-hover card-body px-4 mb-2 card">
                        <div class="form-check">
                            <input type="radio" id="basemap-0" className="form-check-input" checked={props.value === 0} value={0} onChange={onChange} />
                            <label htmlFor="basemap-0" className="d-sm-flex w-100 align-items-start justify-content-between form-check-label">
                                <span class="d-block pe-5">
                                    <span className="d-block h6 mb-2">{enums.Basemaps[0]}</span>
                                    <span class="d-block mb-2 mb-sm-0">&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors</span>
                                </span>
                                <span className="d-block h4 mb-0 mini-map-container flex-shrink-0 shadow">
                                    <MapContainer center={props.center} zoom={15} zoomControl={false} style={containerStyle} dragging={false}>
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    </MapContainer>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="card-hover card-body px-4 mb-2 card">
                        <div class="form-check">
                            <input type="radio" id="basemap-1" class="form-check-input" checked={props.value === 1} value={1} onChange={onChange} />
                            <label htmlFor="basemap-1" class="d-sm-flex w-100 align-items-start justify-content-between form-check-label">
                                <span class="d-block pe-5">
                                    <span class="d-block h6 mb-2">{enums.Basemaps[1]}</span>
                                    <span class="d-block mb-2 mb-sm-0">Powered by <a href="https://www.esri.com/" target="_blank">Esri</a>. Sources: Esri, DigitalGlobe, GeoEye, i-cubed, USDA FSA, USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community</span>
                                </span>
                                <span className="d-block h4 mb-0 mini-map-container flex-shrink-0 shadow">
                                    <MapContainer center={props.center} zoom={15} zoomControl={false} style={containerStyle} dragging={false}>
                                        <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
                                    </MapContainer>
                                </span>
                            </label>
                        </div>
                    </div>
                </SimpleBar>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setModalIsVisible(false)}>Cancel</Button>
                <Button size="sm" variant="primary" onClick={() => setModalIsVisible(false)}>Finish</Button>
            </Modal.Footer>
        </Modal>
    </>
}