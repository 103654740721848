import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Polygon } from "react-leaflet";
import EditableRectangle from "../editableRectangle";
import postal from "postal";
import ConfigurationDialog from "./_configurationDialog";
import WeightSelect from "../../form/weightSelect";
import ColorPicker from "../../form/colorPicker";
import OpacitySlider from "../../form/opacitySlider";
import WorkflowSelect from "../../form/workflowSelect";
import stringUtilities from "../../../utilities/stringUtilities";
import geospatialUtilities from "../../../utilities/geospatialUtilities";

import * as turf from '@turf/turf'
import MapPopup from "./_mapPopup";
import LimitSelect from "../../form/limitSelect";
import hash from "object-hash";

export default function BoxDrawingTool(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [renderPositions, setRenderPositions] = useState(null);

    const [northEast, setNorthEast] = useState(null);
    const [southWest, setSouthWest] = useState(null);

    const [weight, setWeight] = useState(null);
    const [color, setColor] = useState(null);
    const [opacity, setOpacity] = useState(null);
    const [fillOpacity, setFillOpacity] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);

    const reset = function () {

        let coords = props.sketchItem?.coords ?? [];

        setNorthEast(!arrayUtilities.isNullOrEmpty(coords) && coords.length > 0 ? coords[0] : null);
        setSouthWest(!arrayUtilities.isNullOrEmpty(coords) && coords.length > 0 ? coords[1] : null);
        setWeight(props.sketchItem?.tool?.properties?.weight ?? 1);
        setColor(props.sketchItem?.tool?.properties?.color ?? "#000000");
        setOpacity(props.sketchItem?.tool?.properties?.opacity ?? 1);
        setFillOpacity(props.sketchItem?.tool?.properties?.fillOpacity ?? 0.5);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);
    }

    const onRectangleChange = function (northEast, southWest) {
        setNorthEast(northEast);
        setSouthWest(southWest);
        setIsDrawing(false);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords: [
                    northEast,
                    southWest
                ],
                tool: {
                    id: 23,
                    properties: {
                        weight,
                        color,
                        opacity,
                        fillOpacity,
                        workflowId,
                        limitId
                    }
                }
            }
            props.onSave(sketchItem, props.index);
        }
        
        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

    }, [isEditing])

    useEffect(() => {
        if (northEast !== null && southWest !== null) {
            setRenderPositions(geospatialUtilities.boundsToLatLngArray([northEast, southWest]));
        } else {
            setRenderPositions(null);
        }
    }, [northEast, southWest])

    useEffect(() => {

        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {
        if (northEast !== null && southWest !== null) {
            let renderPositions = geospatialUtilities.boundsToLatLngArray([northEast, southWest]);

            if (printingSketchLimitIndex !== null || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                    renderPositions = null;
                }

                if (renderPositions !== null && !stringUtilities.isNullOrEmpty(workflowId)) {
                    let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                    if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                        renderPositions = null;
                    }
                }
                
                if (renderPositions !== null && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {

                    let limit = props.sketch.limits[printingSketchLimitIndex];

                    let p1 = turf.polygon([geospatialUtilities.boundsToLatLngArray([northEast, southWest])]);
                    let p2 = turf.polygon([geospatialUtilities.boundsToLatLngArray(limit.limitOfSketchCoords)]);

                    var intersection = turf.intersect(p1, p2);

                    if (intersection) {
                        renderPositions = intersection.geometry.coordinates;
                    } else {
                        renderPositions = null;
                    }
                }
            }

            setRenderPositions(renderPositions);
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let pathOptions = { color, weight, fill: true, opacity, fillColor: color, fillOpacity };

    return <>

        {(isDrawing || isEditing) &&

            <EditableRectangle isDrawing={props.isDrawing} pathOptions={pathOptions} key={hash({ northEast, southWest})} northEast={northEast} southWest={southWest} onChange={onRectangleChange} allowResize />
        }
        {!isDrawing && !isEditing && isVisible && !arrayUtilities.isNullOrEmpty(renderPositions) &&
            <Polygon positions={renderPositions} pathOptions={pathOptions}>
                <MapPopup title="Box" onEdit={() => setIsEditing(true)} deleteTitle="Delete Box" deleteContent="Are you sure you want to permanently delete this box?" onDelete={onDelete} />
            </Polygon>
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Box`} onCancel={onCancel} onSave={onSave}>
            <WeightSelect value={weight} onChange={setWeight} />
            <ColorPicker value={color} onChange={setColor} />
            <OpacitySlider value={opacity} onChange={setOpacity} />
            <OpacitySlider label="Fill Opacity" value={fillOpacity} onChange={setFillOpacity} />
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
        </ConfigurationDialog>
    </>
}