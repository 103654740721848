import { Marker, Polygon, Polyline, Popup, useMap } from "react-leaflet";
import arrayUtilities from "../../utilities/arrayUtilities";
import pointUtilities from "../../utilities/pointUtilities";
import stringUtilities from "../../utilities/stringUtilities";
import DataPopup from "./dataPopup";
import { useEffect, useState } from "react";
import postal from "postal";
import WhenMapIsNotDrawingOrEditing from "./whenMapIsNotDrawingOrEditing";
import geospatialUtilities from "../../utilities/geospatialUtilities";

import * as turf from '@turf/turf'
import { divIcon } from "leaflet";
import snapUtilities from "../../utilities/snapUtilities";
import ConfigurationDialog from "./drawingTools/_configurationDialog";
import RotationSlider from "../form/rotationSlider";
import cloneDeep from "lodash.clonedeep";
import { Button } from "react-bootstrap";


export default function DataGeomtry(props) {

    const linePoints = props.points.filter(x => x.lineId === props.lineId);
    const isVisible = !stringUtilities.isNullOrEmpty(props.lineId) && !arrayUtilities.isNullOrEmpty(linePoints) && props.workflowLines?.includes(linePoints[0].workflowId);

    const [isEditing, setIsEditing] = useState(false);
    const [rotate, setRotate] = useState(null);

    const [isNormallyVisible, setIsNormallyVisible] = useState(false);
    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [renderPositions, setRenderPositions] = useState(geospatialUtilities.coordsToLatLngArray(linePoints));

    const [midPoints, setMidPoints] = useState(null);
    const [renderMidPoints, setRenderMidPoints] = useState(null);
    const map = useMap();

    const reset = function () {

        setRotate(0);

        if (!arrayUtilities.isNullOrEmpty(props.sketch?.configuration?.data)) {
            let dataItem = props.sketch.configuration.data.find(x => x.id === props.lineId);

            if (dataItem && dataItem !== null) {
                setRotate(dataItem.rotate);
            }

            if (dataItem && dataItem.midPoints && dataItem.midPoints !== null) {
                setMidPoints(dataItem.midPoints);
            } else {
                setMidPoints(defaultMidPoints());
            }
        }
        else {
            setMidPoints(defaultMidPoints());
        }
    }

    const onCancel = function () {
        setIsEditing(false);
        reset();
    }

    const onSave = function () {
        if (props.onSave) {
            props.onSave({
                id: props.lineId,
                rotate,
                midPoints
            });
        }

        setIsEditing(false);
    }

    const defaultMidPoints = function () {

        let result = [];
        if (!arrayUtilities.isNullOrEmpty(linePoints)) {
            for (let i = 0; i < linePoints.length; i++) {
                if (i > 0) {
                    let p0 = { lat: linePoints[i - 1].lat, lng: linePoints[i - 1].lng };
                    let p1 = { lat: linePoints[i].lat, lng: linePoints[i].lng };

                    result.push(geospatialUtilities.midPoint(p0, p1));
                };
            }
        }

        return result;
    }

    const deleteMidPoint = function (index) {

        map.closePopup();

        let copy = cloneDeep(midPoints);
        copy.splice(index, 1);
        setMidPoints(copy);
    }

    const onMidPointMarkerDragEnd = function (e, index) {

        let copy = cloneDeep(midPoints);
        copy.splice(index, 1, e.target._latlng);
        setMidPoints(copy);
    }

    const isNormallyVisibleForWorkflowId = function (workflowId) {
        let typeIsNormallyVisible = pointUtilities.getType(linePoints[0].workflowId, linePoints[0].formData?.Type, props.workflows, false)?.isNormallyVisible ?? false;

        if (typeIsNormallyVisible) {
            let dimensionLines = props.sketch?.items.filter(x => x.tool?.id === 1);
            if (!arrayUtilities.isNullOrEmpty(dimensionLines)) {
                for (let i = 0; i < dimensionLines.length; i++) {

                    if (!arrayUtilities.isNullOrEmpty(dimensionLines[i].coords)) {

                        let workflowIds = [];
                        for (let j = 0; j < dimensionLines[i].coords.length; j++) {
                            let snapResult = snapUtilities.snapToNearest(dimensionLines[i].coords[j], props.snappingTolerance, props.points, props.lines, props.sketch, false, props.workflowLines, props.workflowPoints, props.workflowPointsLines);
                            if (snapResult && !stringUtilities.isNullOrEmpty(snapResult.workflowId)) {
                                workflowIds.push(snapResult.workflowId);
                            }
                        }

                        if (workflowIds.includes(workflowId) && workflowIds.includes(linePoints[0].workflowId)) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    useEffect(() => {

        if (!arrayUtilities.isNullOrEmpty(linePoints)) {
            let isNormallyVisible = isNormallyVisibleForWorkflowId(printingWorkflowId);
            setIsNormallyVisible(isNormallyVisible);

            if (isVisible || isNormallyVisible) {

                let renderPositions = geospatialUtilities.coordsToLatLngArray(linePoints);
                let renderMidPoints = midPoints;

                if ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                    let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === linePoints[0].workflowId);

                    if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== linePoints[0].workflowId && (!workflow || workflow === null || !workflow.isComposite) && !isNormallyVisible) {
                        renderPositions = null;
                        renderMidPoints = [];
                    }
                    else if (printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {

                        let limit = props.sketch.limits[printingSketchLimitIndex];

                        if (limit !== null) {

                            let clipToPolygon = workflow.isComposite ?
                                (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords) ? geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords) : null) :
                                (!arrayUtilities.isNullOrEmpty(limit.limitOfLocateCoords) ? limit.limitOfLocateCoords : null);

                     

                            if (!arrayUtilities.isNullOrEmpty(clipToPolygon)) {
                                
                                let poly = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                                    geospatialUtilities.coordsToLatLngArray(clipToPolygon)
                                )]);

                                if (linePoints.length > 1) {

                                    if (linePoints[0].geometryType === 0 || linePoints[0].geometryType === 1) {

                                        renderPositions = [[]];

                                        let interleaved = arrayUtilities.interleave(linePoints, defaultMidPoints());

                                        interleaved.forEach((linePoint, linePointIndex) => {

                                            if (linePointIndex > 0) {
                                                let prevLinePoint = interleaved[linePointIndex - 1];

                                                let line = turf.lineString([[prevLinePoint.lat, prevLinePoint.lng], [linePoint.lat, linePoint.lng]]);
                                                var lineSplits = turf.lineSplit(line, poly);                                            //console.log(lineSplits);

                                                if (arrayUtilities.isNullOrEmpty(lineSplits.features)) {

                                                    // None of the line parts intersect, so its either fully within or fully without.


                                                    if (turf.booleanPointInPolygon([prevLinePoint.lat, prevLinePoint.lng], poly) || turf.booleanPointInPolygon([linePoint.lat, linePoint.lng], poly)) {
                                                        renderPositions[renderPositions.length - 1].push([prevLinePoint.lat, prevLinePoint.lng]);
                                                        renderPositions[renderPositions.length - 1].push([linePoint.lat, linePoint.lng]);
                                                    }
                                                    else if (renderPositions[renderPositions.length - 1].length > 0) {
                                                        renderPositions.push([]);
                                                    }

                                                } else {


                                                    // Iterate over the line parts and just include the ones that are inside the poly

                                                    lineSplits.features.forEach((feature) => {

                                                        feature.geometry.coordinates.forEach((coordinate, coordinateIndex) => {

                                                            if (coordinateIndex > 0) {
                                                                let prev = feature.geometry.coordinates[coordinateIndex - 1];

                                                                let p0 = { lat: prev[0], lng: prev[1] };
                                                                let p1 = { lat: coordinate[0], lng: coordinate[1] };

                                                                let midPoint = geospatialUtilities.midPoint(p0, p1);

                                                                if (turf.booleanPointInPolygon([midPoint.lat, midPoint.lng], poly)) {
                                                                    renderPositions[renderPositions.length - 1].push(prev);
                                                                    renderPositions[renderPositions.length - 1].push(coordinate);
                                                                }
                                                                else if (renderPositions[renderPositions.length - 1].length > 0) {
                                                                    renderPositions.push([]);
                                                                }
                                                            }
                                                        });
                                                    });
                                                }

                                            }
                                        })
                                    } else if (linePoints[0].geometryType === 2) {

                                        let p1 = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                                            geospatialUtilities.coordsToLatLngArray(linePoints)
                                        )]);

                                        var intersection = turf.intersect(p1, poly);

                                        if (intersection) {
                                            renderPositions = intersection.geometry.coordinates;
                                        } else {
                                            renderPositions = null;
                                        }
                                    }
                                }

                                if (!arrayUtilities.isNullOrEmpty(midPoints)) {
                                    renderMidPoints = [];

                                    midPoints.forEach((midPoint) => {
                                        if (geospatialUtilities.polygonContainsPoint(clipToPolygon, midPoint)) {
                                            renderMidPoints.push(midPoint);
                                        }
                                    });
                                }
                            }
                        }
                    }
                }

                setRenderPositions(renderPositions);
                setRenderMidPoints(renderMidPoints);
            }
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        setRenderMidPoints(midPoints);
    }, [midPoints])

    useEffect(() => {
        reset();
    }, [props.sketch])

    useEffect(() => {

        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, []);

    const renderMidpointIcons = function (popup) {
        let iconUrl = pointUtilities.getIconUrlFromPoint(linePoints[0], props.workflows);

        if (!stringUtilities.isNullOrEmpty(iconUrl) && !arrayUtilities.isNullOrEmpty(renderMidPoints)) {
            let icon = divIcon({
                html: `<img src="${iconUrl}" style="transform: rotate(${rotate ?? 0}deg);">`,
                className: "marker-icon-point",
                iconAnchor: [15 * props.symbolScale, 15 * props.symbolScale],
                iconSize: [30 * props.symbolScale, 30 * props.symbolScale]
            });

            return renderMidPoints.map((midPoint, index) =>
                <Marker position={midPoint} icon={icon} index={index} key={`${props.lineId}-midpoint-${index}`} pointId={linePoints[0].id} draggable={isEditing} bubblingMouseEvents={false}
                    eventHandlers={{
                        dragend: (e) => onMidPointMarkerDragEnd(e, index)
                    }}>
                    <WhenMapIsNotDrawingOrEditing>
                        {isEditing ? <Popup>
                            <div className='card border-0'>
                                <div className='card-body position-relative d-flex justify-content-center'>
                                    <Button size="sm" variant='danger' onClick={() => deleteMidPoint(index)}>Delete mid-point</Button>
                                </div>
                            </div>
                        </Popup> : popup}
                    </WhenMapIsNotDrawingOrEditing>
                </Marker>
            );
        }

        return null;
    }

    if ((isVisible || isNormallyVisible) && !arrayUtilities.isNullOrEmpty(renderPositions)) {

        let color = pointUtilities.getColorFromPoint(linePoints[0], props.workflows);

        let pathOptions = {
            color,
            weight: 3,
            dashArray: pointUtilities.getIsDashedFromPoint(linePoints[0], props.workflows) ? "5 10" : null
        };

        let popup = <DataPopup organisationId={props.organisationId} user={props.user} canEdit={props.sketch && props.sketch !== null} onEdit={() => setIsEditing(true)} projectId={props.projectId} pointId={linePoints[0].id} canDeleteGeometry geometryType={linePoints[0].geometryType} />;

        return <>
            {linePoints[0].geometryType === 2 && <>
                <Polygon positions={renderPositions} pathOptions={pathOptions} pointId={linePoints[0].id}>
                    <WhenMapIsNotDrawingOrEditing>
                        {popup}
                    </WhenMapIsNotDrawingOrEditing>
                </Polygon>
                {renderMidpointIcons(popup)}
            </>}
            {linePoints[0].geometryType !== 2 && <>
                <Polyline positions={renderPositions} pathOptions={pathOptions} pointId={linePoints[0].id}>
                    <WhenMapIsNotDrawingOrEditing>
                        {popup}
                    </WhenMapIsNotDrawingOrEditing>
                </Polyline>
                {renderMidpointIcons(popup)}
            </>}

            <ConfigurationDialog show={isEditing} title={`Edit ${linePoints[0].geometryType}`} onCancel={onCancel} onSave={onSave}>
                <RotationSlider value={rotate} onChange={setRotate} />
            </ConfigurationDialog>
        </>
    };
}