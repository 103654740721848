import { Button, Form, Offcanvas } from "react-bootstrap";
import enums from "../../../utilities/enums";
import { saveAs } from 'file-saver';
import useApi from "../../../utilities/useApi";
import { useState } from "react";
import Loading from "../../common/loading";
import SimpleBar from "simplebar-react";


export default function DownloadData(props) {

    const srids = [
        { id: 4326, name: "WGS 84" },
        { id: 26901, name: "NAD83 / UTM zone 1N" },
        { id: 26902, name: "NAD83 / UTM zone 2N" },
        { id: 26903, name: "NAD83 / UTM zone 3N" },
        { id: 26904, name: "NAD83 / UTM zone 4N" },
        { id: 26905, name: "NAD83 / UTM zone 5N" },
        { id: 26906, name: "NAD83 / UTM zone 6N" },
        { id: 26907, name: "NAD83 / UTM zone 7N" },
        { id: 26908, name: "NAD83 / UTM zone 8N" },
        { id: 26909, name: "NAD83 / UTM zone 9N" },
        { id: 26910, name: "NAD83 / UTM zone 10N" },
        { id: 26911, name: "NAD83 / UTM zone 11N" },
        { id: 26912, name: "NAD83 / UTM zone 12N" },
        { id: 26913, name: "NAD83 / UTM zone 13N" },
        { id: 26914, name: "NAD83 / UTM zone 14N" },
        { id: 26915, name: "NAD83 / UTM zone 15N" },
        { id: 26916, name: "NAD83 / UTM zone 16N" },
        { id: 26917, name: "NAD83 / UTM zone 17N" },
        { id: 26918, name: "NAD83 / UTM zone 18N" },
        { id: 26919, name: "NAD83 / UTM zone 19N" },
        { id: 26920, name: "NAD83 / UTM zone 20N" },
        { id: 26921, name: "NAD83 / UTM zone 21N" },
        { id: 26922, name: "NAD83 / UTM zone 22N" },
        { id: 26923, name: "NAD83 / UTM zone 23N" },
        { id: 32181, name: "NAD83 / MTM zone 1" },
        { id: 32182, name: "NAD83 / MTM zone 2" },
        { id: 32183, name: "NAD83 / MTM zone 3" },
        { id: 32184, name: "NAD83 / MTM zone 4" },
        { id: 32185, name: "NAD83 / MTM zone 5" },
        { id: 32186, name: "NAD83 / MTM zone 6" },
        { id: 32187, name: "NAD83 / MTM zone 7" },
        { id: 32188, name: "NAD83 / MTM zone 8" },
        { id: 32189, name: "NAD83 / MTM zone 9" },
        { id: 32190, name: "NAD83 / MTM zone 10" },
        { id: 32191, name: "NAD83 / MTM zone 11" },
        { id: 32192, name: "NAD83 / MTM zone 12" },
        { id: 32193, name: "NAD83 / MTM zone 13" },
        { id: 32194, name: "NAD83 / MTM zone 14" },
        { id: 32195, name: "NAD83 / MTM zone 15" },
        { id: 32196, name: "NAD83 / MTM zone 16" },
        { id: 32197, name: "NAD83 / MTM zone 17" },
        { id: 3775, name: "NAD83 / Alberta 3TM ref merid 111 W" },
        { id: 3776, name: "NAD83 / Alberta 3TM ref merid 114 W" },
        { id: 3777, name: "NAD83 / Alberta 3TM ref merid 117 W" },
        { id: 3778, name: "NAD83 / Alberta 3TM ref merid 120 W" },
        { id: 26946, name: "NAD83 / California zone 6" },
        { id: 2230, name: "NAD83 / California zone 6 (ftUS)" },
        { id: 32104, name: "NAD83 / Nebraska" },
        { id: 26852, name: "NAD83 / Nebraska (ftUS)" },
        { id: 102704, name: "NAD 1983 StatePlane Nebraska FIPS 2600 Feet" },
        { id: 2287, name: "NAD83 / Wisconsin North (ftUS)" },
        { id: 2288, name: "NAD83 / Wisconsin Central (ftUS)" },
        { id: 2289, name: "NAD83 / Wisconsin South (ftUS)" },
        { id: 3070, name: "NAD83 / Wisconsin Transverse Mercator" },
        { id: 6879, name: "NAD83(2011) / Wisconsin Central" },
        { id: 3158, name: "NAD83(CSRS) / UTM zone 14N" },
        { id: 2958, name: "NAD83(CSRS) / UTM zone 17N" },
    ];

    const { api } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [srid, setSrid] = useState(srids[0].id);
    const [includePhotos, setIncludePhotos] = useState(false);

    const cancel = function () {
        if (props.onComplete) {
            props.onComplete();
        }
    }

    const download = function () {
        setIsLoading(true);
        api.getData(props.organisationId, props.projectId, props.selectedJobs, props.selectedUsers, null, props.dataBounds.getNorth(), props.dataBounds.getSouth(), props.dataBounds.getWest(), props.dataBounds.getEast(), props.dateFrom, props.dateTo, 0, props.exportFormat, Number(srid), includePhotos, null, (blob, filename) => {

            saveAs(blob, filename);
            setIsLoading(false);
            cancel();
        }, (e) => {
            console.log(e);
        });
    }

    return <>
        <Offcanvas show placement='end' scroll={false} backdrop={false}>
            <Offcanvas.Header className='border-bottom'>
                <Offcanvas.Title>Download as {enums.ExportFormats[props.exportFormat]}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="d-flex flex-align-start flex-column h-100 w-100 ">
                    <div className="w-100 h-100 mb-auto overflow-y-auto">
                        {isLoading && <Loading />}
                        {!isLoading &&
                            <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: 'calc(100vh - 280px)' }}>
                                {props.exportFormat > 1 && <>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                                            UTM Zone SRID
                                        </Form.Label>
                                        <Form.Select size='sm' value={srid} onChange={(e) => setSrid(e.target.value)}>
                                            <>
                                                {srids.map((x, index) => <option value={x.id} key={x.id} index={index}>{x.name} (EPSG: {x.id})</option>)}
                                            </>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className='mb-3 row align-items-center'>
                                        <Form.Label className='form-label fs-sm col-form-label' htmlFor="download-include-photos">
                                            Include Photos
                                        </Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Check type='switch' id="download-include-photos" checked={includePhotos} onChange={(e) => setIncludePhotos(e.target.checked)} />
                                        </div>
                                    </Form.Group>
                                </>}
                            </SimpleBar>
                        }
                    </div>
                    <div className="d-flex justify-content-between order-2 w-100">
                        <Button onClick={cancel} variant="secondary" size="sm">Cancel</Button>
                        <Button size="sm" variant="primary" onClick={download}><i className="fi-download me-2"></i> Download</Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}