import { useEffect, useState } from "react";
import EditablePolyline from "../editablePolyline";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Polyline } from "react-leaflet";
import postal from "postal";
import ConfigurationDialog from "./_configurationDialog";
import OpacitySlider from "../../form/opacitySlider";
import ColorPicker from "../../form/colorPicker";
import WeightSelect from "../../form/weightSelect";
import WorkflowSelect from "../../form/workflowSelect";
import stringUtilities from "../../../utilities/stringUtilities";
import geospatialUtilities from "../../../utilities/geospatialUtilities";

import * as turf from '@turf/turf'
import MapPopup from "./_mapPopup";
import LimitSelect from "../../form/limitSelect";
import hash from "object-hash";

export default function LineDrawingTool(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);
    const [renderPositions, setRenderPositions] = useState(null);

    const [coords, setCoords] = useState(null);
    const [weight, setWeight] = useState(null);
    const [color, setColor] = useState(null);
    const [opacity, setOpacity] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);

    const reset = function () {
        setCoords(props.sketchItem?.coords ?? []);
        setWeight(props.sketchItem?.tool?.properties?.weight ?? 1);
        setColor(props.sketchItem?.tool?.properties?.color ?? "#000000");
        setOpacity(props.sketchItem?.tool?.properties?.opacity ?? 1);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let sketchItem = {
                coords,
                tool: {
                    id: props.toolId,
                    properties: {
                        weight,
                        color,
                        opacity,
                        workflowId,
                        limitId
                    }
                }
            }
            props.onSave(sketchItem, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

    }, [isEditing])

    useEffect(() => {
        if (!arrayUtilities.isNullOrEmpty(coords)) {
            setRenderPositions(geospatialUtilities.coordsToLatLngArray(coords));
        }
    }, [coords])

    useEffect(() => {

        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {
        if (!arrayUtilities.isNullOrEmpty(coords)) {
            let renderPositions = geospatialUtilities.coordsToLatLngArray(coords);

            if (printingSketchLimitIndex !== null || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

                if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                    renderPositions = null;
                }

                if (renderPositions !== null && !stringUtilities.isNullOrEmpty(workflowId)) {
                    let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                    if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                        renderPositions = null;
                    }
                }

                if (renderPositions !== null && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {

                    let limit = props.sketch.limits[printingSketchLimitIndex];

                    let line = turf.lineString(geospatialUtilities.coordsToLatLngArray(coords));
                    
                    if (!arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords)) {
                        let poly = turf.buffer(turf.polygon([geospatialUtilities.boundsToLatLngArray(limit.limitOfSketchCoords)]), -0.0001); // Buffer the limit inwards by a meter to ensure internal splits are truly internal

                        var lineSplits = turf.lineSplit(line, poly);

                        if (arrayUtilities.isNullOrEmpty(lineSplits.features)) {

                            // None of the line parts intersect, so its either fully within or fully without.

                            let inPolygonCount = 0;

                            coords.forEach((linePoint) => {
                                if (geospatialUtilities.polygonContainsPoint(geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords), linePoint)) {
                                    inPolygonCount++;
                                }
                            })

                            if (inPolygonCount === 0) {
                                renderPositions = null;
                            }

                        } else {

                            renderPositions = [];

                            // Iterate over the line parts and just include the ones that are inside the poly

                            lineSplits.features.forEach((feature) => {

                                let inPolygonCount = 0;
                                feature.geometry.coordinates.forEach((coordinate) => {
                                    if (geospatialUtilities.polygonContainsPoint(geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords), { lat: coordinate[0], lng: coordinate[1] })) {
                                        inPolygonCount++;
                                    }
                                });

                                if (inPolygonCount > 1) { // Its likely that at least one point is on the boundary even if the line is outside, so checking for greater than one here instead of greater than zero.
                                    renderPositions = renderPositions.concat(feature.geometry.coordinates);
                                }
                            });
                        }
                    }
                }
            }

            setRenderPositions(renderPositions);
        }

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let pathOptions = { color, weight, opacity, fill: false, dashArray: props.isDashed ? "5 10" : null };
    let bufferPathOptions = { color, weight: 20, opacity: 0.00, fill: false };

    return <>

        {(isDrawing || isEditing) &&
            <EditablePolyline pathOptions={pathOptions} coords={coords} key={hash({ coords })} onChange={setCoords} isDrawing={isDrawing} snappingTolerance={props.snappingTolerance} sketch={props.sketch} points={props.points} lines={props.lines} workflowLines={props.workflowLines} workflowPoints={props.workflowPoints} workflowPointsLines={props.workflowPointsLines} />
        }
        {!isDrawing && !isEditing && isVisible && !arrayUtilities.isNullOrEmpty(renderPositions) && <>
            <Polyline positions={renderPositions} pathOptions={pathOptions} />

            <Polyline positions={renderPositions} pathOptions={bufferPathOptions}>
                <MapPopup title={`${props.isDashed ? "Dashed" : "Solid"} Line`} onEdit={() => setIsEditing(true)} deleteTitle={`Delete ${props.isDashed ? "Dashed" : "Solid"} Line`} deleteContent={`Are you sure you want to permanently delete this ${props.isDashed ? "dashed" : "solid"} line?`} onDelete={onDelete} />
            </Polyline>
        </>
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} ${props.isDashed ? "Dashed" : "Solid"} Line`} onCancel={onCancel} onSave={onSave}>
            <WeightSelect value={weight} onChange={setWeight} />
            <ColorPicker value={color} onChange={setColor} />
            <OpacitySlider value={opacity} onChange={setOpacity} />
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
        </ConfigurationDialog>
    </>
}