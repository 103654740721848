import { Button, Form, Modal, Nav } from "react-bootstrap";
import useApi from "../../utilities/useApi";
import { useEffect, useState } from "react";
import arrayUtilities from "../../utilities/arrayUtilities";
import Textbox from "./textbox";
import stringUtilities from "../../utilities/stringUtilities";
import Loading from "../common/loading";


export default function Tags(props) {

    const { api } = useApi();
    const [tags, setTags] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [newTagName, setNewTagName] = useState("");
    const [canSaveNewTag, setCanSaveNewTag] = useState(false);

    const getTags = function () {
        setIsLoading(true);
        api.getTags(props.organisationId, (data) => {
            setTags(data);
            setIsLoading(false);
        }, () => { });
    }

    const addTag = function () {
        setIsLoading(true);
        api.addTag(props.organisationId, newTagName, (data) => {
            toggleTag(data.id);
            getTags();
        }, () => { })

        setShowAddTagModal(false);
    }

    const toggleTag = function (id) {
        if (props.onChange) {
            props.onChange(arrayUtilities.toggle(id, props.value, !props.value.includes(id)))
        }
    }

    useEffect(() => {
        setCanSaveNewTag(!stringUtilities.isNullOrEmpty(newTagName))
    }, [newTagName]);

    useEffect(() => {
        setNewTagName("");
    }, [showAddTagModal]);

    useEffect(() => {
        getTags();
    }, [])

    return <>
        <Form.Group className='mb-3'>
            <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                Tags
            </Form.Label>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="tags-list">
                    {!arrayUtilities.isNullOrEmpty(tags) && <>
                        {tags.map((tag, index) => <Button size="sm" key={`tag-${tag.id}`} index={index} className={`tag ${(props.value.includes(tag.id) ? "active" : "")}`} onClick={() => toggleTag(tag.id)}>{tag.name}</Button>)}
                    </>}
                    <Button size="sm" className="tag add" onClick={() => setShowAddTagModal(true)}>  <i className='fi-plus me-2'></i> Add tag</Button>
                </div>
            }

        </Form.Group>

        <Modal show={showAddTagModal} onHide={() => setShowAddTagModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Textbox label="Name" value={newTagName} onChange={setNewTagName} />
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setShowAddTagModal(false)}>Cancel</Button>
                <Button size="sm" variant='primary' onClick={addTag} disabled={!canSaveNewTag}>Save</Button>
            </Modal.Footer>
        </Modal>
    </>
}