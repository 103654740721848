import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import stringUtilities from '../../utilities/stringUtilities';
import Textbox from '../form/textbox';

export default function DeleteButton(props) {

    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [canDelete, setCanDelete] = useState(stringUtilities.isNullOrEmpty(props.confirmationText))

    const [confirm, setConfirm] = useState(null);

    const onConfirm = function () {
        setModalIsVisible(false);

        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    useEffect(() => {
        setCanDelete(stringUtilities.isNullOrEmpty(props.confirmationText) || confirm?.trim().toLowerCase() === props.confirmationText.trim().toLowerCase());
    }, [confirm])

    return <>
        <Button size={props.size ?? "sm"} variant={stringUtilities.isNullOrEmpty(props.variant) ? "danger" : props.variant} className={props.className + (props.compact ? " btn-icon" : "")} disabled={props.disabled} onClick={() => setModalIsVisible(true)}>
            {props.compact &&
                <i className='fi-trash'></i>
            }
            {!props.compact && <>
                <i className='fi-trash me-2'></i> Delete
            </>}
        </Button>

        <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered>
            <Modal.Header>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3">{props.modalContent}</p>

                {!stringUtilities.isNullOrEmpty(props.confirmationText) && 
                    <Textbox value={confirm} onChange={setConfirm} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant='secondary' onClick={() => setModalIsVisible(false)}>Cancel</Button>
                <Button size="sm" variant="primary" onClick={onConfirm} disabled={!canDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}