export default {

    firstDayOfMonth(date) {

        let theDate = new Date(date);
        theDate.setDate(1);
        theDate.setHours(0, 0, 0, 0);

        return theDate;
    },

    lastDayOfMonth(date) {

        let theDate = new Date(date);

        theDate.setMonth(theDate.getMonth() + 1);
        theDate.setDate(0);

        return theDate;
    }
}