import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default function ErrorModal(props) {

    const [modalIsVisible, setModalIsVisible] = useState(true);

    const dismiss = function () {
        setModalIsVisible(false);

        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const retry = function () {
        if (props.onRetry) {
            props.onRetry();
        }
    }

    return <Modal show={modalIsVisible} onHide={() => setModalIsVisible(false)} centered>
        <Modal.Header>
            <Modal.Title><span className="text-danger">Error</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="mb-3 text-danger">{props.error.message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button size="sm" variant='secondary' onClick={dismiss}>Dismiss</Button>
            {props.onRetry && props.onRetry !== null &&
                <button type="button" className="btn btn-primary ms-3" onClick={retry}>Retry</button>
            }
        </Modal.Footer>
    </Modal>
}