import React, { useEffect, useState } from "react";
import cloneDeep from 'lodash.clonedeep';
import { Polyline, Popup, useMap, useMapEvents } from "react-leaflet";
import geospatialUtilities from "../../utilities/geospatialUtilities";
import { Button } from "react-bootstrap";
import arrayUtilities from "../../utilities/arrayUtilities";
import SnapToPoint from "./snapToPoint";
import SnappableMarker from "./snappableMarker";

export default function EditablePolyline(props) {

    const [draggingIndex, setDraggingIndex] = useState(null);
    const [coords, setCoords] = useState(props.coords);
    const [vertices, setVertices] = useState([]);
    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const map = useMap();

    const deleteVertex = function (index) {

        map.closePopup();

        let copy = cloneDeep(coords);
        copy.splice(index / 2, 1);
        setCoords(copy);
    }

    const onMarkerDragStart = function (e, index) {
        setDraggingIndex(index);
    }

    const onMarkerDrag = function (e, index) {

        let copy = cloneDeep(vertices);
        copy.splice(index, 1, e.latlng);
        setVertices(copy);
    }

    const onMarkerDragEnd = function (e, index) {

        let newcoords = []
        vertices.forEach((coord, i) => {

            if (i % 2 === 0 || i === index) {
                newcoords.push(i === index ? e.latlng : coord);
            }
        });

        setCoords(newcoords);
        setDraggingIndex(null);

        if (props.onChange) {
            props.onChange(newcoords);
        }
    }

    const onSnappedPointSelected = function (latlng) {

        setDoSnapToPoint(null);
        let newcoords = arrayUtilities.newOrConcat(coords, latlng);

        setCoords(newcoords);
        if (props.onChange) {
            props.onChange(newcoords);
        }
    }

    useMapEvents({
        click: (e) => {
            if (props.isDrawing && draggingIndex === null) {
                setDoSnapToPoint(e.latlng);
            }
        },
    })


    useEffect(() => {

        let coordsWithMidpoints = [];
        if (!arrayUtilities.isNullOrEmpty(coords)) {
            coords.forEach((coord, index) => {

                coordsWithMidpoints.push(coord);

                if (index + 1 < coords.length) {
                    let p1 = coords[index];
                    let p2 = coords[index + 1 < coords.length ? index + 1 : 0];
                    let midPoint = geospatialUtilities.midPoint(p1, p2);

                    coordsWithMidpoints.push(midPoint);
                }
            })
        }

        setVertices(coordsWithMidpoints);
    }, [coords])

    return <>
        {!arrayUtilities.isNullOrEmpty(coords) &&
            <Polyline positions={[coords.map(x => [x.lat, x.lng])]} pathOptions={props.pathOptions}></Polyline>
        }

        {vertices.map((coord, index) => {

            if ((index % 2 === 0 || index === draggingIndex || draggingIndex === null)) {

                return <SnappableMarker
                    index={index}
                    opacity={index % 2 === 0 || draggingIndex === index ? 1 : 0.35}
                    position={[coord.lat, coord.lng]}
                    onDragStart={(e) => onMarkerDragStart(e, index)}
                    onDrag={(e) => onMarkerDrag(e, index)}
                    onDragend={(e) => onMarkerDragEnd(e, index)}
                    snappingTolerance={props.snappingTolerance} 
                    sketch={props.sketch} 
                    points={props.points} 
                    lines={props.lines}
                    workflowLines={props.workflowLines}
                    workflowPoints={props.workflowPoints}
                    workflowPointsLines={props.workflowPointsLines} >
                    {index % 2 == 0 && <Popup>
                        <div className='card border-0'>
                            <div className='card-body position-relative d-flex justify-content-center'>
                                <Button size="sm" variant='danger' onClick={() => deleteVertex(index)}>Delete Point</Button>
                            </div>
                        </div>
                    </Popup>}
                </SnappableMarker>
            }
            return null;
        })}

        {doSnapToPoint !== null &&
            <SnapToPoint
                latlng={doSnapToPoint} 
                snappingTolerance={props.snappingTolerance} 
                sketch={props.sketch} 
                points={props.points} 
                lines={props.lines} 
                onSnappedPointSelected={onSnappedPointSelected} 
                onCancel={() => setDoSnapToPoint(null)} 
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines} />
        }
    </>
}