import { Marker, useMap, useMapEvents } from "react-leaflet";
import MapPopup from "./_mapPopup";
import { useEffect, useState } from "react";
import stringUtilities from "../../../utilities/stringUtilities";
import arrayUtilities from "../../../utilities/arrayUtilities";
import geospatialUtilities from "../../../utilities/geospatialUtilities";
import postal from 'postal';
import * as turf from '@turf/turf'

export default function TextMarkerMarker(props) {

    const map = useMap();

    const [shouldPrint, setShouldPrint] = useState(true);
    const [topLeft, setTopLeft] = useState(null);
    const [bottomRight, setBottomRight] = useState(null);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);

    const refreshCorners = function () {

        let element = document.getElementById(props.id);
        if (element) {

            let mapContainer = element.closest(".map-container");
            if (mapContainer) {
                let elementRect = element.getBoundingClientRect();
                let mapContainerRect = mapContainer.getBoundingClientRect();

                let topLeft = map.containerPointToLatLng({
                    x: elementRect.left - mapContainerRect.x,
                    y: elementRect.top - mapContainerRect.y
                });

                let bottomRight = map.containerPointToLatLng({
                    x: elementRect.left - mapContainerRect.x + elementRect.width,
                    y: elementRect.top - mapContainerRect.y + elementRect.height
                });

                setTopLeft(topLeft);
                setBottomRight(bottomRight);
            }
        }
    }

    const onMapEvent = function () {
        setShouldPrint(true);
        setTimeout(() => {
            refreshCorners();
        }, 100);
    }

    useMapEvents({
        moveend: onMapEvent,
        zoomend: onMapEvent
    })

    useEffect(() => {

        let shouldPrint = true;

        if (props.coord && props.coord !== null && ((printingSketchLimitIndex !== null) || !stringUtilities.isNullOrEmpty(printingWorkflowId))) {

            if (!stringUtilities.isNullOrEmpty(props.limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === props.limitId) !== printingSketchLimitIndex) {
                shouldPrint = false;
            }

            if (shouldPrint && !stringUtilities.isNullOrEmpty(props.workflowId)) {
                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === props.workflowId);

                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== props.workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                    shouldPrint = false;
                }
            }

            if (shouldPrint && printingSketchLimitIndex !== null && !isNaN(printingSketchLimitIndex) && props.sketch && props.sketch !== null && !arrayUtilities.isNullOrEmpty(props.sketch.limits) && props.sketch.limits.length > printingSketchLimitIndex) {
                let limit = props.sketch.limits[printingSketchLimitIndex];

                if (limit !== null && !arrayUtilities.isNullOrEmpty(limit.limitOfSketchCoords)) {
                    let losPolygon = geospatialUtilities.boundsToPolygonCoords(limit.limitOfSketchCoords);

                    let p1 = turf.polygon([geospatialUtilities.boundsToLatLngArray([topLeft, bottomRight])]);
                    let p2 = turf.polygon([geospatialUtilities.makePolygonSelfClosing(
                        geospatialUtilities.coordsToLatLngArray(losPolygon)
                    )]);

                    var intersection = turf.intersect(p1, p2);
                    if (!intersection || intersection === null) {
                        shouldPrint = false;
                    }
                }
            }
        }

        setShouldPrint(shouldPrint);

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        refreshCorners();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    return <>
        {shouldPrint && <Marker icon={props.icon} position={[props.coord.lat, props.coord.lng]} pane="drawing-tool-labels">
            <MapPopup
                title={props.title}
                onEdit={props.onEdit}
                deleteTitle={props.deleteTitle}
                deleteContent={props.deleteContent}
                onDelete={props.onDelete} />
        </Marker>}
    </>
}