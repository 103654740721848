import { useEffect, useState } from "react";
import { Marker, Rectangle, useMap, useMapEvents } from "react-leaflet";
import mapIcons from "./mapIcons";
import geospatialUtilities from "../../utilities/geospatialUtilities";
var hash = require('object-hash');

export default function EditableRectangle(props) {

    const map = useMap();

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);

    const [northEast, setNorthEast] = useState(props.northEast);
    const [southWest, setSouthWest] = useState(props.southWest);

    const [northEastTemp, setNorthEastTemp] = useState(null);
    const [southWestTemp, setSouthWestTemp] = useState(null);
    const [draggingRectangleStart, setDraggingRectangleStart] = useState(null);
    const [isDraggingRectangle, setIsDraggingRectangle] = useState(false);
    const [ratio, setRatio] = useState(props.ratio);

    const onRectangleDragStart = function (e) {

        map.dragging.disable();

        setNorthEastTemp(northEast);
        setSouthWestTemp(southWest);
        setIsDraggingRectangle(true);
        setDraggingRectangleStart(e.latlng);
    }

    const onChange = function (e) {
        if (props.onChange && northEast !== null && southWest !== null) {
            props.onChange(northEast, southWest);
        }
    }

    useMapEvents({

        mousedown: (e) => {

            if (isDrawing) {
                map.dragging.disable();
                setNorthEast(e.latlng);
                setSouthWest(e.latlng);
            }
        },

        mousemove: (e) => {
            if (isDrawing) {
                setSouthWest(e.latlng);
            }

            if (isDraggingRectangle && draggingRectangleStart && draggingRectangleStart !== null && northEastTemp && northEastTemp !== null && southWestTemp && southWestTemp !== null) {

                let latDiff = e.latlng.lat - draggingRectangleStart.lat;
                let lngDiff = e.latlng.lng - draggingRectangleStart.lng;

                setNorthEast({ lat: northEastTemp.lat + latDiff, lng: northEastTemp.lng + lngDiff });
                setSouthWest({ lat: southWestTemp.lat + latDiff, lng: southWestTemp.lng + lngDiff });
            }
        },
        mouseup: (e) => {
            if (isDrawing) {
                if (props.onChange && northEast !== null) {

                    let ne = { lat: Math.max(northEast.lat, e.latlng.lat), lng: Math.max(northEast.lng, e.latlng.lng) };
                    let sw = { lat: Math.min(northEast.lat, e.latlng.lat), lng: Math.min(northEast.lng, e.latlng.lng) };

                    setIsDrawing(false);
                    setNorthEast(ne);
                    setSouthWest(sw);
                }
            }

            map.dragging.enable();
            setNorthEastTemp(null);
            setSouthWestTemp(null);
            setIsDraggingRectangle(false);
            setDraggingRectangleStart(null);

            setTimeout(() => {
                onChange();
            }, 500);
        }
    })


    const onMarkerDrag = function (e, isNorthEast, isSouthEast, isSouthWest, isNorthWest) {

        let ne = northEast;
        let sw = southWest;

        if (ratio && ratio !== null) {
            let oppositeCorner = null;

            if (isNorthEast) {
                oppositeCorner = southWest;
            } else if (isSouthEast) {
                oppositeCorner = { lat: northEast.lat, lng: southWest.lng };
            } else if (isSouthWest) {
                oppositeCorner = northEast;
            } else if (isNorthWest) {
                oppositeCorner = { lat: southWest.lat, lng: northEast.lng };
            }

            if (oppositeCorner !== null) {

                let oldWidth = geospatialUtilities.haversineDistance({ lat: e.oldLatLng.lat, lng: oppositeCorner.lng }, e.oldLatLng);
                let newWidth = geospatialUtilities.haversineDistance({ lat: e.oldLatLng.lat, lng: oppositeCorner.lng }, { lat: e.oldLatLng.lat, lng: e.latlng.lng });

                let oldHeight = geospatialUtilities.haversineDistance({ lat: oppositeCorner.lat, lng: e.oldLatLng.lng }, e.oldLatLng);
                let newHeight = geospatialUtilities.haversineDistance({ lat: oppositeCorner.lat, lng: e.oldLatLng.lng }, { lat: e.latlng.lat, lng: e.oldLatLng.lng });

                let widthRatio = newWidth / oldWidth;
                let heightRatio = newHeight / oldHeight;

                if (heightRatio !== 0 && widthRatio !== 0) {
                    let bestRatio = Math.max(Math.abs(widthRatio), Math.abs(heightRatio));

                    if (widthRatio !== 1 && (heightRatio === 1 || bestRatio === Math.abs(widthRatio))) {

                        // We are adjusting height

                        let earth = 6378.137;
                        let meterInDegrees = (1 / ((2 * Math.PI / 360) * earth)) / 1000;
                        let delta = (newWidth * ratio * meterInDegrees);

                        if (isNorthEast) {
                            ne = { lat: oppositeCorner.lat + delta, lng: e.latlng.lng };
                        } else if (isSouthEast) {
                            sw = { lat: oppositeCorner.lat - delta, lng: southWest.lng };
                            ne = { lat: northEast.lat, lng: e.latlng.lng };
                        } else if (isSouthWest) {
                            sw = { lat: oppositeCorner.lat - delta, lng: e.latlng.lng };
                        } else if (isNorthWest) {
                            sw = { lat: southWest.lat, lng: e.latlng.lng };
                            ne = { lat: oppositeCorner.lat + delta, lng: northEast.lng };
                        }

                    } else if (heightRatio !== 1 && (widthRatio === 1 || bestRatio === Math.abs(heightRatio))) {

                        // We are adjusting width.

                        let earth = 6378.137;
                        let meterInDegrees = (1 / ((2 * Math.PI / 360) * earth)) / 1000;
                        let delta = (newHeight / ratio * meterInDegrees) / Math.cos(oppositeCorner.lat * (Math.PI / 180));

                        if (isNorthEast) {
                            ne = { lat: e.latlng.lat, lng: oppositeCorner.lng + delta };
                        } else if (isSouthEast) {
                            ne = { lat: northEast.lat, lng: oppositeCorner.lng + delta };
                            sw = { lat: e.latlng.lat, lng: southWest.lng };
                        } else if (isSouthWest) {
                            sw = { lat: e.latlng.lat, lng: oppositeCorner.lng - delta };
                        } else if (isNorthWest) {
                            ne = { lat: e.latlng.lat, lng: northEast.lng };
                            sw = { lat: southWest.lat, lng: oppositeCorner.lng - delta };
                        }
                    }
                }
            }
        } else {
            if (isNorthEast) {
                ne = e.latlng;
            } else if (isSouthEast) {
                ne = { lat: ne.lat, lng: e.latlng.lng };
                sw = { lat: e.latlng.lat, lng: sw.lng };
            } else if (isSouthWest) {
                sw = e.latlng;
            } else if (isNorthWest) {
                ne = { lat: e.latlng.lat, lng: ne.lng };
                sw = { lat: sw.lat, lng: e.latlng.lng };
            }
        }

        setNorthEast({ lat: Math.max(ne.lat, sw.lat), lng: Math.max(ne.lng, sw.lng) });
        setSouthWest({ lat: Math.min(ne.lat, sw.lat), lng: Math.min(ne.lng, sw.lng) });
    }

    useEffect(() => {
        if (props.ratio && props.ratio !== null && props.northEast && props.northEast !== null && props.southWest && props.southWest !== null) {

            let ne = { lat: Math.max(props.northEast.lat, props.southWest.lat), lng: Math.max(props.northEast.lng, props.southWest.lng) };
            let sw = { lat: Math.min(props.northEast.lat, props.southWest.lat), lng: Math.min(props.northEast.lng, props.southWest.lng) };

            let p0 = map.latLngToContainerPoint(ne);
            let p1 = map.latLngToContainerPoint(sw);

            let xDiff = Math.max(p0.x, p1.x) - Math.min(p0.x, p1.x);
            let yDiff = Math.max(p0.y, p1.y) - Math.min(p0.y, p1.y);

            let losRatio = yDiff / xDiff;

            setRatio(losRatio);
            setNorthEast(ne);
            setSouthWest(sw);
        }
    }, []);

    return <>

        {northEast !== null && southWest !== null &&
            <>
                <Rectangle key={hash({ northEast, southWest })} bounds={[[northEast.lat, northEast.lng], [southWest.lat, southWest.lng]]} pathOptions={props.pathOptions} eventHandlers={{
                    mousedown: onRectangleDragStart
                }} />

                {props.allowResize && <>
                    {/* North East drag handle */}
                    <Marker draggable position={northEast} icon={mapIcons.dragHandleNesw} eventHandlers={{
                        drag: (e) => onMarkerDrag(e, true, false, false, false),
                        dragend: onChange
                    }} />

                    {/* North West drag handle */}
                    <Marker draggable position={{ lat: northEast.lat, lng: southWest.lng }} icon={mapIcons.dragHandleNwse} eventHandlers={{
                        drag: (e) => onMarkerDrag(e, false, false, false, true),
                        dragend: onChange
                    }} />

                    {/* South East drag handle */}
                    <Marker draggable position={{ lat: southWest.lat, lng: northEast.lng }} icon={mapIcons.dragHandleNwse} eventHandlers={{
                        drag: (e) => onMarkerDrag(e, false, true, false, false),
                        dragend: onChange
                    }} />

                    {/* South West drag handle */}
                    <Marker draggable position={southWest} icon={mapIcons.dragHandleNesw} eventHandlers={{
                        drag: (e) => onMarkerDrag(e, false, false, true, false),
                        dragend: onChange
                    }} />
                </>}
            </>
        }
    </>;
}