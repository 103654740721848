import arrayUtilities from "./arrayUtilities";

export default {

    radiusOfEarthInKm: 6371,

    coordToLatLngArray: (x) => [x.lat, x.lng],
    coordsToLatLngArray: (coords) => coords.map(x => [x.lat, x.lng]),

    latLngArrayToCoords: function(latLngArray) {
        if (!arrayUtilities.isNullOrEmpty(latLngArray)) {
            return latLngArray.map((x) => { return { lat: x[0], lng: x[1]}});
        }

        return [];
    },

    boundsToLatLngArray: function(coords) {
        if (coords.length === 2) {
            
            return [
                [coords[0].lat, coords[0].lng],
                [coords[0].lat, coords[1].lng],
                [coords[1].lat, coords[1].lng],
                [coords[1].lat, coords[0].lng],
                [coords[0].lat, coords[0].lng],
            ];
        }

        return null;
    },

    boundsToPolygonCoords: function(coords) {
        if (coords.length === 2) {
            
            return [
                coords[0],
                {
                    lat: coords[0].lat,
                    lng: coords[1].lng
                },
                coords[1],
                {
                    lat: coords[1].lat,
                    lng: coords[0].lng
                },
                coords[0],
            ];
        }

        return null;
    },

    makePolygonSelfClosing: function (polygonArray) {

        if (!arrayUtilities.isNullOrEmpty(polygonArray) && (polygonArray[0][0] !== polygonArray[polygonArray.length - 1][0] || polygonArray[0][1] !== polygonArray[polygonArray.length - 1][1])) {
            polygonArray = polygonArray.concat(polygonArray.slice(0, 1));
        }

        return polygonArray;
    },

    midPoint: function (point1, point2) {

        let degreesToRadians = Math.PI / 180;

        var lat1 = point1.lat * degreesToRadians;
        var lat2 = point2.lat * degreesToRadians;
        var lng1 = point1.lng * degreesToRadians;
        var dLng = (point2.lng - point1.lng) * degreesToRadians;

        // Calculate mid-point:
        var bx = Math.cos(lat2) * Math.cos(dLng);
        var by = Math.cos(lat2) * Math.sin(dLng);
        var lat = Math.atan2(
            Math.sin(lat1) + Math.sin(lat2),
            Math.sqrt((Math.cos(lat1) + bx) * (Math.cos(lat1) + bx) + by * by));
        var lng = lng1 + Math.atan2(by, Math.cos(lat1) + bx);

        return { lat: lat / degreesToRadians, lng: lng / degreesToRadians };
    },

    haversineDistance: function (point1, point2) {

        if (!point1 || point1 === null || !point2 || point2 === null) {
            return 0.00;
        }

        let lat1 = point1.lat;
        let lng1 = point1.lng;
        let lat2 = point2.lat;
        let lng2 = point2.lng;

        const toRadian = angle => (Math.PI / 180) * angle;
        const distance = (a, b) => (Math.PI / 180) * (a - b);

        const dLat = distance(lat2, lat1);
        const dLon = distance(lng2, lng1);

        lat1 = toRadian(lat1);
        lat2 = toRadian(lat2);

        const a =
            Math.pow(Math.sin(dLat / 2), 2) +
            Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.asin(Math.sqrt(a));

        let finalDistance = this.radiusOfEarthInKm * c;

        return finalDistance *= 1000;
    },

    shiftPoint: function (point, latMeters, lonMeters) {

        let newLatitude = latMeters === 0 ? point.lat : (point.lat + (latMeters / (this.radiusOfEarthInKm * 1000)) * (180 / Math.PI));
        let newLongitude = lonMeters === 0 ? point.lng : (point.lng + (lonMeters / (this.radiusOfEarthInKm * 1000)) * (180 / Math.PI) / Math.cos(point.lat * Math.PI / 180));

        return { lat: newLatitude, lng: newLongitude };
    },

    polygonContainsPoint: function (polygonCoords, point) {

        let x = point.lat;
        let y = point.lng;

        let inside = false;
        for (let i = 0, j = polygonCoords.length - 1; i < polygonCoords.length; j = i++) {
            let xi = polygonCoords[i].lat, yi = polygonCoords[i].lng;
            let xj = polygonCoords[j].lat, yj = polygonCoords[j].lng;

            let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }

        return inside;
    },

      // https://stackoverflow.com/questions/16429562/find-a-point-in-a-polyline-which-is-closest-to-a-latlng
      getClosestPointOnLines: function (pXy, aXys) {

        var minDist;
        var fTo;
        var fFrom;
        var x;
        var y;
        var i;
        var dist;

        if (aXys.length > 1) {

            for (var n = 1; n < aXys.length; n++) {

                if (aXys[n].lat != aXys[n - 1].lat) {
                    var a = (aXys[n].lng - aXys[n - 1].lng) / (aXys[n].lat - aXys[n - 1].lat);
                    var b = aXys[n].lng - a * aXys[n].lat;
                    dist = Math.abs(a * pXy.lat + b - pXy.lng) / Math.sqrt(a * a + 1);
                }
                else
                    dist = Math.abs(pXy.lat - aXys[n].lat)

                // length^2 of line segment 
                var rl2 = Math.pow(aXys[n].lng - aXys[n - 1].lng, 2) + Math.pow(aXys[n].lat - aXys[n - 1].lat, 2);

                // distance^2 of pt to end line segment
                var ln2 = Math.pow(aXys[n].lng - pXy.lng, 2) + Math.pow(aXys[n].lat - pXy.lat, 2);

                // distance^2 of pt to begin line segment
                var lnm12 = Math.pow(aXys[n - 1].lng - pXy.lng, 2) + Math.pow(aXys[n - 1].lat - pXy.lat, 2);

                // minimum distance^2 of pt to infinite line
                var dist2 = Math.pow(dist, 2);

                // calculated length^2 of line segment
                var calcrl2 = ln2 - dist2 + lnm12 - dist2;

                // redefine minimum distance to line segment (not infinite line) if necessary
                if (calcrl2 > rl2)
                    dist = Math.sqrt(Math.min(ln2, lnm12));

                if ((minDist == null) || (minDist > dist)) {
                    if (calcrl2 > rl2) {
                        if (lnm12 < ln2) {
                            fTo = 0;//nearer to previous point
                            fFrom = 1;
                        }
                        else {
                            fFrom = 0;//nearer to current point
                            fTo = 1;
                        }
                    }
                    else {
                        // perpendicular from point intersects line segment
                        fTo = ((Math.sqrt(lnm12 - dist2)) / Math.sqrt(rl2));
                        fFrom = ((Math.sqrt(ln2 - dist2)) / Math.sqrt(rl2));
                    }
                    minDist = dist;
                    i = n;
                }
            }

            var dx = aXys[i - 1].lat - aXys[i].lat;
            var dy = aXys[i - 1].lng - aXys[i].lng;

            x = aXys[i - 1].lat - (dx * fTo);
            y = aXys[i - 1].lng - (dy * fTo);

        }

        return { lat: x, lng: y, 'i': i, 'fTo': fTo, 'fFrom': fFrom };
    }
}