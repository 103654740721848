import { useEffect, useState } from "react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import { Circle } from "react-leaflet";
import EditableCircle from "../editableCircle";
import postal from "postal";
import ConfigurationDialog from "./_configurationDialog";
import WeightSelect from "../../form/weightSelect";
import ColorPicker from "../../form/colorPicker";
import OpacitySlider from "../../form/opacitySlider";
import WorkflowSelect from "../../form/workflowSelect";
import MapPopup from "./_mapPopup";
import stringUtilities from "../../../utilities/stringUtilities";
import LimitSelect from "../../form/limitSelect";
import hash from "object-hash";

export default function CircleDrawingTool(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(true);

    const [printingSketchLimitIndex, setPrintingSketchLimitIndex] = useState(null);
    const [printingWorkflowId, setPrintingWorkflowId] = useState(null);

    const [centerPoint, setCenterPoint] = useState(null);
    const [radius, setRadius] = useState(null);

    const [weight, setWeight] = useState(null);
    const [color, setColor] = useState(null);
    const [opacity, setOpacity] = useState(null);
    const [fillOpacity, setFillOpacity] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);

    const reset = function () {

        let coords = props.sketchItem?.coords ?? [];
        setCenterPoint(!arrayUtilities.isNullOrEmpty(coords) && coords.length > 0 ? coords[0] : null);
        setRadius(props.sketchItem?.tool?.radius ?? 10);
        setWeight(props.sketchItem?.tool?.properties?.weight ?? 1);
        setColor(props.sketchItem?.tool?.properties?.color ?? "#000000");
        setOpacity(props.sketchItem?.tool?.properties?.opacity ?? 1);
        setFillOpacity(props.sketchItem?.tool?.properties?.fillOpacity ?? 0.5);
        setWorkflowId(props.sketchItem?.tool?.properties?.workflowId ?? "");
        setLimitId(props.sketchItem?.tool?.properties?.limitId ?? props.activeLimit);
    }

    const onCircleChange = function (centerPoint, radius) {
        setCenterPoint(centerPoint);
        setRadius(radius);
        setIsDrawing(false);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {
        
        if (props.onSave) {

            let sketchItem = {
                coords: [
                    centerPoint
                ],
                tool: {
                    id: 13,
                    radius: radius,
                    properties: {
                        weight,
                        color,
                        opacity,
                        fillOpacity,
                        workflowId,
                        limitId
                    }
                }
            }

            props.onSave(sketchItem, props.index);
        }
        
        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

    }, [isEditing])


    useEffect(() => {

        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {

        let shouldRender = true;

        if (printingSketchLimitIndex !== null || !stringUtilities.isNullOrEmpty(printingWorkflowId)) {

            if (!stringUtilities.isNullOrEmpty(limitId) && !arrayUtilities.isNullOrEmpty(props.sketch?.limits) && props.sketch.limits.findIndex(x => x.id === limitId) !== printingSketchLimitIndex) {
                shouldRender = false;
            }

            if (shouldRender && !stringUtilities.isNullOrEmpty(workflowId)) {
                let workflow = arrayUtilities.isNullOrEmpty(props.workflows) ? null : props.workflows.find(x => x.id === workflowId);

                if (!stringUtilities.isNullOrEmpty(printingWorkflowId) && printingWorkflowId !== "ALL" && printingWorkflowId !== workflowId && (!workflow || workflow === null || !workflow.isComposite)) {
                    shouldRender = false;
                }
            }
        }

        setShouldRender(shouldRender);

    }, [printingSketchLimitIndex, printingWorkflowId])

    useEffect(() => {
        reset();

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "printing",
                callback: function (data) {
                    setPrintingSketchLimitIndex(data.sketchLimitIndex);
                    setPrintingWorkflowId(data.workflowId);
                }
            }),
        ]

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, [])

    let pathOptions = { color, weight, fill: true, opacity, fillColor: color, fillOpacity };

    return <>

        {(isDrawing || isEditing) &&
            <EditableCircle isDrawing={props.isDrawing} pathOptions={pathOptions} key={hash({ centerPoint })} centerPoint={centerPoint} radius={radius} onChange={onCircleChange} />
        }
        {!isDrawing && !isEditing && isVisible && shouldRender && centerPoint !== null &&
            <Circle center={centerPoint} radius={radius} pathOptions={pathOptions}>
                <MapPopup title="Circle" onEdit={() => setIsEditing(true)} deleteTitle="Delete Circle" deleteContent="Are you sure you want to permanently delete this circle?" onDelete={onDelete} />
            </Circle>
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} Circle`} onCancel={onCancel} onSave={onSave}>
            <WeightSelect value={weight} onChange={setWeight} />
            <ColorPicker value={color} onChange={setColor} />
            <OpacitySlider value={opacity} onChange={setOpacity} />
            <OpacitySlider label="Fill Opacity" value={fillOpacity} onChange={setFillOpacity} />
            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
        </ConfigurationDialog>
    </>
}